import { Table } from "antd"
import { PubStatisticsTableRowText } from "@/components/publisher/Statistics/StatisticsTableRowText"
import { VISIBILITY_MIN_GOOD_PERSENTAGE } from "@/components/publisher/Statistics/index"
import styles from "@/components/publisher/Statistics/Statistics.module.css"

const { Summary } = Table
const { Row, Cell } = Summary

interface PubStatisticsTableSummaryProps {
  selectedColumns: string[]
  defaultColumns: string[]
  clicks: string | number
  views: string | number
  blockViews: string | number
  blindViews: string | number
  debitCpc: string | number
  blindBlockViews: string | number
  visibility: string | number
  blockCTR: string | number
  blockVCTR: string | number
}

export const PubStatisticsTableSummary = ({
  selectedColumns,
  defaultColumns,
  clicks,
  views,
  blockViews,
  blindViews,
  debitCpc,
  blindBlockViews,
  visibility,
  blockCTR,
  blockVCTR,
}: PubStatisticsTableSummaryProps): JSX.Element => {
  const getSummaryData = (column: string) => {
    switch (column) {
      case "clicks":
        return clicks
      case "views":
        return views
      case "block_views":
        return blockViews
      case "blind_views":
        return blindViews
      case "debit_cpc":
        return debitCpc
      case "blind_block_views":
        return blindBlockViews
      case "visibility":
        return (
          <PubStatisticsTableRowText
            value={visibility}
            persentage={VISIBILITY_MIN_GOOD_PERSENTAGE}
          />
        )
      case "block_ctr":
        return blockCTR
      case "block_vctr":
        return blockVCTR
      default:
        return ""
    }
  }

  // filter defaultColumns for save their position
  const filteredColumns = defaultColumns.filter((col) =>
    selectedColumns.includes(col),
  )

  return (
    <Row>
      <Cell className={styles.lastRow} index={0}>
        SUM
      </Cell>
      {filteredColumns.map((column, index) => (
        <Cell className={styles.lastRow} index={index + 1} key={index}>
          {getSummaryData(column)}
        </Cell>
      ))}
    </Row>
  )
}
