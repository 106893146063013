import { useState } from "react"
import { Typography, Table, Flex } from "antd"
import { useTranslation } from "react-i18next"
import { useGetPubPaymentsQuery } from "@/redux/slices/publisherApi"
import { StdSpinner } from "@/components/common/Std/Spinner"
import { StdErrorMessage } from "@/components/common/Std/ErrorMessage"
import { StdPaginationRange } from "@/components/common/Std/Selects/PaginationRange"
import { StdDownloadDropdown } from "@/components/common/Std/Dropdowns/DownloadDropdown"
import { DEFAULT_PAGINATION_RANGE } from "@/constants"
import { CSVLink } from "react-csv"
import { textToUpperCase } from "@/utils/textToUpperCase"
import { TFunction } from "i18next"
import { PublisherPayment } from "@/definitions"

const { Title } = Typography

export const PubPayment = (): JSX.Element => {
  const { t } = useTranslation()

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [paginationRange, setPaginationRange] = useState<number>(
    DEFAULT_PAGINATION_RANGE,
  )
  const { data, isLoading, isError } = useGetPubPaymentsQuery()

  const handlePageChange = (page: number): void => {
    setCurrentPage(page)
  }

  if (isLoading) return <StdSpinner />

  if (isError) return <StdErrorMessage>Error...</StdErrorMessage>

  const csvData = convertToCsv(data, t)

  return (
    <Flex gap="large" vertical>
      <Title level={3}>{t("PUB.PAYMENTS.PAYMENTS")}</Title>
      <Flex gap="large" align="center" justify="flex-end">
        <StdPaginationRange
          t={t}
          paginationRange={paginationRange}
          setPaginationRange={setPaginationRange}
        />
        <StdDownloadDropdown
          items={[
            {
              key: "csv",
              label: (
                <CSVLink filename="payment-csv.csv" data={csvData}>
                  {t("DOWNLOAD.CSV")}
                </CSVLink>
              ),
            },
          ]}
        />
      </Flex>
      <div style={{ position: "relative" }}>
        <Table
          scroll={{ x: "max-content" }}
          dataSource={data}
          rowKey="id"
          showSorterTooltip={false}
          sortDirections={["ascend", "descend", "ascend"]}
          columns={[
            {
              title: t("PUB.PAYMENTS.ID"),
              dataIndex: "id",
              key: "id",
              width: 100,
              sorter: (a, b) => Number(a.id) - Number(b.id),
              defaultSortOrder: "descend",
            },
            {
              title: t("PUB.PAYMENTS.START_DATE"),
              dataIndex: "start_date",
              key: "start_date",
              width: 200,
              sorter: (a, b) =>
                new Date(a.start_date).getTime() -
                new Date(b.start_date).getTime(),
            },
            {
              title: t("PUB.PAYMENTS.PAYMENT_AMOUNT"),
              dataIndex: "payment_amount",
              key: "payment_amount",
              width: 200,
              sorter: (a, b) =>
                Number(a.payment_amount) - Number(b.payment_amount),
            },
            {
              title: t("PUB.PAYMENTS.COMMENT"),
              dataIndex: "comment",
              key: "comment",
              width: 300,
              render: (value) => <span>{!value ? "-" : value}</span>,
              sorter: (a, b) => a.comment.localeCompare(b.comment),
            },
          ]}
          pagination={{
            position: ["bottomCenter"],
            current: currentPage,
            pageSize: paginationRange,
            showSizeChanger: false,
            onChange: handlePageChange,
            total: data?.length,
            showTotal: (total, range) => {
              if (range[1] > 0) {
                return (
                  <span className="paginationShowTotal">
                    {t("PAGINATION.SHOW_TOTAL", {
                      firstNumber: range[0],
                      secondNumber: range[1],
                      total: total,
                    })}
                  </span>
                )
              }
            },
          }}
        />
      </div>
    </Flex>
  )
}

const convertToCsv = (
  data: PublisherPayment[] | undefined,
  t: TFunction<"translation", undefined>,
) => {
  if (!data || !data.length) return []

  const csvData = []

  if (data?.length > 0) {
    // Add header row and translate keys
    const headerRow = Object.keys(data[0]).map((header) =>
      t(`PUB.PAYMENTS.${textToUpperCase(header)}`),
    )
    csvData.push(headerRow.join(","))
  }

  // Add data rows
  data.forEach((row: PublisherPayment) => {
    csvData.push(Object.values(row))
  })

  return csvData.join("\n")
}
