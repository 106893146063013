import {
  Button,
  Input,
  Select,
  Checkbox,
  InputNumber,
  Flex,
  Form,
  Typography,
} from "antd"
import { useTranslation } from "react-i18next"
import type { FormProps } from "antd"
import { useGetTimezonesQuery } from "@/redux/slices/utilsApi"
import { useCreateAdvCampaignMutation } from "@/redux/slices/advertiserCampaignsApi"
import { AdvertiserCampaignCreate } from "@/definitions"
import { filterOptions } from "@/utils/filterOptions"
import { StdPaymentModelsCheckboxes } from "@/components/common/Std/PaymentTypesCheckboxes"
import { StdCountries } from "@/components/common/Std/Selects/Countries"
import { useEffect } from "react"

const { Item, useForm } = Form
const { Title } = Typography

export const AdvCampaignCreate = (): JSX.Element => {
  const [form] = useForm()
  const { t } = useTranslation()

  const { data: timezonesData, isLoading: timezonesIsLoading } =
    useGetTimezonesQuery()

  const [createCampaing, { isLoading, data }] = useCreateAdvCampaignMutation()

  useEffect(() => {
    if (data?.state === "ok") {
      form.resetFields()
    }
  }, [data])

  const handleChangePaymentModel = (value: string): void => {
    form.setFieldValue("payment_model", value)
  }

  const handleChangeCountry = (value: string): void => {
    form.setFieldValue("country", value)
  }

  const onFinish: FormProps<AdvertiserCampaignCreate>["onFinish"] = (
    values,
  ) => {
    createCampaing(values)
  }

  return (
    <Form
      name="create-campaign"
      form={form}
      layout="vertical"
      labelCol={{ span: 16 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 450 }}
      onFinish={onFinish}
      autoComplete="off"
      initialValues={{
        price: 0,
        balance_limit: 0,
        daily_limit: 0,
        from: "0",
        to: "24",
      }}
    >
      <Title level={4} style={{ paddingBottom: "5px" }}>
        {t("ADV.CAMPAIGNS.ADD_NEW_CAMPAIGNS")}
      </Title>
      <Item
        name="name"
        label={t("ADV.CAMPAIGNS.CAMPAING_NAME")}
        rules={[
          { required: true, message: t("ADV.CAMPAIGNS.CAMPAING_NAME_REQ") },
        ]}
      >
        <Input placeholder={t("ADV.CAMPAIGNS.CAMPAING_NAME")} />
      </Item>
      <Item
        name="country"
        label={t("SELECTS.COUNTRY")}
        rules={[{ required: true, message: t("SELECTS.COUNTRY_REQ") }]}
      >
        <StdCountries t={t} onChange={handleChangeCountry} />
      </Item>
      <Item
        name="timezone"
        label={t("SELECTS.TIMEZONE")}
        rules={[{ required: true, message: t("SELECTS.TIMEZONE_REQ") }]}
      >
        <Select
          optionFilterProp="children"
          placeholder={t("SELECTS.TIMEZONE_PLACEHOLDER")}
          filterOption={filterOptions}
          loading={timezonesIsLoading}
          showSearch
          options={timezonesData?.map((timezone: string) => ({
            label: timezone,
            value: timezone,
          }))}
        />
      </Item>
      <Item
        name="payment_model"
        label={t("ADV.CAMPAIGNS.PAYMENT_MODEL")}
        rules={[
          { required: true, message: t("ADV.CAMPAIGNS.PAYMENT_MODEL_REQ") },
        ]}
      >
        <StdPaymentModelsCheckboxes onChange={handleChangePaymentModel} />
      </Item>
      <Item
        name="price"
        label={t("ADV.CAMPAIGNS.PRICE")}
        rules={[{ required: true, message: t("ADV.CAMPAIGNS.PRICE_REQ") }]}
      >
        <InputNumber
          min={0}
          max={1000}
          addonAfter="$"
          style={{ width: "100%" }}
        />
      </Item>
      <Item
        name="balance_limit"
        label={t("ADV.CAMPAIGNS.LIMIT")}
        rules={[{ required: true, message: t("ADV.CAMPAIGNS.LIMIT_REQ") }]}
      >
        <InputNumber
          min={0}
          max={1000}
          addonAfter="$"
          style={{ width: "100%" }}
        />
      </Item>
      <Item
        name="daily_limit"
        label={t("ADV.CAMPAIGNS.DAILY_LIMIT")}
        rules={[
          { required: true, message: t("ADV.CAMPAIGNS.DAILY_LIMIT_REQ") },
        ]}
      >
        <InputNumber
          min={0}
          max={1000}
          addonAfter="$"
          style={{ width: "100%" }}
        />
      </Item>

      <Item
        name="active_period_days"
        label={t("ADV.CAMPAIGNS.ACTIVE_PERIOD_DAYS")}
        rules={[
          {
            required: true,
            message: t("ADV.CAMPAIGNS.ACTIVE_PERIOD_DAYS_REQ"),
          },
        ]}
      >
        <Checkbox.Group
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
          options={Object.entries(
            t("WEEK_DAYS", {
              returnObjects: true,
            }),
          ).map((day: string[], key: number) => ({
            key,
            label: day[1],
            value: day[0],
            style: { width: 93 },
          }))}
        />
      </Item>
      <Item style={{ marginBottom: "0" }}>
        <Flex
          gap="large"
          align="start"
          style={{ width: "100%", flexWrap: "wrap" }}
        >
          <Item
            style={{ flex: "1" }}
            name="from"
            label={t("ADV.CAMPAIGNS.FROM_TIME")}
            rules={[
              { required: true, message: t("ADV.CAMPAIGNS.FROM_TIME_REQ") },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              options={Array.from({ length: 25 }, (_, i) => ({
                label: i.toString(),
                value: i.toString(),
              }))}
            />
          </Item>
          <Item
            style={{ flex: "1" }}
            name="to"
            label={t("ADV.CAMPAIGNS.TO_TIME")}
            rules={[
              { required: true, message: t("ADV.CAMPAIGNS.TO_TIME_REQ") },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              options={Array.from({ length: 25 }, (_, i) => ({
                label: i.toString(),
                value: i.toString(),
              }))}
            />
          </Item>
        </Flex>
      </Item>

      <Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: "100%" }}
          loading={isLoading}
        >
          {t("ADV.CAMPAIGNS.CREATE_BUTTON")}
        </Button>
      </Item>
    </Form>
  )
}
