import { Flex, Switch, Typography } from "antd"
import { TFunction } from "i18next"
import { Dispatch, SetStateAction } from "react"

const { Text } = Typography

interface StdStateSwitcherProps {
  isActive: boolean
  setIsActive: Dispatch<SetStateAction<boolean>>
  t: TFunction<"translation", undefined>
}

export const StdStateSwitcher = ({
  isActive,
  setIsActive,
  t,
}: StdStateSwitcherProps): JSX.Element => {
  return (
    <Flex align="center" gap={15}>
      <Text>{t("BUTTONS.STATE")}:</Text>
      <Switch
        checkedChildren={t("BUTTONS.ON")}
        unCheckedChildren={t("BUTTONS.OFF")}
        defaultChecked={isActive ? true : false}
        onChange={(checked) => setIsActive(checked)}
      />
    </Flex>
  )
}
