import { StdLabel } from "@/components/common/Std/Label"
import { PublisherCampaign } from "@/definitions"
import { Checkbox, DatePicker, Flex, Select } from "antd"
import { TFunction } from "i18next"
import { Dispatch, SetStateAction } from "react"

interface PubOfferDomainsFiltersProps {
  t: TFunction<"translation", undefined>
  setCampaign: Dispatch<SetStateAction<string>>
  setGeo: Dispatch<SetStateAction<string>>
  setShowUrlsStat: Dispatch<SetStateAction<boolean>>
  defaultCampaign: PublisherCampaign | undefined
  pubCampaignsData: PublisherCampaign[] | undefined
  onChangeStartDate: (_: any, dateString: string) => void
  onChangeEndDate: (_: any, dateString: string) => void
  geoData: string[] | undefined
}

export const PubOfferDomainsFilters = ({
  t,
  setCampaign,
  setGeo,
  setShowUrlsStat,
  defaultCampaign,
  pubCampaignsData,
  onChangeStartDate,
  onChangeEndDate,
  geoData,
}: PubOfferDomainsFiltersProps): JSX.Element => {
  return (
    <Flex vertical gap="large" style={{ maxWidth: "300px", width: "100%" }}>
      <Select
        placeholder={t("SELECTS.CAMPAIGN_PLACEHOLDER")}
        style={{ width: "100%" }}
        optionFilterProp="children"
        showSearch
        onChange={(value: string) => {
          setCampaign(value)
        }}
        defaultValue={defaultCampaign?.campaign_id}
        options={pubCampaignsData?.map((camp: PublisherCampaign) => ({
          label: `${camp.campaign_id} ${camp.name}`,
          value: camp.campaign_id,
        }))}
      />
      <Flex wrap="wrap" gap={20} style={{ width: "100%" }}>
        <DatePicker
          style={{ flex: "1" }}
          onChange={onChangeStartDate}
          placeholder={t("SELECTS.START_DATE_PLACEHOLDER")}
        />
        <DatePicker
          style={{ flex: "1" }}
          onChange={onChangeEndDate}
          placeholder={t("SELECTS.END_DATE_PLACEHOLDER")}
        />
      </Flex>
      <StdLabel label={t("SELECTS.GEO")}>
        <Select
          placeholder={t("SELECTS.ALL_PLACEHOLDER")}
          defaultValue={"ALL"}
          style={{ width: 140 }}
          optionFilterProp="children"
          showSearch
          onChange={(value: string) => {
            setGeo(value)
          }}
          filterOption={(inputValue: string, option: any) =>
            option?.value?.toLowerCase().indexOf(inputValue?.toLowerCase()) >= 0
          }
          options={geoData?.map((geoItem: string) => ({
            label: t(geoItem),
            value: geoItem,
          }))}
        />
      </StdLabel>
      <Checkbox
        onChange={(e) => {
          setShowUrlsStat(e.target.checked)
        }}
      >
        {t("PUB.OFFER_DOMAINS.SHOW_ULRS_STAT")}
      </Checkbox>
    </Flex>
  )
}
