import { Dispatch, ReactNode, SetStateAction } from "react"
import { Layout, Typography } from "antd"
import { StdLangsSwitcher } from "@/components/common/Std/Selects/LangsSwitcher"
import { ReactComponent as LogoIcon } from "@/assets/logo.svg"
import { StdThemeSwitcher } from "@/components/common/Std/ThemeSwitcher"
import styles from "@/components/common/Layouts/UnAuthLayout.module.css"

const { Header } = Layout
const { Title } = Typography

interface UnAuthLayoutProps {
  children: ReactNode
  theme: string
  setTheme: Dispatch<SetStateAction<string>>
}

export const UnAuthLayout = ({
  children,
  theme,
  setTheme,
}: UnAuthLayoutProps): JSX.Element => {
  return (
    <Layout className={styles.layout}>
      <Header className={styles.header}>
        <LogoIcon className={styles.logoImg} />
        <div className={styles.logoBox}>
          <Title level={5} className={styles.logoText}>
            ScroogeFrog
          </Title>
          <Title level={5} className={styles.logoText}>
            AdNetwork
          </Title>
        </div>
      </Header>
      <div className={styles.navPanel}>
        <StdThemeSwitcher
          theme={theme}
          setTheme={setTheme}
          style={{ color: "#fff" }}
        />
        <StdLangsSwitcher style={{ width: 80 }} />
      </div>
      {children}
    </Layout>
  )
}
