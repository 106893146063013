import {
  AdvertiserInfo,
  ModerationCount,
  ModerationCountQueries,
  PublisherInfo,
} from "@/definitions"
import { userSplitApi } from "@/redux/emptySplitApi"
import { createApiLink } from "@/utils"

export const apiWithTag = userSplitApi.enhanceEndpoints({
  addTagTypes: ["user"],
})

export const userApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getUserInfo: builder.query<PublisherInfo | AdvertiserInfo, string>({
      query: (role) => ({
        url: `${createApiLink(`/${role}/`)}`,
        method: "GET",
      }),
      providesTags: () => [{ type: "user" }],
    }),
    getModerationsCount: builder.query<ModerationCount, ModerationCountQueries>(
      {
        query: ({ role, paramsString }) => ({
          url: `${createApiLink(`/${role}/ModerationCount/`, paramsString)}`,
          method: "GET",
        }),
        providesTags: () => [{ type: "user" }],
      },
    ),
  }),
})

export const { useGetUserInfoQuery, useGetModerationsCountQuery } = userApi
