import { useGetTimezonesQuery } from "@/redux/slices/utilsApi"
import { filterOptions } from "@/utils/filterOptions"
import { Select } from "antd"
import { TFunction } from "i18next"

interface StdTimezonesProps {
  t: TFunction<"translation", undefined>
  onChange: (value: string) => void
  defaultValue?: string
  disabled?: boolean
}

export const StdTimezones = ({
  t,
  onChange,
  defaultValue,
  disabled,
}: StdTimezonesProps): JSX.Element => {
  const { data: timezonesData, isLoading: timezonesDataIsLoading } =
    useGetTimezonesQuery()

  return (
    <Select
      optionFilterProp="children"
      filterOption={filterOptions}
      defaultValue={defaultValue}
      disabled={disabled}
      onChange={onChange}
      loading={timezonesDataIsLoading}
      showSearch
      placeholder={t("SELECTS.TIMEZONE_PLACEHOLDER")}
      options={timezonesData?.map((timezone: string) => ({
        label: timezone,
        value: timezone,
      }))}
    />
  )
}
