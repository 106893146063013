import { textToUpperCase } from "@/utils/textToUpperCase"
import { TFunction } from "i18next"

export const getFilteredData = (tableDate: any) => {
  if (!tableDate || !tableDate.length) return []

  // filtered by selectedColumn
  const filteredData = tableDate.map((item: any, index: number) => {
    const filteredItem: any = {
      ...item,
      // row_id it's uniq id for table row
      row_id: item.pad_id + `${index}`,
    }

    return filteredItem
  })

  return filteredData
}

export const convertToCsv = (
  data: any,
  selectedColumns: string[],
  defaultColumns: string[],
  t: TFunction<"translation", undefined>,
  summaryData: any,
) => {
  if (!data || !data.length) return ""

  const {
    clicks,
    blindViews,
    views,
    blockViews,
    debitCpc,
    blindBlockViews,
    visibility,
    blockCTR,
    blockVCTR,
  } = summaryData

  const csvData = []
  const firstColumnTitle = "№"
  const summaryTitle = "SUM"

  // filter defaultColumns for save their position and get selectedColumn
  const filteredColumns = defaultColumns.filter((col) =>
    selectedColumns.includes(col),
  )

  // Add header row and translate keys
  const headerRow = filteredColumns.map((column) =>
    t(`PUB.STATISTICS.DOWNLOAD.${textToUpperCase(column)}`),
  )
  csvData.push([firstColumnTitle, ...headerRow].join(","))

  // Filter data by selectedColumn and add data rows
  data.forEach((row: any, index: number) => {
    const newRow: any = {
      index: index + 1,
    }

    filteredColumns.forEach((col) => {
      newRow[col] = row[col]
    })

    const rowValues = Object.values(newRow)
    csvData.push(rowValues)
  })

  // Add summary data
  const summaryRow = [summaryTitle]

  const getSummaryData = (column: string) => {
    switch (column) {
      case "clicks":
        return clicks
      case "views":
        return views
      case "block_views":
        return blockViews
      case "blind_views":
        return blindViews
      case "debit_cpc":
        return debitCpc
      case "blind_block_views":
        return blindBlockViews
      case "visibility":
        return visibility
      case "block_ctr":
        return blockCTR
      case "block_vctr":
        return blockVCTR
      default:
        return ""
    }
  }

  filteredColumns.forEach((col) => {
    summaryRow.push(getSummaryData(col))
  })

  csvData.push(summaryRow)

  return csvData.join("\n")
}
