import { Typography } from "antd"

const { Text } = Typography

interface PubStatisticsTableRowTextProps {
  value: string | number
  persentage: number
}

export const PubStatisticsTableRowText = ({
  value,
  persentage,
}: PubStatisticsTableRowTextProps): JSX.Element => {
  return (
    <Text
      strong={true}
      type={Number(value) >= persentage ? "success" : "danger"}
    >
      {value}
    </Text>
  )
}
