import { AdvCampaignOfferTextCheckLength } from "@/components/advertiser/Campaigns/Offers/Texts/TextCheckLength"
import { StdStateSwitcher } from "@/components/common/Std/Form/StateSwitcher"
import { AdvertiserCampaignOfferTextCreate } from "@/definitions"
import { useCreateAdvCampaignOfferTextMutation } from "@/redux/slices/advertiserCampaignsApi"
import { Button, Form, Input } from "antd"
import type { FormProps } from "antd"
import { ChangeEvent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

const { Item, useForm } = Form
const { TextArea } = Input

interface AdvCampaignOfferTextCreateProps {
  offerId: string | undefined
}

export const AdvCampaignOfferTextCreate = ({
  offerId,
}: AdvCampaignOfferTextCreateProps): JSX.Element => {
  const [form] = useForm()
  const { t } = useTranslation()

  const [isActive, setIsActive] = useState<boolean>(false)
  const [text, setText] = useState<string>("")
  const [isErrorText, setIsErrorText] = useState<boolean>(false)

  const [createText, { isSuccess, isLoading }] =
    useCreateAdvCampaignOfferTextMutation()

  useEffect(() => {
    if (isSuccess) {
      form.resetFields()
      setIsErrorText(false)
      setText("")
    }
  }, [isSuccess])

  const handleChangeText = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    const textValue = event.target.value

    setText(textValue)

    const checkElement = document.querySelector(
      ".check-text",
    ) as HTMLElement | null

    if (checkElement) {
      if (
        checkElement.scrollHeight > checkElement?.offsetHeight + 2 ||
        checkElement.scrollWidth > checkElement?.offsetWidth + 2
      ) {
        setIsErrorText(true)
      } else {
        setIsErrorText(false)
      }
    }
  }

  const onFinish: FormProps<AdvertiserCampaignOfferTextCreate>["onFinish"] = (
    values,
  ) => {
    const newValues = {
      ...values,
      offer_id: offerId || "",
      state: isActive ? "on" : "off",
      text: isErrorText ? "" : text,
    }

    createText(newValues)
  }

  return (
    <Form
      form={form}
      id="create-offer-text"
      name="create-offer-text"
      layout="vertical"
      labelCol={{ span: 16 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 450 }}
      onFinish={onFinish}
      autoComplete="off"
      initialValues={{
        state: "off",
      }}
    >
      <Item
        name="text"
        label={t("ADV.CAMPAIGNS.TEXT")}
        rules={[{ required: true, message: t("ADV.CAMPAIGNS.TEXT_REQ") }]}
      >
        <TextArea
          onChange={handleChangeText}
          rows={4}
          placeholder={t("ADV.CAMPAIGNS.TEXT")}
        />
      </Item>
      <AdvCampaignOfferTextCheckLength text={text} isErrorText={isErrorText} />
      <Item name="state">
        <StdStateSwitcher t={t} isActive={isActive} setIsActive={setIsActive} />
      </Item>
      <Item>
        <Button
          disabled={isErrorText}
          loading={isLoading}
          type="primary"
          htmlType="submit"
          style={{ width: "100%" }}
        >
          {t("ADV.CAMPAIGNS.CREATE_BUTTON")}
        </Button>
      </Item>
    </Form>
  )
}
