import { Key, useEffect, useState } from "react"
import { Breadcrumb, Flex, Table, Typography } from "antd"
import { useTranslation } from "react-i18next"
import { StdSpinner } from "@/components/common/Std/Spinner"
import { StdErrorMessage } from "@/components/common/Std/ErrorMessage"
import { StdPaginationRange } from "@/components/common/Std/Selects/PaginationRange"
import { StdColumnsDropdown } from "@/components/common/Std/Dropdowns/ColumnsDropdown"
import { StdDownloadDropdown } from "@/components/common/Std/Dropdowns/DownloadDropdown"
import {
  useGetAdvAllCampaignsQuery,
  useGetAdvCampaignPadsQuery,
} from "@/redux/slices/advertiserCampaignsApi"
import { useGetAdvStatisticsQuery } from "@/redux/slices/advertiserApi"
import { AdvStatisticsCampaignFilters } from "@/components/advertiser/Statistics/Campaign/StatisticsCampaignFilters"
import { AdvStatisticsCampaignTableSummary } from "@/components/advertiser/Statistics/Campaign/StatisticsCampaignTableSummary"
import { NavLink, useNavigate, useParams } from "react-router-dom"
import { DEFAULT_PAGINATION_RANGE } from "@/constants"
import { convertToCsv } from "@/components/advertiser/Statistics/utils"
import { CSVLink } from "react-csv"
import { reducedValue } from "@/utils/reducedValue"
import { valueToFixed } from "@/utils/valueToFixed"
import { ColumnsType } from "antd/es/table"
import { AdvertiserStatisticsItem } from "@/definitions"
import dayjs from "dayjs"

const { Title } = Typography

const TABLE_COLUMNS_TITLES = [
  "offer_id",
  "offer",
  "blind_views",
  "views",
  "clicks",
  "ctr",
  "vctr",
  "avarage_cpc",
  "cost",
]

export const AdvStatisticsCampaign = (): JSX.Element => {
  const { campaignId } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [campaign, setCampaign] = useState<string>("")
  const [pad, setPad] = useState<string>("")
  const [startsBy, setStartsBy] = useState<string>("")
  const [startDate, setStartDate] = useState<string>(
    dayjs().format("YYYY-MM-DD"),
  )
  const [endDate, setEndDate] = useState<string>(dayjs().format("YYYY-MM-DD"))
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [paginationRange, setPaginationRange] = useState<number>(
    DEFAULT_PAGINATION_RANGE,
  )
  const [selectedColumns, setSelectedColumns] =
    useState<string[]>(TABLE_COLUMNS_TITLES)
  const [selectedRows, setSelectedRows] = useState<any[]>([])
  const [selectedRowsKeys, setSelectedRowsKeys] = useState<Key[]>([])

  const {
    data: campaignsData,
    isLoading: campaignsDataIsLoading,
    isError: campaignsDataIsError,
  } = useGetAdvAllCampaignsQuery()

  const {
    data: padsData,
    isLoading: padsDataIsLoading,
    isError: padsDataIsError,
  } = useGetAdvCampaignPadsQuery(campaign, {
    skip: !campaign,
    refetchOnMountOrArgChange: true,
  })

  const { data, isLoading, isError } = useGetAdvStatisticsQuery(
    composeQueryParamsString(
      {
        from: startDate,
        to: endDate,
        campaign_id: campaign,
        pad_id: pad,
      },
      startsBy,
    ),
    { skip: !campaign, refetchOnMountOrArgChange: true },
  )

  useEffect(() => {
    setCampaign(campaignId || "")
  }, [campaignId])

  const summaryData = {
    blindViews: data?.sum?.views_all_sum || "0",
    views: data?.sum?.views_sum || "0",
    clicks: data?.sum?.clicks_sum || "0",
    ctr: data?.sum?.ctr_sum || "0",
    vctr: data?.sum?.vctr_sum || "0",
    avarageCpc: data?.sum?.cpc_sum || "0",
    cost: data?.sum?.cost_sum || "0",
  }

  const csvData = convertToCsv(
    t,
    data?.data,
    summaryData,
    selectedColumns,
    TABLE_COLUMNS_TITLES,
  )

  const selectedRowsSummaryData = {
    blindViews: reducedValue(selectedRows, "blind_views"),
    views: reducedValue(selectedRows, "views"),
    clicks: reducedValue(selectedRows, "clicks"),
    ctr: reducedValue(selectedRows, "ctr"),
    vctr: reducedValue(selectedRows, "vctr"),
    avarageCpc: reducedValue(selectedRows, "avarage_cpc"),
    cost: valueToFixed(reducedValue(selectedRows, "cost"), 4),
  }

  const selectedRowsCsvData = convertToCsv(
    t,
    selectedRows,
    selectedRowsSummaryData,
    selectedColumns,
    TABLE_COLUMNS_TITLES,
  )

  const onSelectedRowsChange = (
    newSelectedRowKeys: Key[],
    selectedRows: any,
  ): void => {
    setSelectedRowsKeys(newSelectedRowKeys)
    setSelectedRows(selectedRows)
  }

  const handlePageChange = (page: number): void => {
    setCurrentPage(page)
  }

  const onChangeStartDate = (_: any, dateString: string): void => {
    setStartDate(dateString)
  }

  const onChangeEndDate = (_: any, dateString: string): void => {
    setEndDate(dateString)
  }

  const onChangeCampaign = (id: string): void => {
    setCampaign(id)
    navigate(`/advertiser/statistics/${id}`)
  }

  const onChangeStartsBy = (value: string): void => {
    switch (value) {
      case "pads":
        setStartsBy("&stat_by_pads=1")
        break
      case "dates":
        setStartsBy("&stat_by_dates=1")
        break
      case "offers":
        setStartsBy("&stat_by_offers_pads=1")
        break
      default:
        break
    }
  }

  if (campaignsDataIsLoading || padsDataIsLoading || isLoading)
    return <StdSpinner />

  if (campaignsDataIsError || padsDataIsError || isError)
    return <StdErrorMessage>Error...</StdErrorMessage>

  const TABLE_COLUMNS: ColumnsType<AdvertiserStatisticsItem> = [
    {
      title: t("ADV.STATISTICS.OFFER_ID"),
      dataIndex: "offer_id",
      key: "offer_id",
      width: 70,
      defaultSortOrder: "descend",
      sorter: (a, b) => Number(a.offer_id) - Number(b.offer_id),
    },
    {
      title: t("ADV.STATISTICS.OFFER"),
      dataIndex: "offer",
      key: "offer",
      sorter: (a, b) => a.offer.localeCompare(b.offer),
    },
    {
      title: t("ADV.STATISTICS.BLIND_VIEWS"),
      dataIndex: "blind_views",
      key: "blind_views",
      sorter: (a, b) => Number(a.blind_views) - Number(b.blind_views),
    },
    {
      title: t("ADV.STATISTICS.VIEWS"),
      dataIndex: "views",
      key: "views",
      sorter: (a, b) => Number(a.views) - Number(b.views),
    },
    {
      title: t("ADV.STATISTICS.CLICKS"),
      dataIndex: "clicks",
      key: "clicks",
      sorter: (a, b) => Number(a.clicks) - Number(b.clicks),
    },
    {
      title: t("ADV.STATISTICS.CTR"),
      dataIndex: "ctr",
      key: "ctr",
      sorter: (a, b) => Number(a.ctr) - Number(b.ctr),
    },
    {
      title: t("ADV.STATISTICS.VCTR"),
      dataIndex: "vctr",
      key: "vctr",
      sorter: (a, b) => Number(a.vctr) - Number(b.vctr),
    },
    {
      title: t("ADV.STATISTICS.AVARAGE_CPC"),
      dataIndex: "avarage_cpc",
      key: "avarage_cpc",
      sorter: (a, b) => Number(a.avarage_cpc) - Number(b.avarage_cpc),
    },
    {
      title: t("ADV.STATISTICS.COST"),
      dataIndex: "cost",
      key: "cost",
      sorter: (a, b) => Number(a.cost) - Number(b.cost),
    },
  ]

  return (
    <Flex vertical gap="large">
      <Flex gap="large" justify="space-between" align="end" wrap="wrap">
        <Flex vertical>
          <Breadcrumb
            items={[
              {
                title: (
                  <NavLink to={"/advertiser/statistics"}>
                    {t("ADV.STATISTICS.CAMPAIGNS")}
                  </NavLink>
                ),
              },
              {
                title: `${t("ADV.STATISTICS.CAMPAIGN_ID")}: ${campaign}`,
              },
            ]}
          />
          <Title level={4} style={{ paddingBottom: "5px" }}>
            {t("ADV.STATISTICS.CAMPAIGN_STATISTICS")}
          </Title>
          <AdvStatisticsCampaignFilters
            t={t}
            campaignsData={campaignsData}
            campaignsDataIsLoading={campaignsDataIsLoading}
            defaultCampaign={campaign}
            padsData={padsData}
            padsDataIsLoading={padsDataIsLoading}
            onChangeCampaign={onChangeCampaign}
            setPad={setPad}
            onChangeStartDate={onChangeStartDate}
            onChangeEndDate={onChangeEndDate}
            onChangeStartsBy={onChangeStartsBy}
          />
        </Flex>
        <Flex gap="large">
          <StdPaginationRange
            t={t}
            paginationRange={paginationRange}
            setPaginationRange={setPaginationRange}
          />
          <StdColumnsDropdown
            t={t}
            namespace="ADV.STATISTICS"
            options={TABLE_COLUMNS_TITLES}
            selectedOptions={selectedColumns}
            setSelectedOptions={setSelectedColumns}
          />
          <StdDownloadDropdown
            items={[
              {
                key: "csv",
                label: (
                  <CSVLink
                    filename="statistics-csv.csv"
                    data={
                      selectedRows?.length > 0 ? selectedRowsCsvData : csvData
                    }
                  >
                    {t("DOWNLOAD.CSV")}
                  </CSVLink>
                ),
              },
            ]}
          />
        </Flex>
      </Flex>
      <div style={{ position: "relative" }}>
        <Table
          columns={TABLE_COLUMNS.filter((col: any) =>
            selectedColumns.includes(col?.dataIndex),
          )}
          loading={isLoading}
          dataSource={Array.isArray(data?.data) ? data?.data : []}
          rowKey="id"
          scroll={{ x: "max-content" }}
          showSorterTooltip={false}
          sortDirections={["ascend", "descend", "ascend"]}
          rowSelection={{
            selectedRowKeys: selectedRowsKeys,
            onChange: onSelectedRowsChange,
            columnWidth: 70,
          }}
          summary={() => {
            if (!!data?.data?.length) {
              return (
                <AdvStatisticsCampaignTableSummary
                  selectedColumns={selectedColumns}
                  defaultColumns={TABLE_COLUMNS_TITLES}
                  views={summaryData.views}
                  clicks={summaryData.clicks}
                  blindViews={summaryData.blindViews}
                  avarageCpc={summaryData.avarageCpc}
                  cost={summaryData.cost}
                  ctr={summaryData.ctr}
                  vctr={summaryData.vctr}
                />
              )
            }
          }}
          pagination={{
            position: ["bottomCenter"],
            current: currentPage,
            pageSize: paginationRange,
            showSizeChanger: false,
            onChange: handlePageChange,
            total: data?.data?.length,
            showTotal: (total, range) => {
              if (range[1] > 0) {
                return (
                  <span className="paginationShowTotal">
                    {t("PAGINATION.SHOW_TOTAL", {
                      firstNumber: range[0],
                      secondNumber: range[1],
                      total: total,
                    })}
                  </span>
                )
              }
            },
          }}
        />
      </div>
    </Flex>
  )
}

const composeQueryParamsString = (params: any, startsByParam: string) => {
  const string = Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&")
  return string + startsByParam
}
