import { Spin } from "antd"
import { CSSProperties } from "react"

const DEFAULT_STYLE: CSSProperties = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
}

interface StdSpinnerProps {
  size?: "small" | "default" | "large"
  style?: CSSProperties
}

export const StdSpinner = ({ size, style }: StdSpinnerProps): JSX.Element => {
  return (
    <div style={style ? { ...DEFAULT_STYLE, ...style } : DEFAULT_STYLE}>
      <Spin size={size || "large"} />
    </div>
  )
}
