import { useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  Button,
  Flex,
  Popconfirm,
  Select,
  Switch,
  Table,
  Typography,
} from "antd"
import { CopyOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { ColumnsType } from "antd/es/table"
import { TFunction } from "i18next"
import { DEFAULT_PAGINATION_RANGE } from "@/constants"
import { StdPaginationRange } from "@/components/common/Std/Selects/PaginationRange"
import { StdColumnsDropdown } from "@/components/common/Std/Dropdowns/ColumnsDropdown"
import { AdvertiserCampaigns } from "@/definitions"
import {
  useDeleteAdvCampaignMutation,
  useUpdateAdvCampaginAllOffersStatesMutation,
} from "@/redux/slices/advertiserCampaignsApi"
import styles from "@/components/advertiser/Campaigns/Campaigns.module.css"

const { Title } = Typography

interface AdvCampaignsTableProps {
  t: TFunction<"translation", undefined>
  campaignsData: AdvertiserCampaigns[] | undefined
}

const TABLE_COLUMNS_TITLES: string[] = [
  "campaign_id",
  "name",
  "payment_model",
  "price",
  "state",
  "limit",
  "daily_limit",
  "action",
]

export const AdvCampaignsTable = ({
  t,
  campaignsData,
}: AdvCampaignsTableProps): JSX.Element => {
  const navigate = useNavigate()

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [paginationRange, setPaginationRange] = useState<number>(
    DEFAULT_PAGINATION_RANGE,
  )
  const [selectedColumns, setSelectedColumns] =
    useState<string[]>(TABLE_COLUMNS_TITLES)
  const [selectedCampaign, setSelectedCampaign] = useState<string>("")

  const [toggleOffersStateCampaign] =
    useUpdateAdvCampaginAllOffersStatesMutation()
  const [deleteCampaign] = useDeleteAdvCampaignMutation()

  const handlePageChange = (page: number): void => {
    setCurrentPage(page)
  }

  const TABLE_COLUMNS: ColumnsType<AdvertiserCampaigns> = [
    {
      title: t(`ADV.CAMPAIGNS.ID`),
      dataIndex: "campaign_id",
      key: "campaign_id",
      width: 70,
      sorter: (a, b) => Number(a.campaign_id) - Number(b.campaign_id),
    },
    {
      title: t(`ADV.CAMPAIGNS.NAME`),
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: <div className={styles.flexItem}>{t("ADV.CAMPAIGNS.STATE")}</div>,
      dataIndex: "state",
      key: "state",
      width: 100,
      sorter: (a, b) => a.state.localeCompare(b.state),
      render: (_: string, record: AdvertiserCampaigns) => (
        <div className={styles.flexItem}>
          <Switch
            checkedChildren={t("BUTTONS.ON")}
            unCheckedChildren={t("BUTTONS.OFF")}
            defaultChecked={record.state === "ON" ? true : false}
            onChange={(checked) => {
              toggleOffersStateCampaign({
                campaign_id: record.campaign_id,
                state: checked ? "on" : "off",
              })
            }}
          />
        </div>
      ),
    },
    {
      title: t("ADV.CAMPAIGNS.PAYMENT_MODEL"),
      dataIndex: "payment_model",
      key: "payment_model",
      sorter: (a, b) => a.payment_model.localeCompare(b.payment_model),
    },
    {
      title: t("ADV.CAMPAIGNS.PRICE_USD"),
      dataIndex: "price",
      key: "price",
      sorter: (a, b) => Number(a.price) - Number(b.price),
    },
    {
      title: t("ADV.CAMPAIGNS.LIMIT"),
      dataIndex: "limit",
      key: "limit",
      render: (limit) => (limit ? limit : "-"),
      sorter: (a, b) =>
        Number(a.limit ? a.limit : 0) - Number(b.limit ? b.limit : 0),
    },
    {
      title: t("ADV.CAMPAIGNS.DAILY_LIMIT"),
      dataIndex: "daily_limit",
      key: "daily_limit",
      render: (daily_limit) => (daily_limit ? daily_limit : "-"),
      sorter: (a, b) =>
        Number(a.daily_limit ? a.daily_limit : 0) -
        Number(b.daily_limit ? b.daily_limit : 0),
    },
    {
      title: <div className={styles.flexItem}>{t("ADV.CAMPAIGNS.ACTION")}</div>,
      dataIndex: "action",
      key: "action",
      width: 160,
      render: (_: any, record: AdvertiserCampaigns) => (
        <div className={styles.flexGap}>
          <Button
            onClick={() =>
              navigate(`/advertiser/campaigns/${record.campaign_id}`)
            }
            style={{ padding: "4px 10px" }}
            type="primary"
          >
            <EditOutlined />
          </Button>
          <Button style={{ padding: "4px 10px" }} type="primary">
            <CopyOutlined />
          </Button>
          <Popconfirm
            title={t("ADV.CAMPAIGNS.DELETE_CONFIRMATION")}
            okButtonProps={{ danger: true }}
            onConfirm={() => {
              deleteCampaign(record.campaign_id)
            }}
          >
            <Button style={{ padding: "4px 10px" }} danger type="primary">
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ]

  const ALL_ITEM = {
    label: t("SELECTS.ALL"),
    value: "",
  }

  const selectOptions = campaignsData?.map((campaign) => ({
    label: `${campaign.campaign_id} ${campaign.name}`,
    value: `${campaign.campaign_id} ${campaign.name}`,
  }))

  return (
    <Flex vertical gap="large">
      <Flex
        gap="large"
        wrap="wrap"
        style={{ width: "100%" }}
        align="flex-end"
        justify="space-between"
      >
        <Flex gap="large" vertical style={{ maxWidth: "300px", width: "100%" }}>
          <Title level={4}>{t("ADV.CAMPAIGNS.TABLE_TITLE")}</Title>

          <Select
            placeholder={t("SELECTS.ID_CAMPAING_NAME_PLACEHOLDER")}
            onChange={(value) => setSelectedCampaign(value)}
            showSearch
            options={selectOptions ? [ALL_ITEM, ...selectOptions] : []}
          />
        </Flex>

        <Flex gap="large" wrap="wrap">
          <StdPaginationRange
            t={t}
            paginationRange={paginationRange}
            setPaginationRange={setPaginationRange}
          />
          <StdColumnsDropdown
            t={t}
            namespace="ADV.CAMPAIGNS"
            options={TABLE_COLUMNS_TITLES}
            selectedOptions={selectedColumns}
            setSelectedOptions={setSelectedColumns}
          />
        </Flex>
      </Flex>

      <div style={{ position: "relative" }}>
        <Table
          rowKey="campaign_id"
          scroll={{ x: "max-content" }}
          showSorterTooltip={false}
          sortDirections={["ascend", "descend", "ascend"]}
          dataSource={getTableData(campaignsData, selectedCampaign)}
          columns={TABLE_COLUMNS.filter((col: any) =>
            selectedColumns.includes(col?.dataIndex),
          )}
          pagination={{
            position: ["bottomCenter"],
            current: currentPage,
            pageSize: paginationRange,
            showSizeChanger: false,
            onChange: handlePageChange,
            total: campaignsData?.length,
            showTotal: (total, range) => {
              if (range[1] > 0) {
                return (
                  <span className="paginationShowTotal">
                    {t("PAGINATION.SHOW_TOTAL", {
                      firstNumber: range[0],
                      secondNumber: range[1],
                      total: total,
                    })}
                  </span>
                )
              }
            },
          }}
        />
      </div>
    </Flex>
  )
}

const getTableData = (
  data: AdvertiserCampaigns[] | undefined,
  selectedCampaign: string,
) => {
  if (!data) return []

  if (!selectedCampaign) return data

  return data.filter(
    (campaign) =>
      `${campaign.campaign_id} ${campaign.name}` === selectedCampaign,
  )
}
