import { Flex, Typography } from "antd"
import { ReactNode } from "react"

const { Text } = Typography

interface StdLabelProps {
  label: string
  children: ReactNode
}

export const StdLabel = ({ label, children }: StdLabelProps): JSX.Element => {
  return (
    <Flex gap="8px" vertical>
      <Text>{label}</Text>
      {children}
    </Flex>
  )
}
