import { Typography, Table, Flex, Select } from "antd"
import { StdErrorMessage } from "@/components/common/Std/ErrorMessage"
import { StdSpinner } from "@/components/common/Std/Spinner"
import { useGetAdvAllCampaignsQuery } from "@/redux/slices/advertiserCampaignsApi"
import { useTranslation } from "react-i18next"
import { useState } from "react"
import { AdvertiserCampaigns } from "@/definitions"
import { ColumnsType } from "antd/es/table"
import { NavLink } from "react-router-dom"
import { BaseType } from "antd/es/typography/Base"

const { Title, Text } = Typography

export const AdvStatistics = (): JSX.Element => {
  const { t } = useTranslation()

  const [selectedCampaign, setSelectedCampaign] = useState<string>("")

  const {
    data: campaignsData,
    isLoading: campaignsDataIsLoading,
    isError: campaignsDataIsError,
  } = useGetAdvAllCampaignsQuery()

  if (campaignsDataIsLoading) return <StdSpinner />

  if (campaignsDataIsError) return <StdErrorMessage>Error...</StdErrorMessage>

  const TABLE_COLUMNS: ColumnsType<AdvertiserCampaigns> = [
    {
      title: t("ADV.STATISTICS.ID"),
      dataIndex: "campaign_id",
      key: "campaign_id",
      width: 70,
      sorter: (a, b) => Number(a.campaign_id) - Number(b.campaign_id),
      defaultSortOrder: "descend",
    },
    {
      title: t("ADV.STATISTICS.CAMPAIGN"),
      dataIndex: "name",
      key: "name",
      render: (name: string, record: AdvertiserCampaigns) => (
        <NavLink to={`/advertiser/statistics/${record.campaign_id}`}>
          {name}
        </NavLink>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: t("ADV.STATISTICS.PAYMENT_MODEL"),
      dataIndex: "payment_model",
      key: "payment_model",
      sorter: (a, b) => a.payment_model.localeCompare(b.payment_model),
    },
    {
      title: t("ADV.STATISTICS.PRICE"),
      dataIndex: "price",
      key: "price",
      sorter: (a, b) => Number(a.price) - Number(b.price),
    },
    {
      title: t("ADV.STATISTICS.LIMIT"),
      dataIndex: "limit",
      key: "limit",
      render: (limit, record) =>
        limit ? (
          <Text strong={true} type={getLimitColor(limit, record.used_limit)}>
            {record.used_limit + "/" + limit}
          </Text>
        ) : (
          "-"
        ),
      sorter: (a, b) => Number(a.limit) - Number(b.limit),
    },
    {
      title: t("ADV.STATISTICS.DAILY_LIMIT"),
      dataIndex: "daily_limit",
      key: "daily_limit",
      render: (daily_limit) => (daily_limit ? daily_limit : "-"),
      sorter: (a, b) => Number(a.daily_limit) - Number(b.daily_limit),
    },
  ]

  const ALL_ITEM = {
    label: t("SELECTS.ALL"),
    value: "",
  }

  const selectOptions = campaignsData?.map((campaign) => ({
    label: `${campaign.campaign_id} ${campaign.name}`,
    value: `${campaign.campaign_id} ${campaign.name}`,
  }))

  return (
    <Flex gap="large" vertical>
      <Flex gap="large" vertical style={{ maxWidth: "300px" }}>
        <Title level={3}>{t("ADV.STATISTICS.CAMPAIGNS_STATISTICS")}</Title>
        <Select
          placeholder={t("SELECTS.ID_CAMPAING_NAME_PLACEHOLDER")}
          onChange={(value) => setSelectedCampaign(value)}
          showSearch
          options={selectOptions ? [ALL_ITEM, ...selectOptions] : []}
        />
      </Flex>

      <Table
        loading={campaignsDataIsLoading}
        dataSource={getTableData(campaignsData, selectedCampaign)}
        scroll={{ x: "max-content" }}
        rowKey="campaign_id"
        showSorterTooltip={false}
        sortDirections={["ascend", "descend", "ascend"]}
        columns={TABLE_COLUMNS}
        pagination={{ position: ["bottomCenter"], showSizeChanger: false }}
      />
    </Flex>
  )
}

const getTableData = (
  data: AdvertiserCampaigns[] | undefined,
  selectedCampaign: string,
) => {
  if (!data) return []

  if (!selectedCampaign) return data

  return data.filter(
    (campaign) =>
      `${campaign.campaign_id} ${campaign.name}` === selectedCampaign,
  )
}

const getLimitColor = (
  limit: string | null,
  usedLimit: string | null,
): BaseType => {
  const usedLimitPercentage = (Number(usedLimit) / Number(limit)) * 100

  if (usedLimitPercentage >= 50) return "success"

  if (usedLimitPercentage < 50 && usedLimitPercentage >= 25) return "warning"

  // if (usedLimitPercentage < 25) return "danger"

  return "danger"
}
