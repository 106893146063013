import { Dispatch, SetStateAction } from "react"
import { Select } from "antd"
import { textToUpperCase } from "@/utils/textToUpperCase"
import { TFunction } from "i18next"

const OPTIONS_TITLES: string[] = [
  "views",
  "clicks",
  "pubs_widget_views",
  "profit",
  "blind_widget_views",
  "pubs_blind_widget_views",
]

interface GraphDataSelectProps {
  t: TFunction<"translation", undefined>
  onChange: Dispatch<SetStateAction<string>>
  defaultValue: string
  withoutTitle: string
  className: string
}

export const GraphDataSelect = ({
  t,
  onChange,
  defaultValue,
  withoutTitle,
  className,
}: GraphDataSelectProps): JSX.Element => {
  const filteredOptions = OPTIONS_TITLES.filter(
    (title) => title !== withoutTitle,
  )

  return (
    <Select
      className={className}
      style={{ flex: "1", maxWidth: "138px", width: "100%" }}
      optionFilterProp="children"
      defaultValue={defaultValue}
      onChange={(value) => onChange(value)}
      options={filteredOptions.map((value) => ({
        label: t(`PUB.GRAPH.${textToUpperCase(value)}`),
        value: value,
      }))}
    />
  )
}
