import { createApiLink, validateError } from "@/utils"
import { publisherSplitApi } from "@/redux/emptySplitApi"
import { userApi } from "@/redux/slices/userApi"
import { getQueriesModerationCount } from "@/utils/getQueriesModerationCount"
import {
  ApiResponseError,
  ApiResponseOk,
  PublisherCampaign,
  PublisherGraph,
  PublisherInfo,
  PublisherInfoUpdate,
  PublisherModeration,
  PublisherNotification,
  PublisherNotificationUpdate,
  PublisherOffersDomains,
  PublisherPayment,
  PublisherSource,
  PublisherSourceCreate,
  PublisherSourceDefaultPrice,
  PublisherSourceDeviceType,
  PublisherSourceEdit,
  PublisherSourceStateEdit,
  PublisherSourceType,
  PublisherStatistics,
} from "@/definitions"

export const apiWithTag = publisherSplitApi.enhanceEndpoints({
  addTagTypes: ["publisher"],
})

export const publisherApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getPubUserInfo: builder.query<PublisherInfo, void>({
      query: () => ({
        url: `${createApiLink(`/publisher/`)}`,
        method: "GET",
      }),
      providesTags: () => [{ type: "publisher" }],
    }),
    updatePubUserInfo: builder.mutation<
      ApiResponseOk | ApiResponseError,
      PublisherInfoUpdate
    >({
      query: ({ name, phone, companyName, website, timezone }) => ({
        url: `${createApiLink(`/publisher/`)}`,
        method: "PUT",
        body: {
          name,
          phone,
          timezone,
          company_name: companyName,
          site_name: website,
        },
        validateStatus: (response, result) => validateError(response, result),
      }),
      invalidatesTags: () => [{ type: "publisher" }],
    }),
    getPubCampaigns: builder.query<PublisherCampaign[], void>({
      query: () => ({
        url: `${createApiLink(`/publisher/campaigns/`)}`,
        method: "GET",
      }),
      providesTags: () => [{ type: "publisher" }],
    }),
    getPubSources: builder.query<PublisherSource[], string>({
      query: (paramsString) => ({
        url: `${createApiLink(`/publisher/sources/`, paramsString)}`,
        method: "GET",
      }),
      providesTags: () => [{ type: "publisher" }],
    }),
    getPubSource: builder.query<PublisherSource, string>({
      query: (id) => ({
        url: `${createApiLink(`/publisher/sources/${id}/`)}`,
        method: "GET",
      }),
      providesTags: () => [{ type: "publisher" }],
    }),
    createPubSource: builder.mutation<
      ApiResponseOk | ApiResponseError,
      PublisherSourceCreate
    >({
      query: (data) => ({
        url: `${createApiLink(`/publisher/sources/`)}`,
        method: "POST",
        body: data,
        validateStatus: (response, result) => validateError(response, result),
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled
          dispatch(
            userApi.endpoints.getModerationsCount.initiate(
              getQueriesModerationCount("publisher"),
            ),
          )
        } catch (error) {
          console.error("Error CREATE widget/source:", error)
        }
      },
      invalidatesTags: () => [{ type: "publisher" }],
    }),
    editPubSource: builder.mutation<
      ApiResponseOk | ApiResponseError,
      PublisherSourceEdit
    >({
      query: ({
        source_id,
        domain,
        source,
        payment_model,
        price,
        teaser_type,
        country,
        user_comments,
      }) => ({
        url: `${createApiLink(`/publisher/sources/${source_id}/`)}`,
        method: "PUT",
        body: {
          domain,
          source,
          payment_model,
          price,
          teaser_type,
          country,
          user_comments,
        },
        validateStatus: (response, result) => validateError(response, result),
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled
          dispatch(
            userApi.endpoints.getModerationsCount.initiate(
              getQueriesModerationCount("publisher"),
            ),
          )
        } catch (error) {
          console.error("Error EDIT widget/source:", error)
        }
      },
      invalidatesTags: () => [{ type: "publisher" }],
    }),
    deletePubSource: builder.mutation<ApiResponseOk | ApiResponseError, string>(
      {
        query: (source_id) => ({
          url: `${createApiLink(`/publisher/sources/${source_id}/`)}`,
          method: "DELETE",
          validateStatus: (response, result) => validateError(response, result),
        }),
        onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
          try {
            await queryFulfilled
            dispatch(
              userApi.endpoints.getModerationsCount.initiate(
                getQueriesModerationCount("publisher"),
              ),
            )
          } catch (error) {
            console.error("Error DELETE widget/source:", error)
          }
        },
        invalidatesTags: () => [{ type: "publisher" }],
      },
    ),
    getPubSourceTypes: builder.query<PublisherSourceType[], void>({
      query: () => ({
        url: `${createApiLink(`/publisher/SourceType/`)}`,
        method: "GET",
      }),
      providesTags: () => [{ type: "publisher" }],
    }),
    editPubSourceState: builder.mutation<
      ApiResponseOk | ApiResponseError,
      PublisherSourceStateEdit
    >({
      query: (data) => ({
        url: `${createApiLink(`/publisher/SourceState`)}`,
        method: "POST",
        body: data,
        validateStatus: (response, result) => validateError(response, result),
      }),
      invalidatesTags: () => [{ type: "publisher" }],
    }),
    getPubSourceDefaultPrice: builder.query<
      PublisherSourceDefaultPrice,
      string
    >({
      query: (paramsString) => ({
        url: `${createApiLink(`/publisher/SourceDefaultPrice/`, paramsString)}`,
        method: "GET",
      }),
      providesTags: () => [{ type: "publisher" }],
    }),
    getPubSourceDevicesTypes: builder.query<PublisherSourceDeviceType[], void>({
      query: () => ({
        url: `${createApiLink(`/publisher/SourceDeviceType/`)}`,
        method: "GET",
      }),
      providesTags: () => [{ type: "publisher" }],
    }),
    getPubPads: builder.query<any, any>({
      query: () => ({
        url: `${createApiLink(`/publisher/pads/`)}`,
        method: "GET",
      }),
      providesTags: () => [{ type: "publisher" }],
    }),
    getPubPayments: builder.query<PublisherPayment[], void>({
      query: () => ({
        url: `${createApiLink(`/publisher/payments/`)}`,
        method: "GET",
      }),
      providesTags: () => [{ type: "publisher" }],
    }),
    getPubStatistics: builder.query<PublisherStatistics, string>({
      query: (paramsString) => ({
        url: `${createApiLink(`/publisher/statistics/`, paramsString)}`,
        method: "GET",
      }),
      providesTags: () => [{ type: "publisher" }],
    }),
    getPubGraphStatistics: builder.query<PublisherGraph[], string>({
      query: (paramsString) => ({
        url: `${createApiLink(`/publisher/graphStatistics/`, paramsString)}`,
        method: "GET",
      }),
      providesTags: () => [{ type: "publisher" }],
    }),
    getPubOffersStatistics: builder.query<PublisherOffersDomains, string>({
      query: (paramsString) => ({
        url: `${createApiLink(`/publisher/offersDomains/`, paramsString)}`,
        method: "GET",
      }),
      providesTags: () => [{ type: "publisher" }],
    }),
    getPubNotifications: builder.query<PublisherNotification[], string>({
      query: (paramsString) => ({
        url: `${createApiLink(`/publisher/notifications/`, paramsString)}`,
        method: "GET",
      }),
      providesTags: () => [{ type: "publisher" }],
    }),
    updatePubNotifications: builder.mutation<
      ApiResponseOk | ApiResponseError,
      PublisherNotificationUpdate[]
    >({
      query: (data) => ({
        url: `${createApiLink(`/publisher/notifications/`)}`,
        method: "PUT",
        body: data,
        validateStatus: (response, result) => validateError(response, result),
      }),
      invalidatesTags: () => [{ type: "publisher" }],
    }),
    getPubModerations: builder.query<PublisherModeration[], string>({
      query: (paramsString) => ({
        url: `${createApiLink(`/publisher/moderation/`, paramsString)}`,
        method: "GET",
      }),
      providesTags: () => [{ type: "publisher" }],
    }),
  }),
  overrideExisting: false,
})

export const {
  // Info
  useGetPubUserInfoQuery,
  useUpdatePubUserInfoMutation,
  // Notifications
  useGetPubNotificationsQuery,
  useUpdatePubNotificationsMutation,
  // Campaign
  useGetPubCampaignsQuery,
  // Source/Widget
  useGetPubSourcesQuery,
  useLazyGetPubSourcesQuery,
  useGetPubSourceQuery,
  useCreatePubSourceMutation,
  useEditPubSourceMutation,
  useDeletePubSourceMutation,
  useGetPubSourceTypesQuery,
  useEditPubSourceStateMutation,
  useGetPubSourceDefaultPriceQuery,
  useGetPubSourceDevicesTypesQuery,
  // Moderation
  useLazyGetPubModerationsQuery,
  // Pads
  useGetPubPadsQuery,
  // Payments
  useGetPubPaymentsQuery,
  // Statistics
  useGetPubStatisticsQuery,
  useLazyGetPubStatisticsQuery,
  // Graph
  useLazyGetPubGraphStatisticsQuery,
  // Offers
  useLazyGetPubOffersStatisticsQuery,
} = publisherApi
