import { Typography } from "antd"
import { ReactNode } from "react"

const { Text } = Typography

interface StdErrorMessageProps {
  children: ReactNode
}

export const StdErrorMessage = ({
  children,
}: StdErrorMessageProps): JSX.Element => {
  return <Text>{children}</Text>
}
