import { PublisherSourceType } from "@/definitions"
import { useGetPubSourceTypesQuery } from "@/redux/slices/publisherApi"
import { filterOptions } from "@/utils/filterOptions"
import { Select } from "antd"
import { TFunction } from "i18next"

interface WidgetsTypesSelectProps {
  t: TFunction<"translation", undefined>
  onChange: (value: string) => void
  defaultValue?: string
  disabled?: boolean
}

export const WidgetsTypesSelect = ({
  t,
  onChange,
  defaultValue,
  disabled,
}: WidgetsTypesSelectProps): JSX.Element => {
  const { data, isLoading } = useGetPubSourceTypesQuery()

  return (
    <Select
      placeholder={t("SELECTS.WIDGET_TYPE_PLACEHOLDER")}
      showSearch
      disabled={disabled}
      optionFilterProp="children"
      defaultValue={defaultValue}
      filterOption={filterOptions}
      loading={isLoading}
      onChange={(value: string) => onChange(value)}
      options={data?.map((type: PublisherSourceType, key: number) => ({
        key,
        label: type.name,
        value: type.id,
      }))}
    />
  )
}
