import { TFunction } from "i18next"
import { Typography } from "antd"
import styles from "@/components/common/UserVerify/UserVerify.module.css"

const { Title, Text } = Typography

interface PlaceholdersProps {
  t: TFunction<"translation", undefined>
}

export const Placeholders = {
  Loading: ({ t }: PlaceholdersProps): JSX.Element => {
    return (
      <>
        <Title level={2}>{t("VERIFICATION.TITLE")}</Title>
        <div className={styles.dotContainer}>
          <div className={styles.dot} />
          <div className={styles.dot} />
          <div className={styles.dot} />
          <div className={styles.dot} />
          <div className={styles.dot} />
        </div>
      </>
    )
  },
  Success: ({ t }: PlaceholdersProps): JSX.Element => {
    return (
      <div className={styles.placeholder}>
        <Title level={3} style={{ color: "#00b96b" }}>
          {t("VERIFICATION.SUCCESS")}
        </Title>
        <Text style={{ fontSize: "16px" }}>
          {t("VERIFICATION.SUCCESS_TEXT")}
        </Text>
      </div>
    )
  },
  Failed: ({ t }: PlaceholdersProps): JSX.Element => {
    return (
      <div className={styles.placeholder}>
        <Title level={3} style={{ color: "#dc4446" }}>
          {t("VERIFICATION.FAILED")}
        </Title>
        <Text style={{ fontSize: "16px" }}>
          {t("VERIFICATION.FAILED_TEXT")}
        </Text>
      </div>
    )
  },
}
