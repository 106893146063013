import { Typography, DatePicker, Select, Flex } from "antd"
import { useEffect, useState } from "react"
import {
  useGetPubPadsQuery,
  useGetPubSourcesQuery,
  useLazyGetPubGraphStatisticsQuery,
} from "@/redux/slices/publisherApi"
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Area,
  AreaChart,
} from "recharts"
import { useTranslation } from "react-i18next"
import { StdDownloadDropdown } from "@/components/common/Std/Dropdowns/DownloadDropdown"
import { StdSpinner } from "@/components/common/Std/Spinner"
import { StdErrorMessage } from "@/components/common/Std/ErrorMessage"
import { StdLabel } from "@/components/common/Std/Label"
import { exportPngFile } from "@/utils/exportFiles"
import { textToUpperCase } from "@/utils/textToUpperCase"
import { getCookie } from "@/utils/cookies"
import { TFunction } from "i18next"
import { CSVLink } from "react-csv"
import { PublisherGraph } from "@/definitions"
import { GraphDataSelect } from "@/components/publisher/Graph/GraphDataSelect"
import { DefaultOptionType } from "antd/es/select"
import dayjs from "dayjs"

const { Title } = Typography

const DEFAULT_MAX_Y = 4

const FIRST_LINE_COLOR = "#00b96b"
const SECOND_LINE_COLOR = "#1890ff"

export const PubGraph = (): JSX.Element => {
  const { t } = useTranslation()

  const [startDate, setStartDate] = useState<string>(
    dayjs().subtract(7, "day").format("YYYY-MM-DD"),
  )
  const [endDate, setEndDate] = useState<string>(dayjs().format("YYYY-MM-DD"))
  const [websiteId, setWebsiteId] = useState<string>("all")
  const [widgetId, setWidgetId] = useState<string>("")
  const [firstSelectedValue, setFirstSelectedValue] = useState<string>("views")
  const [secondSelectedValue, setSecondSelectedValue] =
    useState<string>("clicks")

  const theme = getCookie("theme")
  const isLightTheme = theme === "light"

  const {
    data: pubSourcData,
    isLoading: pubSourcIsLoading,
    isError: pubSourcIsError,
  } = useGetPubPadsQuery({})

  const [fetchData, { data, isError }] = useLazyGetPubGraphStatisticsQuery()

  const { data: widgetsData, isLoading: widgetsDataIsLoading } =
    useGetPubSourcesQuery(`ms_id=-1&pad_id=${websiteId}`, { skip: !websiteId })

  useEffect(() => {
    fetchData(
      `from=${startDate}&to=${endDate}&pad_id=${websiteId}&source_id=${widgetId}&${firstSelectedValue}=1&${secondSelectedValue}=1`,
    )
  }, [
    firstSelectedValue,
    secondSelectedValue,
    startDate,
    endDate,
    widgetId,
    websiteId,
  ])

  const onChangeStartDate = (_: any, dateString: string): void => {
    setStartDate(dateString)
  }

  const onChangeEndDate = (_: any, dateString: string): void => {
    setEndDate(dateString)
  }

  const onChangeWidgetId = (value: string): void => {
    if (value === "all") {
      setWidgetId("")
      return
    }

    const widgetId = value.split(" - ")[0]

    setWidgetId(widgetId)
  }

  const onChangeWebsiteId = (value: string): void => {
    if (value === "all") {
      setWebsiteId("all")
      return
    }

    const websitetId = value.split(" - ")[0]

    setWebsiteId(websitetId)
  }

  if (pubSourcIsLoading) {
    return <StdSpinner />
  }

  if (pubSourcIsError || isError) {
    return <StdErrorMessage>Error...</StdErrorMessage>
  }

  const ALL_OPTION: DefaultOptionType = {
    label: t("SELECTS.ALL"),
    value: "all",
  }

  const websitesOptions = pubSourcData?.map((pad: any) => ({
    label: pad.domain,
    value: `${pad.pad_id} - ${pad.pad_name}`,
  }))

  const widgetsOptions = widgetsData?.map((item) => ({
    label: `${item.id} ${item.source}`,
    value: `${item.id} - ${item.source}`,
  }))

  const chartId = "chart-graph"

  return (
    <Flex vertical gap="large">
      <Title level={3}>{t("PUB.GRAPH.GRAPH")}</Title>

      <Flex gap="large" wrap="wrap" align="flex-end" justify="space-between">
        <Flex gap="large" vertical style={{ maxWidth: "300px", width: "100%" }}>
          <Flex gap="large" wrap="wrap">
            <DatePicker
              style={{ flex: "1" }}
              onChange={onChangeStartDate}
              placeholder={t("SELECTS.START_DATE_PLACEHOLDER")}
              defaultValue={dayjs().subtract(7, "day")}
            />
            <DatePicker
              style={{ flex: "1" }}
              onChange={onChangeEndDate}
              placeholder={t("SELECTS.END_DATE_PLACEHOLDER")}
              defaultValue={dayjs()}
            />
          </Flex>

          <StdLabel label={t("SELECTS.WEBSITE")}>
            <Select
              showSearch
              defaultValue="all"
              placeholder={t("ALL")}
              onChange={onChangeWebsiteId}
              options={
                websitesOptions
                  ? [ALL_OPTION, ...websitesOptions]
                  : [ALL_OPTION]
              }
            />
          </StdLabel>

          <StdLabel label={t("SELECTS.WIDGET")}>
            <Select
              showSearch
              loading={widgetsDataIsLoading}
              defaultValue="all"
              placeholder={t("ALL")}
              onChange={onChangeWidgetId}
              options={
                widgetsOptions ? [ALL_OPTION, ...widgetsOptions] : [ALL_OPTION]
              }
            />
          </StdLabel>

          <Flex gap="large" wrap="wrap" style={{ width: "100%" }}>
            <GraphDataSelect
              t={t}
              onChange={setFirstSelectedValue}
              defaultValue="views"
              withoutTitle={secondSelectedValue}
              className="green-selected-item"
            />
            <GraphDataSelect
              t={t}
              onChange={setSecondSelectedValue}
              defaultValue="clicks"
              withoutTitle={firstSelectedValue}
              className="blue-selected-item"
            />
          </Flex>
        </Flex>

        <StdDownloadDropdown
          items={[
            {
              key: "png",
              label: t("DOWNLOAD.PNG"),
              onClick: () => exportPngFile(chartId),
            },
            {
              key: "csv",
              label: (
                <CSVLink filename="graphs-csv.csv" data={convertToCsv(data, t)}>
                  {t("DOWNLOAD.CSV")}
                </CSVLink>
              ),
            },
          ]}
        />
      </Flex>

      <Flex
        vertical
        style={
          isLightTheme
            ? {
                background: "#fff",
                borderRadius: "6px",
                transition: "background 0.2s",
                border: "1px solid #d9d9d9",
              }
            : {
                border: "1px solid #424242",
                background: "#141414",
                borderRadius: "6px",
                transition: "background 0.2s",
              }
        }
      >
        <div
          style={{
            width: "100%",
            height: "460px",
          }}
          id={chartId}
        >
          <ResponsiveContainer width="100%" height={460}>
            <AreaChart
              data={data}
              margin={{ top: 30, bottom: 30, left: -10, right: -10 }}
            >
              <CartesianGrid strokeDasharray="3 5" vertical={false} />
              <XAxis dataKey={startDate === endDate ? "hour" : "date"} />

              <YAxis
                type="number"
                domain={[0, getMaxValueY(data, firstSelectedValue) || "auto"]}
                yAxisId="left"
                tick={{ fill: FIRST_LINE_COLOR }}
                tickFormatter={(value) => {
                  if (value >= 1000000) {
                    return `${(value / 1000000).toFixed(1)}M`
                  } else if (value >= 1000) {
                    return `${(value / 1000).toFixed(1)}K`
                  } else {
                    return value
                  }
                }}
              />

              <YAxis
                type="number"
                domain={[0, getMaxValueY(data, secondSelectedValue) || "auto"]}
                yAxisId="right"
                orientation="right"
                tick={{ fill: SECOND_LINE_COLOR }}
                tickFormatter={(value) => {
                  if (value >= 1000000) {
                    return `${(value / 1000000).toFixed(1)}M`
                  } else if (value >= 1000) {
                    return `${(value / 1000).toFixed(1)}K`
                  } else {
                    return value
                  }
                }}
              />

              <Tooltip labelStyle={{ color: "rgba(0, 0, 0, 0.88)" }} />
              <Legend order={2} />

              <Area
                type="monotone"
                name={
                  t(
                    `PUB.GRAPH.${textToUpperCase(firstSelectedValue)}`,
                  ) as unknown as string
                }
                dataKey={firstSelectedValue}
                stroke={FIRST_LINE_COLOR}
                fill={FIRST_LINE_COLOR}
                strokeWidth={4}
                fillOpacity={0.4}
                dot={{ stroke: FIRST_LINE_COLOR, fill: FIRST_LINE_COLOR }}
                yAxisId="left"
              />

              <Area
                type="monotone"
                name={
                  t(
                    `PUB.GRAPH.${textToUpperCase(secondSelectedValue)}`,
                  ) as unknown as string
                }
                dataKey={secondSelectedValue}
                stroke={SECOND_LINE_COLOR}
                fill={SECOND_LINE_COLOR}
                strokeWidth={4}
                fillOpacity={0.4}
                dot={{ stroke: SECOND_LINE_COLOR, fill: SECOND_LINE_COLOR }}
                yAxisId="right"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </Flex>
    </Flex>
  )
}

const getMaxValueY = (
  data: PublisherGraph[] | undefined,
  objectKey: string,
): number => {
  if (!data || !data.length) return DEFAULT_MAX_Y

  return (
    Math.ceil(
      Math.max(
        ...data?.map(
          (item: PublisherGraph) =>
            Number(item[objectKey as keyof PublisherGraph]) || 0,
        ),
      ),
    ) || DEFAULT_MAX_Y
  )
}

export const convertToCsv = (
  data: PublisherGraph[] | undefined,
  t: TFunction<"translation", undefined>,
) => {
  if (!data || !data.length) return []

  const csvData = []

  if (data?.length > 0) {
    // Add header row and translate keys
    const headerRow = Object.keys(data[0]).map((header) =>
      t(`PUB.GRAPH.${textToUpperCase(header)}`),
    )
    csvData.push(headerRow.join(","))
  }

  // Add data rows
  data.forEach((row: PublisherGraph) => {
    csvData.push(Object.values(row))
  })

  return csvData.join("\n")
}
