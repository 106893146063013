import { useEffect, useState } from "react"
import { Route, Routes, Navigate, Outlet } from "react-router-dom"
import { Toaster } from "react-hot-toast"
import { ConfigProvider, theme } from "antd"
import { getLocaleOrSetDefault } from "@/utils"
import { getCookie, setCookie } from "@/utils/cookies"
import { useTranslation } from "react-i18next"
import { Roles } from "@/definitions"

import { UnAuthLayout } from "@/components/common/Layouts/UnAuthLayout"
import { AuthLayout } from "@/components/common/Layouts/AuthLayout"
import { ProtectedRoute } from "@/components/common/ProtectedRoute"
import { Contacts } from "@/components/common/Contacts"
import { Login } from "@/components/common/Login/Login"
import { UserVerify } from "@/components/common/UserVerify"
// import { Register } from "@/components/common/Register/Register"

import { PubAccount } from "@/components/publisher/Account"
import { PubStatistics } from "@/components/publisher/Statistics"
import { PubWidgets } from "@/components/publisher/Widgets"
import { PubWidget } from "@/components/publisher/Widgets/Widget"
import { PubModeration } from "@/components/publisher/Moderation"
import { PubPayment } from "@/components/publisher/Payment"
import { PubGraph } from "@/components/publisher/Graph"
import { PubOfferDomains } from "@/components/publisher/OfferDomains"

import { AdvAccount } from "@/components/advertiser/Account"
import { AdvStatistics } from "@/components/advertiser/Statistics"
import { AdvStatisticsCampaign } from "@/components/advertiser/Statistics/Campaign"
import { AdvToolsDevicesStatistics } from "@/components/advertiser/Tools/ToolsDevicesStatistics"
import { AdvToolsGeoStatistics } from "@/components/advertiser/Tools/ToolsGeoStatistics"
import { AdvToolsOfferDomains } from "@/components/advertiser/Tools/ToolsOffersDomains"
import { AdvPayment } from "@/components/advertiser/Payment"
import { AdvGraph } from "@/components/advertiser/Graph"
import { AdvModerationOffers } from "@/components/advertiser/Moderation/Offers"
import { AdvModerationCreatives } from "@/components/advertiser/Moderation/Creatives"
import { AdvModerationTexts } from "@/components/advertiser/Moderation/Texts"
import { AdvCampaigns } from "@/components/advertiser/Campaigns"
import { AdvCampaign } from "@/components/advertiser/Campaigns/Campaign/Campaign"
import { AdvCampaignOffer } from "@/components/advertiser/Campaigns/Offers/Offer"
import { AdvCampaignOfferText } from "@/components/advertiser/Campaigns/Offers/Texts/Text"
import { AdvCampaignOfferCreative } from "@/components/advertiser/Campaigns/Offers/Creatives/Creative"

const AdvertiserRoutes = () => {
  return (
    <Routes>
      <Route path="account" element={<AdvAccount />} />
      <Route path="statistics" element={<AdvStatistics />} />
      <Route
        path="statistics/:campaignId"
        element={<AdvStatisticsCampaign />}
      />
      <Route path="payments" element={<AdvPayment />} />
      <Route path="graphs" element={<AdvGraph />} />
      <Route path="tools/devices" element={<AdvToolsDevicesStatistics />} />
      <Route path="tools/geolps" element={<AdvToolsGeoStatistics />} />
      <Route path="tools/domains" element={<AdvToolsOfferDomains />} />
      <Route path="moderation/offers" element={<AdvModerationOffers />} />
      <Route path="moderation/creatives" element={<AdvModerationCreatives />} />
      <Route path="moderation/texts" element={<AdvModerationTexts />} />
      <Route path="campaigns" element={<AdvCampaigns />} />
      <Route path="campaigns/:campaignId" element={<AdvCampaign />} />
      <Route
        path="campaigns/:campaignId/offers/:offerId"
        element={<AdvCampaignOffer />}
      />
      <Route
        path="campaigns/:campaignId/offers/:offerId/texts/:textId"
        element={<AdvCampaignOfferText />}
      />
      <Route
        path="campaigns/:campaignId/offers/:offerId/creatives/:creativeId"
        element={<AdvCampaignOfferCreative />}
      />
      <Route path="contacts" element={<Contacts />} />
      <Route path="*" element={<Navigate to="statistics" replace />} />
    </Routes>
  )
}

const PublisherRoutes = () => {
  return (
    <Routes>
      <Route path="account" element={<PubAccount />} />
      <Route path="statistics" element={<PubStatistics />} />
      <Route path="widgets" element={<PubWidgets />} />
      <Route path="widgets/:widgetId" element={<PubWidget />} />
      <Route path="moderation" element={<PubModeration />} />
      <Route path="payments" element={<PubPayment />} />
      <Route path="graphs" element={<PubGraph />} />
      <Route path="offers" element={<PubOfferDomains />} />
      <Route path="contacts" element={<Contacts />} />
      <Route path="*" element={<Navigate to="statistics" replace />} />
    </Routes>
  )
}

function App() {
  const [selectedTheme, setSelectedTheme] = useState<string>("dark")

  const { i18n } = useTranslation()

  const role = getCookie("role")

  const getRedirectLink = () => {
    if (role === "publisher") {
      return "/publisher/statistics"
    }
    if (role === "advertiser") {
      return "/advertiser/statistics"
    }
    return "/verify"
  }

  useEffect(() => {
    const themeFromCookie = getCookie("theme")

    if (!themeFromCookie) {
      setCookie("theme", "dark")
      setSelectedTheme("dark")
    } else {
      setSelectedTheme(themeFromCookie)
    }
  }, [])

  useEffect(() => {
    const userLang = i18n.language

    if (userLang === "es") {
      setCookie("lang", userLang)
      i18n.changeLanguage(userLang)
    } else {
      setCookie("lang", "en")
      i18n.changeLanguage("en")
    }
  }, [])

  return (
    <ConfigProvider
      theme={{
        algorithm:
          selectedTheme === "dark"
            ? theme.darkAlgorithm
            : theme.defaultAlgorithm,
        token: {
          colorLink: selectedTheme === "dark" ? "#00b96b" : "#1f7a1f",
          colorPrimary: selectedTheme === "dark" ? "#00b96b" : "#1f7a1f",
        },
      }}
      locale={getLocaleOrSetDefault()}
    >
      <Routes>
        <Route path="/verify" element={<UserVerify />} />
        <Route path="/" element={<Navigate replace to={getRedirectLink()} />} />

        <Route
          element={
            <UnAuthLayout theme={selectedTheme} setTheme={setSelectedTheme}>
              <Outlet />
            </UnAuthLayout>
          }
        >
          <Route path="/login" element={<Login />} />
          {/* <Route path="/register" element={<Register />} /> */}
        </Route>

        <Route
          element={
            <AuthLayout theme={selectedTheme} setTheme={setSelectedTheme}>
              <Outlet />
            </AuthLayout>
          }
        >
          <Route
            path="/advertiser/*"
            element={
              <ProtectedRoute requiredRole={Roles.Advertiser}>
                <AdvertiserRoutes />
              </ProtectedRoute>
            }
          />
          <Route
            path="/publisher/*"
            element={
              <ProtectedRoute requiredRole={Roles.Publisher}>
                <PublisherRoutes />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route path="*" element={<Navigate to={getRedirectLink()} replace />} />
      </Routes>

      <Toaster />
    </ConfigProvider>
  )
}

export default App
