import { ModerationCountQueries } from "@/definitions"

export const getQueriesModerationCount = (
  role: string | undefined,
): ModerationCountQueries => {
  if (role === "publisher") {
    return {
      role: "publisher",
      paramsString: "ms_id=0",
    }
  } else {
    return {
      role: "advertiser",
      paramsString: "ms_id=0",
    }
  }
}
