import { createApiLink } from "@/utils"
import { utilsSplitApi } from "@/redux/emptySplitApi"
import {
  ApiResponseError,
  ApiResponseOk,
  Country,
  DeviceType,
  ModerationFilter,
  PaymentModel,
  PaymentType,
} from "@/definitions"

export const apiWithTag = utilsSplitApi.enhanceEndpoints({
  addTagTypes: ["utils"],
})

export const utilsApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getPaymentModels: builder.query<PaymentModel[], void>({
      query: () => ({
        url: `${createApiLink(`/paymentModels/`)}`,
        method: "GET",
        keepUnusedDataFor: 86400,
      }),
      providesTags: () => [{ type: "utils" }],
    }),
    getPaymentTypes: builder.query<PaymentType[], void>({
      query: () => ({
        url: `${createApiLink(`/paymentTypes/`)}`,
        method: "GET",
        keepUnusedDataFor: 86400,
      }),
      providesTags: () => [{ type: "utils" }],
    }),
    getCountries: builder.query<Country[], void>({
      query: () => ({
        url: `${createApiLink(`/countries/`)}`,
        method: "GET",
        keepUnusedDataFor: 86400,
      }),
      providesTags: () => [{ type: "utils" }],
    }),
    getTimezones: builder.query<string[], void>({
      query: () => ({
        url: `${createApiLink(`/timeZones/`)}`,
        method: "GET",
        keepUnusedDataFor: 86400,
      }),
      providesTags: () => [{ type: "utils" }],
    }),
    getOffersDomainsGeo: builder.query<string[], void>({
      query: () => ({
        url: `${createApiLink(`/OffersDomainsGeo/`)}`,
        method: "GET",
        keepUnusedDataFor: 86400,
      }),
      providesTags: () => [{ type: "utils" }],
    }),
    updateLang: builder.mutation<ApiResponseOk | ApiResponseError, string>({
      query: (lang) => ({
        url: `${createApiLink(`/lang/`)}`,
        method: "POST",
        body: {
          lang,
        },
      }),
      invalidatesTags: () => [{ type: "utils" }],
    }),
    getModerationFilters: builder.query<ModerationFilter[], void>({
      query: () => ({
        url: `${createApiLink(`/ModerationFilters/`)}`,
        method: "GET",
        keepUnusedDataFor: 86400,
      }),
      providesTags: () => [{ type: "utils" }],
    }),
    getDevicesTypes: builder.query<DeviceType[], void>({
      query: () => ({
        url: `${createApiLink(`/DevicesTypes/`)}`,
        method: "GET",
        keepUnusedDataFor: 86400,
      }),
      providesTags: () => [{ type: "utils" }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetPaymentModelsQuery,
  useGetPaymentTypesQuery,
  useGetCountriesQuery,
  useGetTimezonesQuery,
  useGetOffersDomainsGeoQuery,
  useUpdateLangMutation,
  useGetModerationFiltersQuery,
  useGetDevicesTypesQuery,
} = utilsApi
