import { API_URL } from "@/constants"
import { getCookie } from "@/utils/cookies"
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const createEmptySplitApi = (reducerPath: string) => {
  return createApi({
    reducerPath: reducerPath,
    baseQuery: fetchBaseQuery({
      baseUrl: API_URL, // prepareHeaders is used to configure the header of every request and gives access to getState which we use to include the token from the store
      prepareHeaders: (headers) => {
        const token = getCookie("token")
        if (token) {
          // include token in req header
          headers.set("authorization", `Bearer ${token}`)
          return headers
        }
      },
    }),
    endpoints: () => ({}),
    // TODO refactor and provide global tagTypes
    refetchOnMountOrArgChange: true,
  })
}

export const userSplitApi = createEmptySplitApi("userApi")
export const publisherSplitApi = createEmptySplitApi("publisherApi")
export const advertiserSplitApi = createEmptySplitApi("advertiserApi")
export const advertiserCampaignSplitApi = createEmptySplitApi(
  "advertiserCampaignApi",
)
export const utilsSplitApi = createEmptySplitApi("utilsApi")

// initialize an empty api service that we'll inject endpoints into later as needed
// export const emptySplitApi = createApi({
//   baseQuery: fetchBaseQuery({
//     baseUrl: API_URL, // prepareHeaders is used to configure the header of every request and gives access to getState which we use to include the token from the store
//     prepareHeaders: (headers) => {
//       const token = getCookie("token")
//       if (token) {
//         // include token in req header
//         headers.set("authorization", `Bearer ${token}`)
//         return headers
//       }
//     },
//   }),
//   endpoints: () => ({}),
//   // TODO refactor and provide global tagTypes
//   refetchOnMountOrArgChange: true,
// })
