import { Dispatch, SetStateAction, useState } from "react"
import { Dropdown, Checkbox, DropdownProps, Button } from "antd"
import { FilterOutlined } from "@ant-design/icons"
import { textToUpperCase } from "@/utils/textToUpperCase"
import { TFunction } from "i18next"
import styles from "@/components/common/Std/Dropdowns/Dropdowns.module.css"

interface StdColumnsDropdownProps {
  t: TFunction<"translation", undefined>
  namespace: string
  options: string[]
  selectedOptions: string[]
  setSelectedOptions: Dispatch<SetStateAction<string[]>>
}

export const StdColumnsDropdown = ({
  t,
  namespace,
  options,
  selectedOptions,
  setSelectedOptions,
}: StdColumnsDropdownProps) => {
  const [open, setOpen] = useState<boolean>(false)

  const handleOpenChange: DropdownProps["onOpenChange"] = (nextOpen, info) => {
    if (info.source === "trigger" || nextOpen) {
      setOpen(nextOpen)
    }
  }

  const handleMenuItemClick = (value: string) => {
    const newSelectedItems: string[] = selectedOptions.includes(value)
      ? selectedOptions.filter((item) => item !== value)
      : [...selectedOptions, value]

    setSelectedOptions(newSelectedItems)
  }

  return (
    <Dropdown
      overlayClassName={styles.columnsDropdown}
      onOpenChange={handleOpenChange}
      open={open}
      menu={{
        items: options.map((option: string) => ({
          key: option,
          label: (
            <Checkbox
              checked={selectedOptions.includes(option)}
              onChange={() => handleMenuItemClick(option)}
            >
              {t(`${namespace}.${textToUpperCase(option)}`)}
            </Checkbox>
          ),
        })),
      }}
    >
      <Button type="default">
        <FilterOutlined />
      </Button>
    </Dropdown>
  )
}
