import { Dispatch, SetStateAction, useState } from "react"
import { TFunction } from "i18next"
import { UploadOutlined } from "@ant-design/icons"
import { Button, Upload } from "antd"
import type { GetProp, UploadFile, UploadProps } from "antd"
import toast from "react-hot-toast"

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0]

interface AdvCampaignOfferCreativeUploadImageProps {
  setImageUrl: Dispatch<SetStateAction<string>>
  setImageType: Dispatch<SetStateAction<string>>
  t: TFunction<"translation", undefined>
}

export const AdvCampaignOfferCreativeUploadImage = ({
  setImageUrl,
  setImageType,
  t,
}: AdvCampaignOfferCreativeUploadImageProps): JSX.Element => {
  const [loadingImage, setLoadingImage] = useState<boolean>(false)
  const [fileList, setFileList] = useState<UploadFile[]>([])

  const handleChange: UploadProps["onChange"] = (info) => {
    setFileList([info.file])

    if (info.file.status === "uploading") {
      setLoadingImage(true)
      return
    }
    if (info.file.status === "done") {
      setImageType(info?.file?.type?.replace("image/", "") || "")

      getBase64(info.file.originFileObj as FileType, (url) => {
        setLoadingImage(false)
        setImageUrl(url)
      })
    } else {
      setLoadingImage(false)
    }
  }

  return (
    <Upload
      onChange={handleChange}
      beforeUpload={beforeUpload}
      accept=".jpg, .jpeg, .png"
      fileList={fileList}
      showUploadList={false}
      listType="text"
      multiple={false}
      action={
        "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD/4QBmRXhpZgAATU0AKgAAAAgABAEaAAUAAAABAAAAPgEbAAUAAAABAAAARgEoAAMAAAABAAIA"
      }
    >
      <Button type="default" loading={loadingImage} htmlType="button">
        <UploadOutlined />
        {t("ADV.CAMPAIGNS.UPLOAD_BUTTON")}
      </Button>
    </Upload>
  )
}

const getBase64 = (img: FileType, callback: (url: string) => void) => {
  const reader = new FileReader()
  reader.addEventListener("load", () => callback(reader.result as string))
  reader.readAsDataURL(img)
}

const beforeUpload = (file: FileType) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png"

  if (!isJpgOrPng) {
    toast.error("You can only upload JPG/PNG file!")
  }

  return isJpgOrPng
}
