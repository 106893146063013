import { Table, Typography } from "antd"

const { Summary } = Table
const { Row, Cell } = Summary
const { Text } = Typography

interface AdvStatisticsCampaignTableSummaryProps {
  defaultColumns: string[]
  selectedColumns: string[]
  blindViews: string | number
  views: string | number
  clicks: string | number
  ctr: string | number
  vctr: string | number
  avarageCpc: string | number
  cost: string | number
}

export const AdvStatisticsCampaignTableSummary = ({
  defaultColumns,
  selectedColumns,
  blindViews,
  views,
  clicks,
  ctr,
  vctr,
  avarageCpc,
  cost,
}: AdvStatisticsCampaignTableSummaryProps): JSX.Element => {
  const getSummaryData = (column: string) => {
    switch (column) {
      case "blind_views":
        return blindViews
      case "views":
        return views
      case "clicks":
        return clicks
      case "ctr":
        return ctr
      case "vctr":
        return vctr
      case "avarage_cpc":
        return avarageCpc
      case "cost":
        return cost
      default:
        return ""
    }
  }

  // filter defaultColumns for save their position
  const filteredColumns = defaultColumns.filter((col) =>
    selectedColumns.includes(col),
  )
  return (
    <Row>
      <Cell index={0}>
        <Text strong={true}>SUM</Text>
      </Cell>
      {filteredColumns.map((column, index) => (
        <Cell index={index + 1} key={index}>
          <Text strong={true}>{getSummaryData(column)}</Text>
        </Cell>
      ))}
    </Row>
  )
}
