import { useEffect } from "react"

type ElementWithOffsetLeft = HTMLElement & { offsetLeft: number }

export const useDraggableScroll = (selector: string) => {
  useEffect(() => {
    const slider = document.querySelector(selector) as ElementWithOffsetLeft

    if (!slider) return

    let isDown = false
    let startX: number
    let scrollLeft: number

    const handleMouseDown = (e: MouseEvent) => {
      isDown = true
      startX = e.pageX - slider.offsetLeft
      scrollLeft = slider.scrollLeft
      slider.classList.add("activeGrabCursor")
    }

    const handleMouseMove = (e: MouseEvent) => {
      if (!isDown) return
      e.preventDefault()
      const x = e.pageX - slider.offsetLeft
      const walk = x - startX
      slider.scrollLeft = scrollLeft - walk
    }

    const handleMouseUp = () => {
      isDown = false
      slider.classList.remove("activeGrabCursor")
    }

    const handleMouseLeave = () => {
      isDown = false
      slider.classList.remove("activeGrabCursor")
    }

    slider.classList.add("grabCursor")

    slider.addEventListener("mousedown", handleMouseDown)
    slider.addEventListener("mousemove", handleMouseMove)
    slider.addEventListener("mouseup", handleMouseUp)
    slider.addEventListener("mouseleave", handleMouseLeave)

    // Clean up event listeners
    return () => {
      slider.removeEventListener("mousedown", handleMouseDown)
      slider.removeEventListener("mousemove", handleMouseMove)
      slider.removeEventListener("mouseup", handleMouseUp)
      slider.removeEventListener("mouseleave", handleMouseLeave)
    }
  }, [selector])
}
