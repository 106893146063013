import { AdvCampaignOfferEdit } from "@/components/advertiser/Campaigns/Offers/OfferEdit"
import { StdErrorMessage } from "@/components/common/Std/ErrorMessage"
import { StdSpinner } from "@/components/common/Std/Spinner"
import { useGetAdvCampaignOfferQuery } from "@/redux/slices/advertiserCampaignsApi"
import { Breadcrumb, Button, Divider, Flex, Typography } from "antd"
import { useTranslation } from "react-i18next"
import { NavLink, useParams } from "react-router-dom"
import { AdvCampaignOfferTexts } from "@/components/advertiser/Campaigns/Offers/Texts"
import { AdvCampaignOfferCreatives } from "@/components/advertiser/Campaigns/Offers/Creatives"
import { useState } from "react"

const { Title } = Typography

enum OffersAdditions {
  Text = "text",
  Creative = "creative",
}

export const AdvCampaignOffer = (): JSX.Element => {
  const { campaignId, offerId } = useParams()
  const { t } = useTranslation()

  const [addition, setAddition] = useState<OffersAdditions>()

  const { data, isLoading, isError } = useGetAdvCampaignOfferQuery({
    campaign_id: campaignId || "",
    offer_id: offerId || "",
  })

  const handleAddAddition = (value: OffersAdditions): void => {
    setAddition(value)
  }

  if (isLoading) return <StdSpinner />

  if (isError) return <StdErrorMessage>Error...</StdErrorMessage>

  return (
    <div>
      <Breadcrumb
        items={[
          {
            title: (
              <NavLink to={"/advertiser/campaigns"}>
                {t("ADV.CAMPAIGNS.CAMPAIGNS")}
              </NavLink>
            ),
          },
          {
            title: (
              <NavLink to={`/advertiser/campaigns/${campaignId}`}>
                {`${t("ADV.CAMPAIGNS.CAMPAIGN_ID")}: ${campaignId || ""}`}
              </NavLink>
            ),
          },
          {
            title: `${t("ADV.CAMPAIGNS.OFFER_ID")}: ${offerId || ""}`,
          },
        ]}
      />
      <Title level={4} style={{ paddingBottom: "5px" }}>
        {t("ADV.CAMPAIGNS.EDIT_OFFER")}
      </Title>
      <AdvCampaignOfferEdit offer={data} />
      <Divider />
      <Flex gap={15} style={{ maxWidth: "300px", paddingBottom: "24px" }}>
        <Button
          htmlType="button"
          style={{ width: "100%" }}
          onClick={() => handleAddAddition(OffersAdditions.Text)}
        >
          {t("ADV.CAMPAIGNS.ADD_TEXT")}
        </Button>
        <Button
          htmlType="button"
          style={{ width: "100%" }}
          onClick={() => handleAddAddition(OffersAdditions.Creative)}
        >
          {t("ADV.CAMPAIGNS.ADD_CREATIVE")}
        </Button>
      </Flex>
      {addition === OffersAdditions.Text && (
        <AdvCampaignOfferTexts offerId={offerId} campaignId={campaignId} />
      )}
      {addition === OffersAdditions.Creative && (
        <AdvCampaignOfferCreatives offerId={offerId} campaignId={campaignId} />
      )}
    </div>
  )
}
