import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from "@reduxjs/toolkit"
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import { authApi } from "@/redux/slices/authApi"
import { publisherApi } from "@/redux/slices/publisherApi"
import { advertiserApi } from "@/redux/slices/advertiserApi"
import { advertiserCampaignsApi } from "@/redux/slices/advertiserCampaignsApi"
import { userApi } from "@/redux/slices/userApi"
import { utilsApi } from "@/redux/slices/utilsApi"
import { authMiddleware } from "@/redux/middlewares/authModdleware"

export const store = configureStore({
  reducer: combineReducers({
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [publisherApi.reducerPath]: publisherApi.reducer,
    [advertiserApi.reducerPath]: advertiserApi.reducer,
    [advertiserCampaignsApi.reducerPath]: advertiserCampaignsApi.reducer,
    [utilsApi.reducerPath]: utilsApi.reducer,
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authMiddleware)
      .concat(authApi.middleware)
      .concat(userApi.middleware)
      .concat(publisherApi.middleware)
      .concat(advertiserApi.middleware)
      .concat(advertiserCampaignsApi.middleware)
      .concat(utilsApi.middleware),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
