import { API_URL } from "@/constants"
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
  endpoints: (builder) => ({
    signinUser: builder.mutation({
      query: (body: { login: string; password: string }) => {
        return {
          url: "/login",
          method: "post",
          body,
        }
      },
    }),
    signUpUser: builder.mutation({
      query: (body: {
        login: string
        password: string
        conf_password: string
        name: string
        phone?: string
        type: string
        timezone: string
        role?: string
      }) => {
        return {
          url: "/Registration",
          method: "post",
          body: body,
        }
      },
    }),
    sendMail: builder.mutation({
      query: (body: { login: string; lang: string; rc3_token: string }) => {
        return {
          url: "/RestorePassword",
          method: "post",
          body,
        }
      },
    }),
    resetPassword: builder.mutation({
      query: (body: {
        id: number
        rp: string
        new_password: string
        confirm_password: string
        rc3_token: string
      }) => {
        return {
          url: "/RestorePassword",
          method: "put",
          body,
        }
      },
    }),
  }),
})

export const {
  useSigninUserMutation,
  useSignUpUserMutation,
  useSendMailMutation,
  useResetPasswordMutation,
} = authApi
