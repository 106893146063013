import html2canvas from "html2canvas"

export const exportPngFile = (id: string) => {
  const itemNode = document.getElementById(id)
  if (itemNode) {
    html2canvas(itemNode).then((canvas) => {
      const url = canvas.toDataURL("image/png")
      const link = document.createElement("a")
      link.href = url
      link.download = `${id}-png.png`
      link.click()
    })
  }
}
