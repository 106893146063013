import { setCookie } from "@/utils/cookies"
import { CSSProperties, Dispatch, SetStateAction } from "react"
import { DarkModeSwitch } from "react-toggle-dark-mode"

interface StdThemeSwitcherProps {
  theme: string
  setTheme: Dispatch<SetStateAction<string>>
  style?: CSSProperties
}

export const StdThemeSwitcher = ({
  theme,
  setTheme,
  style,
}: StdThemeSwitcherProps): JSX.Element => {
  const toggleTheme = (checked: boolean): void => {
    setTheme(checked ? "dark" : "light")
    setCookie("theme", checked ? "dark" : "light")
  }

  return (
    <DarkModeSwitch
      style={style || {}}
      checked={theme === "dark" ? true : false}
      onChange={toggleTheme}
      size={25}
    />
  )
}
