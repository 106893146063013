import { Typography, Flex } from "antd"
import { useTranslation } from "react-i18next"
import { StdSpinner } from "@/components/common/Std/Spinner"
import { StdErrorMessage } from "@/components/common/Std/ErrorMessage"
import { AdvCampaignCreate } from "@/components/advertiser/Campaigns/Campaign/CampaignCreate"
import { AdvCampaignsTable } from "@/components/advertiser/Campaigns/CampaignsTable"
import { useGetAdvAllCampaignsQuery } from "@/redux/slices/advertiserCampaignsApi"

const { Title } = Typography

export const AdvCampaigns = (): JSX.Element => {
  const { t } = useTranslation()

  const {
    data: campaignsData,
    isLoading: campaingsIsLoading,
    isError: campaingsIsError,
  } = useGetAdvAllCampaignsQuery()

  if (campaingsIsLoading) return <StdSpinner />

  if (campaingsIsError) return <StdErrorMessage>Error...</StdErrorMessage>

  return (
    <Flex vertical>
      <Title level={3} style={{ paddingBottom: "5px" }}>
        {t("ADV.CAMPAIGNS.CAMPAIGNS")}
      </Title>

      <AdvCampaignCreate />

      <AdvCampaignsTable t={t} campaignsData={campaignsData} />
    </Flex>
  )
}
