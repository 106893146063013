import { Dispatch, SetStateAction } from "react"
import { Checkbox, DatePicker, Flex, Select } from "antd"
import { TFunction } from "i18next"
import { DefaultOptionType } from "antd/es/select"
import { StdLabel } from "@/components/common/Std/Label"
import dayjs from "dayjs"

interface PubStatisticsFiltersProps {
  t: TFunction<"translation", undefined>
  setStartDate: Dispatch<SetStateAction<string>>
  setEndDate: Dispatch<SetStateAction<string>>
  onChangeWebsiteId: (value: string) => void
  onChangeWidgetId: (value: string) => void
  websitesData: DefaultOptionType[] | undefined
  widgetsData: DefaultOptionType[] | undefined
  selectedColumns: string[]
  setSelectedColumns: Dispatch<SetStateAction<string[]>>
  setDateColumns: Dispatch<SetStateAction<boolean>>
}

export const PubStatisticsFilters = ({
  t,
  setStartDate,
  setEndDate,
  onChangeWebsiteId,
  onChangeWidgetId,
  selectedColumns,
  setSelectedColumns,
  websitesData,
  widgetsData,
  setDateColumns,
}: PubStatisticsFiltersProps): JSX.Element => {
  const ALL_OPTION: DefaultOptionType = {
    label: t("SELECTS.ALL"),
    value: "all",
  }

  return (
    <Flex gap="large" vertical style={{ maxWidth: "300px", width: "100%" }}>
      <Flex gap="large" wrap="wrap" style={{ width: "100%" }}>
        <DatePicker
          style={{ flex: "1" }}
          onChange={(_: any, dateString: string): void => {
            setStartDate(dateString)
          }}
          placeholder={t("SELECTS.START_DATE_PLACEHOLDER")}
          defaultValue={dayjs()}
          // locale={locale}
        />
        <DatePicker
          style={{ flex: "1" }}
          onChange={(_: any, dateString: string): void => {
            setEndDate(dateString)
          }}
          placeholder={t("SELECTS.END_DATE_PLACEHOLDER")}
          defaultValue={dayjs()}
        />
      </Flex>
      <StdLabel label={t("SELECTS.WEBSITE_PLACEHOLDER")}>
        <Select
          placeholder={t("SELECTS.WEBSITE_PLACEHOLDER")}
          onChange={onChangeWebsiteId}
          defaultValue={"all"}
          showSearch
          options={websitesData ? [ALL_OPTION, ...websitesData] : [ALL_OPTION]}
        />
      </StdLabel>
      <StdLabel label={t("SELECTS.WIDGET_PLACEHOLDER")}>
        <Select
          placeholder={t("SELECTS.WIDGET_PLACEHOLDER")}
          onChange={onChangeWidgetId}
          defaultValue={"all"}
          showSearch
          options={widgetsData ? [ALL_OPTION, ...widgetsData] : [ALL_OPTION]}
        />
      </StdLabel>
      <div>
        <Checkbox
          checked={selectedColumns.includes("date")}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedColumns((prevState) => [...prevState, "date"])
              setDateColumns(true)
            } else {
              setSelectedColumns((prevState) =>
                prevState.filter((item) => item !== "date"),
              )
              setDateColumns(false)
            }
          }}
        >
          {t("PUB.STATISTICS.STATS_BY_DATE")}
        </Checkbox>
      </div>
    </Flex>
  )
}
