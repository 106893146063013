import { Button, Dropdown } from "antd"
import { MenuOutlined } from "@ant-design/icons"
import { ItemType } from "antd/es/menu/hooks/useItems"

interface StdDownloadDropdownProps {
  items: ItemType[]
}

export const StdDownloadDropdown = ({
  items,
}: StdDownloadDropdownProps): JSX.Element => {
  return (
    <Dropdown
      menu={{
        items: items,
      }}
    >
      <Button type="default">
        <MenuOutlined />
      </Button>
    </Dropdown>
  )
}
