import { Typography, Flex, DatePicker, Select, Checkbox } from "antd"
import { useEffect, useState } from "react"
import { useLazyGetAdvGraphStatisticsQuery } from "@/redux/slices/advertiserApi"
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Area,
  AreaChart,
} from "recharts"
import { useTranslation } from "react-i18next"
import { StdSpinner } from "@/components/common/Std/Spinner"
import { StdErrorMessage } from "@/components/common/Std/ErrorMessage"
import { StdLabel } from "@/components/common/Std/Label"
import { useGetAdvAllCampaignsQuery } from "@/redux/slices/advertiserCampaignsApi"
import { getValueByBoolean } from "@/utils/getValueByBoolean"
import { AdvertiserCampaigns, AdvertiserGraph } from "@/definitions"
import dayjs from "dayjs"

const { Title } = Typography

const DEFAULT_MAX_Y = 12

export const AdvGraph = (): JSX.Element => {
  const { t } = useTranslation()

  const [campaignId, setCampaignId] = useState<string>("")
  const [startDate, setStartDate] = useState<string>(
    dayjs().subtract(7, "day").format("YYYY-MM-DD"),
  )
  const [endDate, setEndDate] = useState<string>(dayjs().format("YYYY-MM-DD"))
  const [isViews, setIsViews] = useState<boolean>(true)
  const [isClicks, setIsClicks] = useState<boolean>(false)
  const [isCosts, setIsCosts] = useState<boolean>(false)
  const [chartData, setChartData] = useState<AdvertiserGraph[]>([])

  const {
    data: campaignsData,
    isLoading: campaignsIsLoading,
    isError: campaignsIsError,
  } = useGetAdvAllCampaignsQuery()

  const [fetchData, { data, isLoading, isError }] =
    useLazyGetAdvGraphStatisticsQuery()

  useEffect(() => {
    if (campaignId) {
      fetchData(
        `from=${startDate}&to=${endDate}&views=${getValueByBoolean(
          isViews,
        )}&clicks=${getValueByBoolean(isClicks)}&cost=${getValueByBoolean(
          isCosts,
        )}&campaign_id=${campaignId}`,
      )
    }
  }, [startDate, endDate, isViews, isClicks, isCosts, campaignId])

  useEffect(() => {
    if (!!data && !!data.length) {
      // Formatted data with toFixed()
      const formattedData = data?.map((item: AdvertiserGraph) => {
        const newItem: AdvertiserGraph = { date: item.date }
        if (item?.clicks !== undefined)
          newItem.clicks = item?.clicks && Number(item?.clicks).toFixed(0)
        if (item?.views !== undefined)
          newItem.views = item?.views && Number(item?.views).toFixed(0)
        if (item?.cost !== undefined)
          newItem.cost =
            (typeof item?.cost === "number" || "string") &&
            Number(item?.cost).toFixed(4)
        return newItem
      })
      setChartData(formattedData || ([] as AdvertiserGraph[]))
    }
  }, [data])

  const defaultCampaign = campaignsData?.reduce((maxObj, currentObj) => {
    return parseFloat(currentObj.campaign_id) > parseFloat(maxObj.campaign_id)
      ? currentObj
      : maxObj
  }, campaignsData[0])

  useEffect(() => {
    setCampaignId(defaultCampaign?.campaign_id || "")
  }, [campaignsData])

  const onChangeStartDate = (_: any, dateString: string): void => {
    setStartDate(dateString)
  }

  const onChangeEndDate = (_: any, dateString: string): void => {
    setEndDate(dateString)
  }

  const onChangeCampaign = (value: string): void => {
    const campaignId = value.split(" - ")[0]

    setCampaignId(campaignId)
  }

  if (campaignsIsLoading || isLoading) {
    return <StdSpinner />
  }

  if (campaignsIsError || isError)
    return <StdErrorMessage>Error...</StdErrorMessage>

  const chartId = "adv-graph"

  return (
    <Flex vertical gap="large">
      <Title level={3}>{t("ADV.GRAPH.GRAPH")}</Title>
      <StdLabel label={t("SELECTS.CAMPAIGN_PLACEHOLDER")}>
        <Select
          placeholder={t("SELECTS.CAMPAIGN_PLACEHOLDER")}
          style={{ maxWidth: "300px" }}
          showSearch
          loading={campaignsIsLoading}
          defaultValue={
            defaultCampaign
              ? `${defaultCampaign?.campaign_id} ${defaultCampaign?.name}`
              : null
          }
          onChange={onChangeCampaign}
          options={campaignsData?.map((campaign: AdvertiserCampaigns) => ({
            label: `${campaign.campaign_id} ${campaign.name}`,
            value: `${campaign.campaign_id} - ${campaign.name}`,
          }))}
        />
      </StdLabel>
      <div style={{ maxWidth: "300px", display: "flex", gap: "20px" }}>
        <DatePicker
          onChange={onChangeStartDate}
          placeholder={t("SELECTS.START_DATE_PLACEHOLDER")}
          defaultValue={dayjs().subtract(7, "day")}
          disabled={!campaignId}
        />
        <DatePicker
          onChange={onChangeEndDate}
          placeholder={t("SELECTS.END_DATE_PLACEHOLDER")}
          defaultValue={dayjs()}
          disabled={!campaignId}
        />
      </div>

      <Flex gap="small" justify="space-between" style={{ maxWidth: "300px" }}>
        <Checkbox
          disabled={!campaignId}
          defaultChecked
          onClick={() => {
            setIsViews((prevState) => !prevState)
          }}
        >
          {t("ADV.GRAPH.VIEWS")}
        </Checkbox>
        <Checkbox
          disabled={!campaignId}
          onClick={() => {
            setIsClicks((prevState) => !prevState)
          }}
        >
          {t("ADV.GRAPH.CLICKS")}
        </Checkbox>
        <Checkbox
          disabled={!campaignId}
          onClick={() => {
            setIsCosts((prevState) => !prevState)
          }}
        >
          {t("ADV.GRAPH.COST")}
        </Checkbox>
      </Flex>

      <div style={{ width: "100%", height: "300px" }} id={chartId}>
        <ResponsiveContainer width="100%" height={300}>
          <AreaChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis
              type="number"
              domain={[0, getMaxValueY(chartData) || "auto"]}
            />
            <Tooltip labelStyle={{ color: "rgba(0, 0, 0, 0.88)" }} />
            <Legend />
            {isViews && (
              <Area
                type="monotone"
                name={t("ADV.GRAPH.VIEWS") as unknown as string}
                dataKey="views"
                stroke="#00b96b"
                fill="#00b96b"
                strokeWidth={4}
                fillOpacity={0.4}
                dot={{ stroke: "#00b96b", fill: "#00b96b" }}
              />
            )}
            {isClicks && (
              <Area
                type="monotone"
                name={t("ADV.GRAPH.CLICKS") as unknown as string}
                dataKey="clicks"
                stroke="#1890ff"
                fill="#1890ff"
                strokeWidth={4}
                fillOpacity={0.4}
                dot={{ stroke: "#1890ff", fill: "#1890ff" }}
              />
            )}
            {isCosts && (
              <Area
                type="monotone"
                name={t("ADV.GRAPH.COST") as unknown as string}
                dataKey="cost"
                stroke="#ff4d4f"
                fill="#ff4d4f"
                strokeWidth={4}
                fillOpacity={0.4}
                dot={{ stroke: "#ff4d4f", fill: "#ff4d4f" }}
              />
            )}
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </Flex>
  )
}

const getMaxValueY = (data: AdvertiserGraph[]): number => {
  if (!data || !data.length) return DEFAULT_MAX_Y

  const array: number[] = []

  array.push(
    Math.max(
      ...data?.map((item: AdvertiserGraph) => Number(item?.clicks) || 0),
    ),
  )
  array.push(
    Math.max(...data?.map((item: AdvertiserGraph) => Number(item?.cost) || 0)),
  )
  array.push(
    Math.max(...data?.map((item: AdvertiserGraph) => Number(item?.views) || 0)),
  )

  return Math.ceil(Math.max(...array))
}
