import { Flex, Typography, Table } from "antd"
import { useGetAdvPaymentsQuery } from "@/redux/slices/advertiserApi"
import { useTranslation } from "react-i18next"
import { StdSpinner } from "@/components/common/Std/Spinner"
import { StdErrorMessage } from "@/components/common/Std/ErrorMessage"

const { Title } = Typography

export const AdvPayment = (): JSX.Element => {
  const { t } = useTranslation()
  const { data, isLoading, isError } = useGetAdvPaymentsQuery()

  if (isLoading) return <StdSpinner />

  if (isError) return <StdErrorMessage>Error...</StdErrorMessage>

  return (
    <Flex vertical gap="large">
      <Title level={3}>{t("ADV.PAYMENTS.PAYMENTS")}</Title>
      <Table
        scroll={{ x: "max-content" }}
        dataSource={data}
        loading={isLoading}
        showSorterTooltip={false}
        sortDirections={["ascend", "descend", "ascend"]}
        rowKey="id"
        columns={[
          {
            title: "№",
            dataIndex: "id",
            key: "id",
            width: 100,
            sorter: (a, b) => Number(a.id) - Number(b.id),
            defaultSortOrder: "descend",
          },
          {
            title: t("ADV.PAYMENTS.DATE_OF_PAYMENT"),
            dataIndex: "start_date",
            key: "start_date",
            width: 200,
            sorter: (a, b) =>
              new Date(a.start_date).getTime() -
              new Date(b.start_date).getTime(),
          },
          {
            title: t("ADV.PAYMENTS.PAYMENT_AMOUNT"),
            dataIndex: "payment_amount",
            key: "payment_amount",
            width: 200,
            sorter: (a, b) =>
              Number(a.payment_amount) - Number(b.payment_amount),
          },
          {
            title: t("ADV.PAYMENTS.COMMENT"),
            dataIndex: "comment",
            key: "comment",
            width: 300,
            render: (value) => <span>{!value ? "-" : value}</span>,
            sorter: (a, b) => a.comment.localeCompare(b.comment),
          },
        ]}
        pagination={{ position: ["bottomCenter"] }}
      />
    </Flex>
  )
}
