import { Typography, Flex } from "antd"
import {
  useGetPubUserInfoQuery,
  useGetPubNotificationsQuery,
} from "@/redux/slices/publisherApi"
import { useTranslation } from "react-i18next"
import { StdSpinner } from "@/components/common/Std/Spinner"
import { StdErrorMessage } from "@/components/common/Std/ErrorMessage"
import { PubAccountInformation } from "@/components/publisher/Account/AccountInformation"
import { PubAccountNotifications } from "@/components/publisher/Account/AccountNotifications"
import { getCookie } from "@/utils/cookies"
// import { useGetTimezonesQuery } from "@/redux/slices/utilsApi"

const { Title } = Typography

export const PubAccount = (): JSX.Element => {
  const { t } = useTranslation()

  const lang = getCookie("lang")

  const {
    data: userData,
    isLoading: userDataIsloading,
    isError: userDataIsError,
  } = useGetPubUserInfoQuery()

  const {
    data: notificationsData,
    isLoading: notificationsIsLoading,
    isError: notificationsIsError,
  } = useGetPubNotificationsQuery(`lang=${lang || "en"}`)

  // const { data: timezonesData, isLoading: timezonesIsLoading } =
  //   useGetTimezonesQuery({})

  // const {
  //   data: pubCampaignsData,
  //   isLoading: pubCampaignsIsLoading,
  //   isError: pubCampaignsIsError,
  // } = useGetPubCampaignsQuery()

  if (userDataIsloading || notificationsIsLoading) return <StdSpinner />

  if (userDataIsError || notificationsIsError)
    return <StdErrorMessage>Error...</StdErrorMessage>

  return (
    <Flex vertical gap="large">
      <Title level={3}>{t("PUB.ACCOUNT.ACCOUNT")}</Title>

      <PubAccountInformation
        userData={userData}
        // timezonesData={timezonesData}
        t={t}
      />

      <PubAccountNotifications t={t} notificationsData={notificationsData} />

      {/* <Title level={5}>{t("PUB.ACCOUNT.CAMPAIGNS")}</Title> */}

      {/* <Table
        scroll={{ x: "max-content" }}
        dataSource={pubCampaignsData}
        rowKey="campaign_id"
        columns={[
          {
            title: t("PUB.ACCOUNT.ID"),
            dataIndex: "campaign_id",
            key: "campaign_id",
            width: "5%",
          },
          {
            title: t("PUB.ACCOUNT.NAME"),
            dataIndex: "name",
            key: "name",
          },
        ]}
        pagination={{ position: ["bottomCenter"] }}
      /> */}
    </Flex>
  )
}
