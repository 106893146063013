import { Divider, Breadcrumb, Typography } from "antd"
import { NavLink, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { StdSpinner } from "@/components/common/Std/Spinner"
import { StdErrorMessage } from "@/components/common/Std/ErrorMessage"
import {
  useGetAdvCampaignQuery,
  // useDeleteAdvCampaignMutation,
  // useUpdateAdvCampaginAllOffersStatesMutation,
} from "@/redux/slices/advertiserCampaignsApi"
import { AdvCampaignEdit } from "@/components/advertiser/Campaigns/Campaign/CampaignEdit"
import { AdvCampaignOffers } from "@/components/advertiser/Campaigns/Offers"

const { Title } = Typography

export const AdvCampaign = (): JSX.Element => {
  const { campaignId } = useParams()
  const { t } = useTranslation()

  const { data, isLoading, isError } = useGetAdvCampaignQuery(campaignId || "")

  // const [deleteCamp, deleteResult] = useDeleteAdvCampaignMutation()

  // const [toggleOffersInCamp, toggleOffersResult] =
  //   useUpdateAdvCampaginAllOffersStatesMutation()

  if (isLoading) return <StdSpinner />

  if (isError) return <StdErrorMessage>Error...</StdErrorMessage>

  return (
    <div>
      <Breadcrumb
        items={[
          {
            title: (
              <NavLink to={"/advertiser/campaigns"}>
                {t("ADV.CAMPAIGNS.CAMPAIGNS")}
              </NavLink>
            ),
          },
          {
            title: `${t("ADV.CAMPAIGNS.CAMPAIGN_ID")}: ${campaignId || ""}`,
          },
        ]}
      />
      <Title level={4} style={{ paddingBottom: "5px" }}>
        {t("ADV.CAMPAIGNS.EDIT_CAMPAIGN")}
      </Title>
      <AdvCampaignEdit campaignId={campaignId} campaign={data} />
      <Divider />
      <AdvCampaignOffers campaignId={campaignId} />
    </div>
  )
}
