import { COOKIES_DOMAIN } from "@/constants"
import Cookies from "js-cookie"

export const setCookie = (name: string, value: string): void => {
  Cookies.set(name, value, {
    domain: COOKIES_DOMAIN,
    path: "/",
  })
}

export const removeCookie = (name: string): void => {
  Cookies.remove(name, {
    domain: COOKIES_DOMAIN,
    path: "/",
  })
}

export const getCookie = (name: string): string | undefined => {
  return Cookies.get(name)
}
