import { ChangeEvent, useEffect, useState } from "react"
import { PublisherInfo } from "@/definitions"
import { useUpdatePubUserInfoMutation } from "@/redux/slices/publisherApi"
import { Button, Flex, Input, Select, Typography } from "antd"
import { TFunction } from "i18next"

const { Text } = Typography

interface PubAccountInformationProps {
  t: TFunction<"translation", undefined>
  userData: PublisherInfo | undefined
}

export const PubAccountInformation = ({
  userData,
  t,
}: PubAccountInformationProps): JSX.Element => {
  const [name, setName] = useState<string>("")
  const [phone, setPhone] = useState<string>("")
  const [timezone, setTimezone] = useState<string>("")
  const [website, setWebsite] = useState<string>("")
  const [companyName, setCompanyName] = useState<string>("")

  const [updatePubInfo, { isLoading }] = useUpdatePubUserInfoMutation()

  useEffect(() => {
    if (userData) {
      setName(userData?.name || "")
      setPhone(userData?.phone || "")
      setWebsite(userData?.site_name || "")
      setCompanyName(userData?.company_name || "")
      setTimezone(userData?.time_zone || "")
    }
  }, [userData])

  const onChangePhone = (e: ChangeEvent<HTMLInputElement>): void => {
    setPhone(e.target.value)
  }

  const onChangeName = (e: ChangeEvent<HTMLInputElement>): void => {
    setName(e.target.value)
  }

  const onChangeWebsite = (e: ChangeEvent<HTMLInputElement>): void => {
    setWebsite(e.target.value)
  }

  const onChangeCompanyName = (e: ChangeEvent<HTMLInputElement>): void => {
    setCompanyName(e.target.value)
  }

  const handleUpdatePubInfo = (
    name: string,
    phone: string,
    website: string,
    companyName: string,
    timezone: string,
  ) => {
    updatePubInfo({ name, phone, website, companyName, timezone })
  }

  return (
    <Flex vertical gap="large" style={{ maxWidth: "388px" }}>
      <Text type="success">
        {t("PUB.ACCOUNT.LOGIN")}: {userData?.login}
      </Text>
      <Flex gap="large">
        <Input
          placeholder={t("INPUTS.NAME_PLACEHOLDER")}
          value={name}
          onChange={onChangeName}
        />
        <Input
          placeholder={t("INPUTS.PHONE_PLACEHOLDER")}
          value={phone}
          onChange={onChangePhone}
        />
      </Flex>
      <Flex gap="large">
        <Input
          placeholder={t("INPUTS.WEBSITE_PLACEHOLDER")}
          value={website}
          onChange={onChangeWebsite}
        />
        <Input
          placeholder={t("INPUTS.COMPANY_NAME_PLACEHOLDER")}
          value={companyName}
          onChange={onChangeCompanyName}
        />
      </Flex>
      <Select style={{ width: "calc(50% - 12px)" }} value={timezone} disabled />
      <Button
        loading={isLoading}
        onClick={() =>
          handleUpdatePubInfo(name, phone, website, companyName, timezone)
        }
        type="primary"
      >
        {t("PUB.ACCOUNT.SAVE_BUTTON")}
      </Button>
    </Flex>
  )
}
