import { AdvCampaignOfferCreate } from "@/components/advertiser/Campaigns/Offers/OfferCreate"
import {
  useDeleteAdvCampaignOfferMutation,
  useGetAdvCampaignAllOffersQuery,
} from "@/redux/slices/advertiserCampaignsApi"
import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { Button, Divider, List, Modal, Switch, Typography } from "antd"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"

const { Title } = Typography
const { Item } = List

interface AdvCampaignOffersProps {
  campaignId: string | undefined
}

export const AdvCampaignOffers = ({
  campaignId,
}: AdvCampaignOffersProps): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false)
  const [offerId, setOfferId] = useState<string>("")

  const { data, isLoading } = useGetAdvCampaignAllOffersQuery(campaignId || "")
  const [deleteOffer] = useDeleteAdvCampaignOfferMutation()

  const handleDeleteOffer = (id: string): void => {
    deleteOffer({
      campaign_id: campaignId || "",
      offer_id: offerId,
    })

    setDeleteModalVisible(false)
  }

  const handleOpenDeleteModal = (id: string): void => {
    setDeleteModalVisible(true)
    setOfferId(id)
  }

  return (
    <div>
      <Title level={4} style={{ paddingBottom: "5px" }}>
        {t("ADV.CAMPAIGNS.ADD_NEW_OFFER")}
      </Title>
      <AdvCampaignOfferCreate campaignId={campaignId} />
      <Divider />
      <List
        loading={isLoading}
        header={<div>{t("ADV.CAMPAIGNS.OFFERS")}</div>}
        bordered
        dataSource={!!data && data?.length > 0 ? data : []}
        renderItem={(item) => (
          <Item
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <Link to={`/advertiser/campaigns/${campaignId}/offers/${item.id}`}>
              {item.name}
            </Link>
            <div
              className="actions"
              style={{
                display: "flex",
                gap: "10px",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <Switch
                checkedChildren={t("BUTTONS.ON")}
                unCheckedChildren={t("BUTTONS.OFF")}
                defaultChecked={item.state === "ON" ? true : false}
                onChange={(checked) => console.log(checked)}
              />
              <Button
                type="primary"
                style={{ padding: "4px 10px" }}
                onClick={() =>
                  navigate(
                    `/advertiser/campaigns/${campaignId}/offers/${item.id}`,
                  )
                }
              >
                <EditOutlined />
              </Button>
              <Button
                danger
                style={{ padding: "4px 10px" }}
                type="primary"
                onClick={() => handleOpenDeleteModal(item.id)}
              >
                <DeleteOutlined />
              </Button>
            </div>
          </Item>
        )}
      />
      <Modal
        title={t("ADV.CAMPAIGNS.DELETE_CONFIRMATION")}
        open={deleteModalVisible}
        onOk={() => handleDeleteOffer(offerId)}
        okText={t("BUTTONS.YES")}
        centered
        width={300}
        onCancel={() => setDeleteModalVisible(false)}
        okButtonProps={{ danger: true }}
      />
    </div>
  )
}
