import { useState } from "react"
import { Divider, Typography, List, Switch, Button, Modal } from "antd"
import { useTranslation } from "react-i18next"
import { AdvCampaignOfferTextCreate } from "@/components/advertiser/Campaigns/Offers/Texts/TextCreate"
import { useGetAdvCampaignOfferAllTextsQuery } from "@/redux/slices/advertiserCampaignsApi"
import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { Link, useNavigate } from "react-router-dom"
import { textToUpperCase } from "@/utils/textToUpperCase"

const { Title } = Typography
const { Item } = List

interface AdvCampaignOfferTextsProps {
  offerId: string | undefined
  campaignId: string | undefined
}

export const AdvCampaignOfferTexts = ({
  offerId,
  campaignId,
}: AdvCampaignOfferTextsProps): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false)

  const { data, isLoading } = useGetAdvCampaignOfferAllTextsQuery(offerId || "")

  return (
    <div>
      <Title level={4} style={{ paddingBottom: "5px" }}>
        {t("ADV.CAMPAIGNS.ADD_NEW_TEXT")}
      </Title>
      <AdvCampaignOfferTextCreate offerId={offerId} />
      <Divider />

      <List
        loading={isLoading}
        header={<div>{t("ADV.CAMPAIGNS.TEXTS")}</div>}
        bordered
        dataSource={!!data && data?.length > 0 ? data : []}
        renderItem={(item) => (
          <Item
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <Link
              to={`/advertiser/campaigns/${campaignId}/offers/${offerId}/texts/${item.id}`}
            >
              {item.id}
            </Link>
            <div
              className="actions"
              style={{
                display: "flex",
                gap: "10px",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <Switch
                checkedChildren={t("BUTTONS.ON")}
                unCheckedChildren={t("BUTTONS.OFF")}
                defaultChecked={
                  textToUpperCase(item.state) === "ON" ? true : false
                }
                onChange={(checked) => console.log(checked)}
              />
              <Button
                onClick={() =>
                  navigate(
                    `/advertiser/campaigns/${campaignId}/offers/${offerId}/texts/${item.id}`,
                  )
                }
                type="primary"
                style={{ padding: "4px 10px" }}
              >
                <EditOutlined />
              </Button>

              <Button
                onClick={() => setDeleteModalVisible(true)}
                danger
                type="primary"
                style={{ padding: "4px 10px" }}
              >
                <DeleteOutlined />
              </Button>
            </div>
          </Item>
        )}
      />
      <Modal
        title={t("ADV.CAMPAIGNS.DELETE_CONFIRMATION")}
        open={deleteModalVisible}
        onOk={() => setDeleteModalVisible(false)}
        okText={t("BUTTONS.YES")}
        centered
        width={300}
        onCancel={() => setDeleteModalVisible(false)}
        okButtonProps={{ danger: true }}
      />
    </div>
  )
}
