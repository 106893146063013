import { useEffect, useState } from "react"
import { Button, Checkbox, Flex, Typography } from "antd"
import { TFunction } from "i18next"
import {
  AdvertiserNotification,
  AdvertiserNotificationUpdate,
} from "@/definitions"
import { useUpdateAdvNotificationsMutation } from "@/redux/slices/advertiserApi"

const { Title } = Typography

interface AdvAccountNotificationsProps {
  t: TFunction<"translation", undefined>
  notificationsData: AdvertiserNotification[] | undefined
}

export const AdvAccountNotifications = ({
  notificationsData,
  t,
}: AdvAccountNotificationsProps): JSX.Element => {
  const [notifications, setNotifications] = useState<AdvertiserNotification[]>(
    [],
  )

  const [updateNotifications, { isLoading }] =
    useUpdateAdvNotificationsMutation()

  useEffect(() => {
    if (notificationsData) {
      setNotifications(notificationsData)
    }
  }, [notificationsData])

  const onChangeNotificationState = (checked: boolean, id: string): void => {
    setNotifications((prevState) => {
      const newNotificationsData = prevState.map((item) => {
        if (item.id === id) {
          const updatedItem = {
            ...item,
            state: checked ? "on" : "off",
          }

          return updatedItem
        }

        return item
      })

      return newNotificationsData
    })
  }

  const handleUpdateNotifications = (data: AdvertiserNotification[]) => {
    const newData: AdvertiserNotificationUpdate[] = data.map((item) => {
      const newItem = {
        id: item.id,
        state: item.state,
      }

      return newItem
    })

    updateNotifications(newData)
  }

  return (
    <Flex vertical gap="large" style={{ maxWidth: "300px" }}>
      <Title level={5}>{t("ADV.ACCOUNT.NOTIFICATIONS")}</Title>
      <Flex vertical gap={10}>
        {notifications?.map((notification) => (
          <Checkbox
            onChange={(e) =>
              onChangeNotificationState(e.target.checked, notification.id)
            }
            key={notification?.id}
            checked={notification?.state === "on"}
          >
            {notification?.name}
          </Checkbox>
        ))}
      </Flex>
      <Button
        loading={isLoading}
        style={{ width: "100%" }}
        onClick={() => handleUpdateNotifications(notifications)}
        type="primary"
      >
        {t("ADV.ACCOUNT.SAVE_BUTTON")}
      </Button>
    </Flex>
  )
}
