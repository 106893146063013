import { createApiLink, validateError } from "@/utils"
import { advertiserCampaignSplitApi } from "@/redux/emptySplitApi"
import { userApi } from "@/redux/slices/userApi"
import { getQueriesModerationCount } from "@/utils/getQueriesModerationCount"
import {
  ApiResponseError,
  ApiResponseOk,
  AdvertiserCampaign,
  AdvertiserCampaigns,
  AdvertiserCampaignCreate,
  AdvertiserCampaignUpdate,
  AdvertiserCampaignCopy,
  AdvertiserCampaignOffer,
  AdvertiserCampaignOfferIds,
  AdvertiserCampaignOfferCreate,
  AdvertiserCampaignOfferUpdate,
  AdvertiserCampaignAllOffersStatesUpdate,
  AdvertiserCampaignOfferPadStateUpdate,
  AdvertiserCampaignOfferText,
  AdvertiserCampaignOfferTextIds,
  AdvertiserCampaignOfferTextCreate,
  AdvertiserCampaignOfferTextUpdate,
  AdvertiserCampaignOfferCreative,
  AdvertiserCampaignOfferCreativeIds,
  AdvertiserCampaignOfferCreativeCreate,
  AdvertiserCampaignOfferCreativeUpdate,
  AdvertiserCampaignOfferCreativeType,
  AdvertiserCampaignPad,
  AdvertiserCampaignOfferTextAndCreativeStateUpdate,
  AdvertiserWidget,
} from "@/definitions"

export const apiWithTag = advertiserCampaignSplitApi.enhanceEndpoints({
  addTagTypes: ["campaigns", "offers", "texts", "teasers"],
})

export const advertiserCampaignsApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    // Campaign
    getAdvAllCampaigns: builder.query<AdvertiserCampaigns[], void>({
      query: () => ({
        url: `${createApiLink(`/advertiser/campaigns/`)}`,
        method: "GET",
      }),
      providesTags: () => [{ type: "campaigns" }],
    }),
    getAdvCampaign: builder.query<AdvertiserCampaign, string>({
      query: (campaign_id) => ({
        url: `${createApiLink(`/advertiser/Campaign/${campaign_id}/`)}`,
        method: "GET",
      }),
      providesTags: () => [{ type: "campaigns" }],
    }),
    createAdvCampaign: builder.mutation<
      ApiResponseOk | ApiResponseError,
      AdvertiserCampaignCreate
    >({
      query: ({
        name,
        country,
        payment_model,
        timezone,
        price,
        balance_limit,
        daily_limit,
        active_period_days,
        from,
        to,
      }) => ({
        url: `${createApiLink(`/advertiser/Campaign`)}`,
        method: "POST",
        body: {
          name,
          country,
          payment_model,
          timezone,
          price,
          balance_limit,
          daily_limit,
          active_period_days,
          from,
          to,
        },
        validateStatus: (response, result) => validateError(response, result),
      }),
      invalidatesTags: () => [{ type: "campaigns" }],
    }),
    updateAdvCampaign: builder.mutation<
      ApiResponseOk | ApiResponseError,
      AdvertiserCampaignUpdate
    >({
      query: ({ campaign_id, name, state, cpc_limit }) => ({
        url: `${createApiLink(`/advertiser/Campaign/${campaign_id}`)}`,
        method: "PUT",
        body: {
          name,
          state,
          cpc_limit,
        },
        validateStatus: (response, result) => validateError(response, result),
      }),
      invalidatesTags: () => [{ type: "campaigns" }],
    }),
    copyAdvCampaign: builder.mutation<
      ApiResponseOk | ApiResponseError,
      AdvertiserCampaignCopy
    >({
      query: ({ campaign_id, name }) => ({
        url: `${createApiLink(
          `/advertiser/Campaign/${campaign_id}/CopyCampaign/`,
        )}`,
        method: "POST",
        body: {
          name,
        },
        validateStatus: (response, result) => validateError(response, result),
      }),
      invalidatesTags: () => [{ type: "campaigns" }],
    }),
    deleteAdvCampaign: builder.mutation<
      ApiResponseOk | ApiResponseError,
      string
    >({
      query: (campaign_id) => ({
        url: `${createApiLink(`/advertiser/Campaign/${campaign_id}`)}`,
        method: "DELETE",
        validateStatus: (response, result) => validateError(response, result),
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled
          dispatch(
            userApi.endpoints.getModerationsCount.initiate(
              getQueriesModerationCount("advertiser"),
            ),
          )
        } catch (error) {
          console.error("Error DELETE campaign:", error)
        }
      },
      invalidatesTags: () => [{ type: "campaigns" }],
    }),
    getAdvCampaignPads: builder.query<AdvertiserCampaignPad[], string>({
      query: (campaign_id) => ({
        url: `${createApiLink(`/advertiser/Campaign/${campaign_id}/pads/`)}`,
        method: "GET",
      }),
      providesTags: () => [{ type: "campaigns" }],
    }),
    // Offer
    getAdvCampaignOffer: builder.query<
      AdvertiserCampaignOffer,
      AdvertiserCampaignOfferIds
    >({
      query: ({ campaign_id, offer_id }) => ({
        url: `${createApiLink(
          `/advertiser/Campaign/${campaign_id}/Offer/${offer_id}/`,
        )}`,
        method: "GET",
      }),
      providesTags: () => [{ type: "offers" }],
    }),
    getAdvCampaignAllOffers: builder.query<AdvertiserCampaignOffer[], string>({
      query: (id) => ({
        url: `${createApiLink(`/advertiser/Campaign/${id}/Offer/`)}`,
        method: "GET",
      }),
      providesTags: () => [{ type: "offers" }],
    }),
    createAdvCampaignOffer: builder.mutation<
      ApiResponseOk | ApiResponseError,
      AdvertiserCampaignOfferCreate
    >({
      query: ({ campaign_id, name, url, state }) => ({
        url: `${createApiLink(`/advertiser/Campaign/${campaign_id}/Offer/`)}`,
        method: "POST",
        body: {
          name,
          url,
          state,
        },
        validateStatus: (response, result) => validateError(response, result),
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled
          dispatch(
            userApi.endpoints.getModerationsCount.initiate(
              getQueriesModerationCount("advertiser"),
            ),
          )
        } catch (error) {
          console.error("Error CREATE offer:", error)
        }
      },
      invalidatesTags: () => [{ type: "offers" }],
    }),
    updateAdvCampaignOffer: builder.mutation<
      ApiResponseOk | ApiResponseError,
      AdvertiserCampaignOfferUpdate
    >({
      query: ({ campaign_id, offer_id, name, url, state, user_comments }) => ({
        url: `${createApiLink(
          `/advertiser/campaign/${campaign_id}/Offer/${offer_id}`,
        )}`,
        method: "PUT",
        body: {
          name,
          url,
          state,
          user_comments,
        },
        validateStatus: (response, result) => validateError(response, result),
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled
          dispatch(
            userApi.endpoints.getModerationsCount.initiate(
              getQueriesModerationCount("advertiser"),
            ),
          )
        } catch (error) {
          console.error("Error EDIT offer:", error)
        }
      },
      invalidatesTags: () => [{ type: "offers" }],
    }),
    deleteAdvCampaignOffer: builder.mutation<
      ApiResponseOk | ApiResponseError,
      AdvertiserCampaignOfferIds
    >({
      query: ({ campaign_id, offer_id }) => ({
        url: `${createApiLink(
          `/advertiser/Campaign/${campaign_id}/Offer/${offer_id} `,
        )}`,
        method: "DELETE",
        validateStatus: (response, result) => validateError(response, result),
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled
          dispatch(
            userApi.endpoints.getModerationsCount.initiate(
              getQueriesModerationCount("advertiser"),
            ),
          )
        } catch (error) {
          console.error("Error DELETE offer:", error)
        }
      },
      invalidatesTags: () => [{ type: "offers" }],
    }),
    updateAdvCampaginAllOffersStates: builder.mutation<
      ApiResponseOk | ApiResponseError,
      AdvertiserCampaignAllOffersStatesUpdate
    >({
      query: ({ campaign_id, state }) => ({
        url: `${createApiLink(
          `/advertiser/Campaign/${campaign_id}/SetAllState/`,
        )}`,
        method: "POST",
        body: {
          state,
        },
        validateStatus: (response, result) => validateError(response, result),
      }),
      invalidatesTags: () => [{ type: "offers" }],
    }),
    updateAdvCampaginOfferPadState: builder.mutation<
      ApiResponseOk | ApiResponseError,
      AdvertiserCampaignOfferPadStateUpdate
    >({
      query: ({ offer_id, pad_id, state }) => ({
        url: `${createApiLink(`/advertiser/OfferPadState/`)}`,
        method: "POST",
        body: {
          offer_id,
          pad_id,
          state,
        },
        validateStatus: (response, result) => validateError(response, result),
      }),
      invalidatesTags: () => [{ type: "offers" }],
    }),
    // Text
    getAdvCampaignOfferText: builder.query<
      AdvertiserCampaignOfferText,
      AdvertiserCampaignOfferTextIds
    >({
      query: ({ offer_id, text_id }) => ({
        url: `${createApiLink(
          `/advertiser/Offer/${offer_id}/Text/${text_id}/`,
        )}`,
        method: "GET",
      }),
      providesTags: () => [{ type: "texts" }],
    }),
    getAdvCampaignOfferAllTexts: builder.query<
      AdvertiserCampaignOfferText[],
      string
    >({
      query: (offer_id) => ({
        url: `${createApiLink(`/advertiser/Offer/${offer_id}/Text/`)}`,
        method: "GET",
      }),
      providesTags: () => [{ type: "texts" }],
    }),
    createAdvCampaignOfferText: builder.mutation<
      ApiResponseOk | ApiResponseError,
      AdvertiserCampaignOfferTextCreate
    >({
      query: ({ offer_id, text, state }) => ({
        url: `${createApiLink(`/advertiser/Offer/${offer_id}/Text/`)}`,
        method: "POST",
        body: {
          text,
          state,
        },
        validateStatus: (response, result) => validateError(response, result),
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled
          dispatch(
            userApi.endpoints.getModerationsCount.initiate(
              getQueriesModerationCount("advertiser"),
            ),
          )
        } catch (error) {
          console.error("Error CREATE offer text:", error)
        }
      },
      invalidatesTags: () => [{ type: "texts" }],
    }),
    updateAdvCampaignOfferText: builder.mutation<
      ApiResponseOk | ApiResponseError,
      AdvertiserCampaignOfferTextUpdate
    >({
      query: ({ offer_id, text_id, text, state, user_comments }) => ({
        url: `${createApiLink(
          `/advertiser/Offer/${offer_id}/Text/${text_id}/`,
        )}`,
        method: "PUT",
        body: {
          text,
          state,
          user_comments,
        },
        validateStatus: (response, result) => validateError(response, result),
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled
          dispatch(
            userApi.endpoints.getModerationsCount.initiate(
              getQueriesModerationCount("advertiser"),
            ),
          )
        } catch (error) {
          console.error("Error EDIT offer text:", error)
        }
      },
      invalidatesTags: () => [{ type: "texts" }],
    }),
    deleteAdvCampaignOfferText: builder.mutation<
      ApiResponseOk | ApiResponseError,
      AdvertiserCampaignOfferTextIds
    >({
      query: ({ offer_id, text_id }) => ({
        url: `${createApiLink(
          `/advertiser/Offer/${offer_id}/Text/${text_id}/`,
        )}`,
        method: "DELETE",
        validateStatus: (response, result) => validateError(response, result),
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled
          dispatch(
            userApi.endpoints.getModerationsCount.initiate(
              getQueriesModerationCount("advertiser"),
            ),
          )
        } catch (error) {
          console.error("Error DELETE offer text:", error)
        }
      },
      invalidatesTags: () => [{ type: "texts" }],
    }),
    // Creative
    getAdvCampaignOfferCreativeTypes: builder.query<
      AdvertiserCampaignOfferCreativeType[],
      string
    >({
      query: (offer_id) => ({
        url: `${createApiLink(`/advertiser/Offer/${offer_id}/TeaserType/`)}`,
        method: "GET",
      }),
      providesTags: () => [{ type: "teasers" }],
    }),
    getAdvCampaignOfferCreative: builder.query<
      AdvertiserCampaignOfferCreative,
      AdvertiserCampaignOfferCreativeIds
    >({
      query: ({ offer_id, teaser_id }) => ({
        url: `${createApiLink(
          `/advertiser/Offer/${offer_id}/Teaser/${teaser_id}/`,
        )}`,
        method: "GET",
      }),
      providesTags: () => [{ type: "teasers" }],
    }),
    getAdvCampaignOfferAllCreatives: builder.query<
      AdvertiserCampaignOfferCreative[],
      string
    >({
      query: (offer_id) => ({
        url: `${createApiLink(`/advertiser/Offer/${offer_id}/Teaser/`)}`,
        method: "GET",
      }),
      providesTags: () => [{ type: "teasers" }],
    }),
    createAdvCampaignOfferCreative: builder.mutation<
      ApiResponseOk | ApiResponseError,
      AdvertiserCampaignOfferCreativeCreate
    >({
      query: ({ offer_id, teaser_type, image_type, image, state }) => ({
        url: `${createApiLink(`/Advertiser/Offer/${offer_id}/Teaser/`)}`,
        method: "POST",
        body: {
          teaser_type,
          image_type,
          image,
          state,
        },
        validateStatus: (response, result) => validateError(response, result),
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled
          dispatch(
            userApi.endpoints.getModerationsCount.initiate(
              getQueriesModerationCount("advertiser"),
            ),
          )
        } catch (error) {
          console.error("Error CREATE offer creative:", error)
        }
      },
      invalidatesTags: () => [{ type: "teasers" }],
    }),
    updateAdvCampaignOfferCreative: builder.mutation<
      ApiResponseOk | ApiResponseError,
      AdvertiserCampaignOfferCreativeUpdate
    >({
      query: ({
        offer_id,
        teaser_id,
        teaser_type,
        image_type,
        image,
        state,
        user_comment,
      }) => ({
        url: `${createApiLink(
          `/Advertiser/Offer/${offer_id}/Teaser/${teaser_id}/`,
        )}`,
        method: "PUT",
        body: {
          teaser_type,
          image_type,
          image,
          state,
          user_comment,
        },
        validateStatus: (response, result) => validateError(response, result),
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled
          dispatch(
            userApi.endpoints.getModerationsCount.initiate(
              getQueriesModerationCount("advertiser"),
            ),
          )
        } catch (error) {
          console.error("Error EDIT offer creative:", error)
        }
      },
      invalidatesTags: () => [{ type: "teasers" }],
    }),
    deleteAdvCampaignOfferCreative: builder.mutation<
      ApiResponseOk | ApiResponseError,
      AdvertiserCampaignOfferCreativeIds
    >({
      query: ({ offer_id, teaser_id }) => ({
        url: `${createApiLink(
          `/advertiser/Offer/${offer_id}/Teaser/${teaser_id}/`,
        )}`,
        method: "DELETE",
        validateStatus: (response, result) => validateError(response, result),
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled
          dispatch(
            userApi.endpoints.getModerationsCount.initiate(
              getQueriesModerationCount("advertiser"),
            ),
          )
        } catch (error) {
          console.error("Error DELETE offer creative:", error)
        }
      },
      invalidatesTags: () => [{ type: "teasers" }],
    }),
    updateAdvCampaignOfferTextAndCreativeState: builder.mutation<
      ApiResponseOk | ApiResponseError,
      AdvertiserCampaignOfferTextAndCreativeStateUpdate
    >({
      query: ({ teaser_id, offer_id, text_id, state }) => ({
        url: `${createApiLink(`/advertiser/OfferPadState/`)}`,
        method: "POST",
        body: {
          offer_id,
          teaser_id,
          text_id,
          state,
        },
        validateStatus: (response, result) => validateError(response, result),
      }),
      invalidatesTags: () => [{ type: "campaigns" }],
    }),
    // Widgets/Source
    getAdvCampaignWidgets: builder.query<AdvertiserWidget[], string>({
      query: (campaign_id) => ({
        url: `${createApiLink(`/advertiser/campaign/${campaign_id}/sources/`)}`,
        method: "GET",
      }),
      providesTags: () => [{ type: "campaigns" }],
    }),
  }),
  overrideExisting: false,
})

export const {
  // Campaign
  useGetAdvCampaignQuery,
  useGetAdvAllCampaignsQuery,
  useCreateAdvCampaignMutation,
  useUpdateAdvCampaignMutation,
  useCopyAdvCampaignMutation,
  useDeleteAdvCampaignMutation,
  // Campaign pads
  useGetAdvCampaignPadsQuery,
  // Offer
  useGetAdvCampaignOfferQuery,
  useGetAdvCampaignAllOffersQuery,
  useCreateAdvCampaignOfferMutation,
  useUpdateAdvCampaignOfferMutation,
  useDeleteAdvCampaignOfferMutation,
  // Offers state and Offer Pad state
  useUpdateAdvCampaginAllOffersStatesMutation,
  useUpdateAdvCampaginOfferPadStateMutation,
  // Text
  useGetAdvCampaignOfferTextQuery,
  useGetAdvCampaignOfferAllTextsQuery,
  useCreateAdvCampaignOfferTextMutation,
  useUpdateAdvCampaignOfferTextMutation,
  useDeleteAdvCampaignOfferTextMutation,
  // Creative
  useGetAdvCampaignOfferCreativeTypesQuery,
  useGetAdvCampaignOfferCreativeQuery,
  useGetAdvCampaignOfferAllCreativesQuery,
  useCreateAdvCampaignOfferCreativeMutation,
  useUpdateAdvCampaignOfferCreativeMutation,
  useDeleteAdvCampaignOfferCreativeMutation,
  // Creative and Text state
  useUpdateAdvCampaignOfferTextAndCreativeStateMutation,
  // Widgets/Source
  useGetAdvCampaignWidgetsQuery,
} = advertiserCampaignsApi
