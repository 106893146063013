import { useState, useEffect, ChangeEvent } from "react"
import { AdvertiserInfo } from "@/definitions"
import { useUpdateAdvUserInfoMutation } from "@/redux/slices/advertiserApi"
import { Button, Flex, Input, Select, Typography } from "antd"
import { TFunction } from "i18next"
import { filterOptions } from "@/utils/filterOptions"

const { Text } = Typography

interface AdvAccountInformationProps {
  t: TFunction<"translation", undefined>
  userData: AdvertiserInfo | undefined
  timezonesData: string[] | undefined
}

export const AdvAccountInformation = ({
  t,
  userData,
  timezonesData,
}: AdvAccountInformationProps): JSX.Element => {
  const [name, setName] = useState<string>("")
  const [timezone, setTimezone] = useState<string>("")

  const [updateAdvInfo, { isLoading }] = useUpdateAdvUserInfoMutation()

  useEffect(() => {
    if (userData) {
      setName(userData.name)
      setTimezone(userData.time_zone)
    }
  }, [userData])

  const onChangeName = (e: ChangeEvent<HTMLInputElement>): void => {
    setName(e.target.value)
  }

  const onChangeTimezone = (value: string): void => {
    setTimezone(value)
  }

  return (
    <Flex vertical gap="large" style={{ maxWidth: "300px" }}>
      <Text type="success">
        {t("ADV.ACCOUNT.LOGIN")}: {userData?.login}
      </Text>
      <Input
        placeholder={t("INPUTS.NAME")}
        value={name}
        onChange={onChangeName}
        style={{ width: "100%" }}
      />
      <Select
        optionFilterProp="children"
        filterOption={filterOptions}
        onChange={onChangeTimezone}
        showSearch
        options={timezonesData?.map((timezone: string) => ({
          label: timezone,
          value: timezone,
        }))}
        value={timezone}
        style={{ width: "100%" }}
      />
      <Button
        loading={isLoading}
        style={{ width: "100%" }}
        onClick={() => updateAdvInfo({ name, timezone })}
        type="primary"
      >
        {t("ADV.ACCOUNT.SAVE_BUTTON")}
      </Button>
    </Flex>
  )
}
