import { Typography, Select, Table, DatePicker, Checkbox, Flex } from "antd"
import { useEffect, useState } from "react"
import { useGetAdvOffersDomainsStatisticsQuery } from "@/redux/slices/advertiserApi"
import { useTranslation } from "react-i18next"
import { StdErrorMessage } from "@/components/common/Std/ErrorMessage"
import { useGetAdvAllCampaignsQuery } from "@/redux/slices/advertiserCampaignsApi"
import { AdvertiserCampaigns } from "@/definitions"
import { getValueByBoolean } from "@/utils/getValueByBoolean"
import dayjs from "dayjs"

const { Title } = Typography
const { Option } = Select

export const AdvToolsOfferDomains = (): JSX.Element => {
  const { t } = useTranslation()

  const [campaign, setCampaign] = useState<string>("")
  const [startDate, setStartDate] = useState<string>(
    dayjs().subtract(7, "day").format("YYYY-MM-DD"),
  )
  const [endDate, setEndDate] = useState<string>(dayjs().format("YYYY-MM-DD"))
  const [geo, setGeo] = useState<string>("ALL")
  const [showUrlsStat, setShowUrlsStat] = useState<boolean>(false)

  const { data: campaignsData, isLoading: campaignsDataIsLoading } =
    useGetAdvAllCampaignsQuery()

  const {
    data: statsData,
    isLoading: statsIsLoading,
    isError: statsIsError,
  } = useGetAdvOffersDomainsStatisticsQuery(
    `from=${startDate}&to=${endDate}&show_url_stat=${getValueByBoolean(
      showUrlsStat,
    )}&campaign_id=${campaign}&geo=${geo}`,

    {
      skip: !campaign,
    },
  )

  const defaultCampaign = campaignsData?.reduce((maxObj, currentObj) => {
    return parseFloat(currentObj.campaign_id) > parseFloat(maxObj.campaign_id)
      ? currentObj
      : maxObj
  }, campaignsData[0])

  useEffect(() => {
    setCampaign(defaultCampaign?.campaign_id || "")
  }, [campaignsData])

  const onChangeStartDate = (date: any, dateString: string): void => {
    setStartDate(dateString)
  }

  const onChangeEndDate = (date: any, dateString: string): void => {
    setEndDate(dateString)
  }

  const onChangeCampaign = (value: string): void => {
    setCampaign(value)
  }

  if (statsIsError) return <StdErrorMessage>Error...</StdErrorMessage>

  return (
    <Flex vertical gap="large">
      <Title level={3}>{t("ADV.TOOLS.OFFERS_DOMAINS")}</Title>

      <Select
        placeholder={t("SELECTS.CAMPAIGN_PLACEHOLDER")}
        style={{ maxWidth: "300px" }}
        optionFilterProp="children"
        loading={campaignsDataIsLoading}
        showSearch
        onChange={onChangeCampaign}
        defaultValue={defaultCampaign?.campaign_id}
        options={campaignsData?.map((campaign: AdvertiserCampaigns) => ({
          label: `${campaign.campaign_id} ${campaign.name}`,
          value: campaign.campaign_id,
        }))}
      />
      <div style={{ maxWidth: "300px", display: "flex", gap: "20px" }}>
        <DatePicker
          onChange={onChangeStartDate}
          placeholder={t("SELECTS.START_DATE_PLACEHOLDER")}
          defaultValue={dayjs().subtract(7, "day")}
          disabled={!campaign}
        />
        <DatePicker
          onChange={onChangeEndDate}
          placeholder={t("SELECTS.END_DATE_PLACEHOLDER")}
          defaultValue={dayjs()}
          disabled={!campaign}
        />
      </div>
      <Select
        style={{ maxWidth: "300px" }}
        placeholder={t("ALL")}
        defaultValue={"all"}
        onChange={(value) => setGeo(value)}
        disabled={!campaign}
      >
        <Option value="all">{t("ALL")}</Option>
        <Option value="FR">FR</Option>
        <Option value="US">US</Option>
        <Option value="CL">CL</Option>
      </Select>

      <Checkbox
        disabled={!campaign || !geo}
        onChange={(e) => {
          setShowUrlsStat(e.target.checked)
        }}
      >
        {t("ADV.TOOLS.SHOW_ULRS_STAT")}
      </Checkbox>

      <Table
        scroll={{ x: "max-content" }}
        loading={statsIsLoading}
        // rowKey={"id"}
        dataSource={statsData?.domains}
        columns={[
          {
            title: t("ADV.TOOLS.FIRST_DATA"),
            dataIndex: "date",
            key: "date",
          },
          {
            title: t("ADV.TOOLS.OFFER"),
            dataIndex: "offer",
            key: "offer",
          },
          {
            title: t("ADV.TOOLS.COUNTRY"),
            dataIndex: "country",
            key: "country",
          },
          {
            title: t("ADV.TOOLS.DOMAIN"),
            dataIndex: "domain",
            key: "domain",
          },
          {
            title: t("ADV.TOOLS.BAD"),
            dataIndex: "bad",
            key: "bad",
          },
        ]}
        pagination={{ position: ["bottomCenter"] }}
      />

      {/* <br />
      <Table
        scroll={{ x: "max-content" }}
        loading={isLoading}
        rowKey="id"
        dataSource={data?.urls}
        columns={[
          {
            title: t("FIRST_DATA"),
            dataIndex: "date",
            key: "date",
          },
          {
            title: t("OFFER"),
            dataIndex: "offer",
            key: "offer",
          },
          {
            title: t("COUNTRY"),
            dataIndex: "country",
            key: "country",
          },
          {
            title: 'Url',
            dataIndex: "domain",
            key: "domain",
          },
          {
            title: t("CONNECT_TIME"),
            dataIndex: "bad",
            key: "bad",
          },
          {
            title: 'Http code',
            dataIndex: "bad",
            key: "bad",
          },
        ]}
        pagination={{ position: ["bottomCenter"], showSizeChanger: false, }}
      /> */}
    </Flex>
  )
}
