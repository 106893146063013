import { ModerationStatuses } from "@/definitions"
import { textToUpperCase } from "@/utils/textToUpperCase"
import { Typography } from "antd"
import { BaseType } from "antd/es/typography/Base"
import { useTranslation } from "react-i18next"

const { Text } = Typography

interface StdModerationStatusTextProps {
  status: ModerationStatuses | undefined
}

export const StdModerationStatusText = ({
  status,
}: StdModerationStatusTextProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Text
      strong={true}
      type={
        MODERATION_STATUSES_COLORS.get(status as ModerationStatuses) ||
        "secondary"
      }
    >
      {status ? t(`STATUSES.${textToUpperCase(status)}`) : ""}
    </Text>
  )
}

const MODERATION_STATUSES_COLORS = new Map<ModerationStatuses, BaseType>([
  [ModerationStatuses.Approved, "success"],
  [ModerationStatuses.OnModeration, "warning"],
  [ModerationStatuses.Rejected, "danger"],
])
