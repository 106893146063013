import { StdErrorMessage } from "@/components/common/Std/ErrorMessage"
import { StdSpinner } from "@/components/common/Std/Spinner"
import { useGetAdvCampaignOfferCreativeQuery } from "@/redux/slices/advertiserCampaignsApi"
import { AdvCampaignOfferCreativeEdit } from "@/components/advertiser/Campaigns/Offers/Creatives/CreativeEdit"
import { Breadcrumb, Divider, Typography } from "antd"
import { useTranslation } from "react-i18next"
import { NavLink, useParams } from "react-router-dom"

const { Title } = Typography

export const AdvCampaignOfferCreative = (): JSX.Element => {
  const { campaignId, offerId, creativeId } = useParams()
  const { t } = useTranslation()

  const { data, isLoading, isError } = useGetAdvCampaignOfferCreativeQuery({
    offer_id: offerId || "",
    teaser_id: creativeId || "",
  })

  if (isLoading) return <StdSpinner />

  if (isError) return <StdErrorMessage>Error...</StdErrorMessage>

  return (
    <div>
      <Breadcrumb
        items={[
          {
            title: (
              <NavLink to={"/advertiser/campaigns"}>
                {t("ADV.CAMPAIGNS.CAMPAIGNS")}
              </NavLink>
            ),
          },
          {
            title: (
              <NavLink to={`/advertiser/campaigns/${campaignId}`}>
                {`${t("ADV.CAMPAIGNS.CAMPAIGN_ID")}: ${campaignId || ""}`}
              </NavLink>
            ),
          },
          {
            title: (
              <NavLink
                to={`/advertiser/campaigns/${campaignId}/offers/${offerId}`}
              >
                {`${t("ADV.CAMPAIGNS.OFFER_ID")}: ${offerId || ""}`}
              </NavLink>
            ),
          },
          {
            title: `${t("ADV.CAMPAIGNS.CREATIVE_ID")}: ${creativeId || ""}`,
          },
        ]}
      />
      <Title level={4} style={{ paddingBottom: "5px" }}>
        {t("ADV.CAMPAIGNS.EDIT_CREATIVE")}
      </Title>
      <AdvCampaignOfferCreativeEdit
        creativeData={data}
        offerId={offerId}
        creativeId={creativeId}
      />
      <Divider />
    </div>
  )
}
