import { PaymentType } from "@/definitions"
import { useGetPaymentTypesQuery } from "@/redux/slices/utilsApi"
import { Radio } from "antd"
import { StdSpinner } from "@/components/common/Std/Spinner"
import { StdErrorMessage } from "@/components/common/Std/ErrorMessage"

interface StdPaymentModelsCheckboxesProps {
  onChange: (value: string) => void
  defaultValue?: string
}

export const StdPaymentModelsCheckboxes = ({
  onChange,
  defaultValue,
}: StdPaymentModelsCheckboxesProps): JSX.Element => {
  const { data, isLoading, isError } = useGetPaymentTypesQuery()

  if (isLoading) return <StdSpinner size="default" />

  if (!data || !data.length || isError)
    return <StdErrorMessage>Error...</StdErrorMessage>

  return (
    <Radio.Group
      defaultValue={defaultValue}
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        gap: "5px",
      }}
      onChange={(e) => {
        onChange(e.target.value)
      }}
    >
      {data
        ?.filter((variant) => variant.name !== "CPT")
        ?.map((variant: PaymentType) => (
          <Radio key={variant.id} value={variant.id}>
            {variant.name}
          </Radio>
        ))}
    </Radio.Group>
  )
}
