import { useState } from "react"
import { Button, Divider, List, Modal, Switch, Typography } from "antd"
import { useTranslation } from "react-i18next"
import { AdvCampaignOfferCreativeCreate } from "@/components/advertiser/Campaigns/Offers/Creatives/CreativeCreate"
import {
  useDeleteAdvCampaignOfferCreativeMutation,
  useGetAdvCampaignOfferAllCreativesQuery,
} from "@/redux/slices/advertiserCampaignsApi"
import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { textToUpperCase } from "@/utils/textToUpperCase"
import { Link, useNavigate } from "react-router-dom"

const { Title } = Typography
const { Item } = List

interface AdvCampaignOfferCreativesProps {
  offerId: string | undefined
  campaignId: string | undefined
}

export const AdvCampaignOfferCreatives = ({
  offerId,
  campaignId,
}: AdvCampaignOfferCreativesProps): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false)
  const [creativeId, setCreativeId] = useState<string>("")

  const { data, isLoading } = useGetAdvCampaignOfferAllCreativesQuery(
    offerId || "",
  )

  const [deleteCreative] = useDeleteAdvCampaignOfferCreativeMutation()

  const handleDeleteOffer = (id: string): void => {
    deleteCreative({
      offer_id: campaignId || "",
      teaser_id: id,
    })

    setDeleteModalVisible(false)
  }

  const handleOpenDeleteModal = (id: string): void => {
    setDeleteModalVisible(true)
    setCreativeId(id)
  }

  return (
    <div>
      <Title level={4} style={{ paddingBottom: "5px" }}>
        {t("ADV.CAMPAIGNS.ADD_NEW_CREATIVE")}
      </Title>
      <AdvCampaignOfferCreativeCreate offerId={offerId} />
      <Divider />

      <List
        loading={isLoading}
        header={<div>{t("ADV.CAMPAIGNS.CREATIVES")}</div>}
        bordered
        dataSource={!!data && data?.length > 0 ? data : []}
        renderItem={(item) => (
          <Item
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <Link
              to={`/advertiser/campaigns/${campaignId}/offers/${offerId}/creatives/${item.id}`}
            >
              {item.id}
            </Link>
            <div
              className="actions"
              style={{
                display: "flex",
                gap: "10px",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <Switch
                checkedChildren={t("BUTTONS.ON")}
                unCheckedChildren={t("BUTTONS.OFF")}
                defaultChecked={
                  textToUpperCase(item.state) === "ON" ? true : false
                }
                onChange={(checked) => console.log(checked)}
              />
              <Button
                type="primary"
                style={{ padding: "4px 10px" }}
                onClick={() =>
                  navigate(
                    `/advertiser/campaigns/${campaignId}/offers/${offerId}/creatives/${item.id}`,
                  )
                }
              >
                <EditOutlined />
              </Button>

              <Button
                danger
                type="primary"
                style={{ padding: "4px 10px" }}
                onClick={() => handleOpenDeleteModal(item.id)}
              >
                <DeleteOutlined />
              </Button>
            </div>
          </Item>
        )}
      />
      <Modal
        title={t("ADV.CAMPAIGNS.DELETE_CONFIRMATION")}
        open={deleteModalVisible}
        onOk={() => handleDeleteOffer(creativeId)}
        okText={t("BUTTONS.YES")}
        centered
        width={300}
        onCancel={() => setDeleteModalVisible(false)}
        okButtonProps={{ danger: true }}
      />
    </div>
  )
}
