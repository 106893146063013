import { Country } from "@/definitions"
import { useGetCountriesQuery } from "@/redux/slices/utilsApi"
import { filterOptions } from "@/utils/filterOptions"
import { Select } from "antd"
import { TFunction } from "i18next"
interface StdCountriesProps {
  t: TFunction<"translation", undefined>
  onChange: (value: string) => void
  defaultValue?: string
  allOption?: boolean
  disabled?: boolean
}

export const StdCountries = ({
  t,
  onChange,
  defaultValue,
  allOption,
  disabled,
}: StdCountriesProps): JSX.Element => {
  const { data: countries, isLoading: countriesIsLoading } =
    useGetCountriesQuery()

  const ALL_COUNTRY_OPTION = {
    label: t("SELECTS.ALL"),
    value: "-1",
  }

  const getOptions = () => {
    const options = countries
      ?.filter((c) => c.country_iso_code)
      ?.map((country: Country) => ({
        label: country.country_name.replace(/"/g, ""),
        value: country.country_iso_code,
      }))

    if (allOption) {
      options?.unshift(ALL_COUNTRY_OPTION)
    }

    return options
  }

  return (
    <Select
      placeholder={t("SELECTS.COUNTRY_PLACEHOLDER")}
      showSearch
      disabled={disabled}
      optionFilterProp="children"
      filterOption={filterOptions}
      defaultValue={defaultValue}
      loading={countriesIsLoading}
      onChange={(value: string) => onChange(value)}
      options={getOptions()}
    />
  )
}
