import { useEffect, useState } from "react"
import { WidgetsTypesSelect } from "@/components/publisher/Widgets/WidgetTypesSelect"
import { StdPaymentModelsCheckboxes } from "@/components/common/Std/PaymentTypesCheckboxes"
import { StdCountries } from "@/components/common/Std/Selects/Countries"
import { StdTimezones } from "@/components/common/Std/Selects/Timezones"
import { PublisherSourceCreate } from "@/definitions"
import {
  useCreatePubSourceMutation,
  useGetPubSourceDefaultPriceQuery,
} from "@/redux/slices/publisherApi"
import { Button, Form, Input, InputNumber, Typography } from "antd"
import { TFunction } from "i18next"
import type { FormProps } from "antd"
import { DOMAIN_URL_PATTER } from "@/constants"
import { StdDevicesTypes } from "@/components/common/Std/Selects/DevicesTypes"

const { Item, useForm } = Form
const { Title, Text } = Typography

interface PubWidgetCreateProps {
  t: TFunction<"translation", undefined>
}

export const PubWidgetCreate = ({ t }: PubWidgetCreateProps): JSX.Element => {
  const [form] = useForm()

  const [countryCode, setCountryCode] = useState<string>("")
  const [timezone, setTimezone] = useState<string>("")
  const [paymentModelId, setPaymentModelId] = useState<string>("")
  const [widgetTypeId, setWidgetTypeId] = useState<string>("")
  const [deviceTypeId, setDeviceTypeId] = useState<string>("")
  const [defaultPrice, setDefaultPrice] = useState<number | null>(null)
  const [warningPrice, setWarningPrice] = useState<boolean>(false)

  const [createWidget, { data, isLoading, isSuccess }] =
    useCreatePubSourceMutation()

  const { data: defaultPriceData } = useGetPubSourceDefaultPriceQuery(
    `payment_type_id=${paymentModelId}&country_code=${countryCode}&source_type_id=${widgetTypeId}`,
    { skip: !paymentModelId || !countryCode || !widgetTypeId },
  )

  useEffect(() => {
    if (isSuccess && data.state === "ok") {
      form.resetFields()
      setCountryCode("")
      setTimezone("")
      setPaymentModelId("")
      setWidgetTypeId("")
      setDeviceTypeId("")
      setWarningPrice(false)
      setDefaultPrice(null)
    }
  }, [isSuccess])

  useEffect(() => {
    const defaultPriceFromData = defaultPriceData?.default_price

    if (defaultPriceFromData) {
      setDefaultPrice(defaultPriceFromData)
      form.setFieldValue("price", defaultPriceFromData)
      setWarningPrice(false)
    }
  }, [defaultPriceData])

  const handleChangeCountry = (value: string): void => {
    form.setFieldValue("country", value)
    setCountryCode(value)
  }

  const handleChangePrice = (
    value: number | null,
    defaultPrice: number | null,
  ): void => {
    if (!value) {
      setWarningPrice(false)
      form.setFieldValue("price", value)
      return
    }

    form.setFieldValue("price", value)

    if (defaultPrice && value > defaultPrice) {
      setWarningPrice(true)
    } else {
      setWarningPrice(false)
    }
  }

  const handleChangePaymentModel = (value: string): void => {
    form.setFieldValue("payment_model", value)
    setPaymentModelId(value)
  }

  const handleChangeWidgetType = (value: string): void => {
    form.setFieldValue("teaser_type", value)
    setWidgetTypeId(value)
  }

  const handleChangeTimezone = (value: string): void => {
    form.setFieldValue("timezone", value)
    setTimezone(value)
  }

  const handleChangeDeviceType = (value: string): void => {
    form.setFieldValue("device_type", value)
    setDeviceTypeId(value)
  }

  const onFinish: FormProps<PublisherSourceCreate>["onFinish"] = (
    values,
  ): void => {
    createWidget(values)
  }

  return (
    <Form
      form={form}
      name="create-widget"
      layout="vertical"
      labelCol={{ span: 16 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 450 }}
      onFinish={onFinish}
      initialValues={{
        domain: "",
        source: "",
        payment_model: "",
        teaser_type: "",
        country: "",
        device_type: "",
        price: defaultPrice,
      }}
      autoComplete="off"
    >
      <Title level={4} style={{ paddingBottom: "5px" }}>
        {t("PUB.WIDGETS.CREATE_WIDGET")}
      </Title>
      <Item
        name="source"
        label={t("INPUTS.WIDGET_NAME")}
        rules={[{ required: true, message: t("INPUTS.WIDGET_NAME_REQ") }]}
      >
        <Input placeholder={t("INPUTS.WIDGET_NAME_PLACEHOLDER")} />
      </Item>
      <Item
        name="country"
        label={t("SELECTS.COUNTRY")}
        rules={[{ required: true, message: t("SELECTS.COUNTRY_REQ") }]}
        help={
          countryCode ? (
            <Text type="warning">{t("PUB.WIDGETS.COUNTRY_WARNING")}</Text>
          ) : null
        }
      >
        <StdCountries t={t} onChange={handleChangeCountry} />
      </Item>
      <Item
        name="timezone"
        label={t("SELECTS.TIMEZONE")}
        rules={[{ required: true, message: t("SELECTS.TIMEZONE_REQ") }]}
        help={
          timezone ? (
            <Text type="warning">{t("PUB.WIDGETS.TIMEZONE_WARNING")}</Text>
          ) : null
        }
      >
        <StdTimezones t={t} onChange={handleChangeTimezone} />
      </Item>
      <Item
        name="domain"
        label={t("INPUTS.WEBSITE")}
        rules={[
          { required: true, message: t("INPUTS.WEBSITE_REQ") },
          { pattern: DOMAIN_URL_PATTER, message: t("INPUTS.WEBSITE_INVALID") },
        ]}
      >
        <Input placeholder={t("INPUTS.WEBSITE_PLACEHOLDER")} />
      </Item>
      <Item
        name="teaser_type"
        label={t("SELECTS.WIDGET_TYPE")}
        rules={[{ required: true, message: t("SELECTS.WIDGET_TYPE_REQ") }]}
        help={
          widgetTypeId ? (
            <Text type="warning">{t("PUB.WIDGETS.WIDGET_TYPE_WARNING")}</Text>
          ) : null
        }
      >
        <WidgetsTypesSelect t={t} onChange={handleChangeWidgetType} />
      </Item>
      <Item
        name="device_type"
        label={t("SELECTS.WIDGET_DEVICE_TYPE")}
        rules={[
          { required: true, message: t("SELECTS.WIDGET_DEVICE_TYPE_REQ") },
        ]}
        help={
          deviceTypeId ? (
            <Text type="warning">
              {t("PUB.WIDGETS.WIDGET_DEVICE_TYPE_WARNING")}
            </Text>
          ) : null
        }
      >
        <StdDevicesTypes t={t} onChange={handleChangeDeviceType} />
      </Item>
      <Item
        name="payment_model"
        label={t("SELECTS.PAYMENT_MODEL")}
        rules={[{ required: true, message: t("SELECTS.PAYMENT_MODEL_REQ") }]}
      >
        <StdPaymentModelsCheckboxes onChange={handleChangePaymentModel} />
      </Item>
      <Item
        name="price"
        label={t("INPUTS.PRICE_USD")}
        rules={[{ required: true, message: t("INPUTS.PRICE_USD_REQ") }]}
        help={
          warningPrice ? (
            <Text type="warning">
              {t("PUB.WIDGETS.DEFAULT_PRICE_WARNING", {
                price: defaultPrice,
              })}
            </Text>
          ) : null
        }
      >
        <InputNumber
          min={0}
          max={1000}
          decimalSeparator="."
          addonAfter="$"
          style={{ width: "100%" }}
          onChange={(value) => handleChangePrice(value, defaultPrice)}
          placeholder={t("INPUTS.PRICE_USD_PLACEHOLDER")}
        />
      </Item>
      <Item>
        <Button
          style={{ width: "100%" }}
          type="primary"
          htmlType="submit"
          loading={isLoading}
        >
          {t("BUTTONS.CREATE")}
        </Button>
      </Item>
    </Form>
  )
}
