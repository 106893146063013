import { Layout } from "antd"
import { NavLink } from "react-router-dom"
import { TFunction } from "i18next"
import styles from "@/components/common/Layouts/AuthLayout/AuthLayout.module.css"

const date = new Date()
const year = date.getFullYear()

const { Footer } = Layout

interface AuthLayoutFooterProps {
  t: TFunction<"translation", undefined>
  lang: string | undefined
}

export const AuthLayoutFooter = ({
  t,
  lang,
}: AuthLayoutFooterProps): JSX.Element => {
  const isEsLang = lang === "es"

  return (
    <Footer className={styles.footer}>
      {t("RIGHTS", { year: year })}
      <div className={styles.footerLinks}>
        <NavLink
          to={
            isEsLang
              ? "https://adn.scroogefrog.com/files/Condiciones_de_uso.pdf"
              : "https://adn.scroogefrog.com/files/Terms_of_Use.pdf"
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("TERMS")}
        </NavLink>
        <NavLink
          to={
            isEsLang
              ? "https://adn.scroogefrog.com/files/Privacy_Policy_es.pdf"
              : "https://adn.scroogefrog.com/files/Privacy_Policy.pdf"
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("PRIVACY")}
        </NavLink>
      </div>
    </Footer>
  )
}
