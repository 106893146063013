import { Button, Form, Input, Typography } from "antd"
import { Link, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useSigninUserMutation } from "@/redux/slices/authApi"
import { useEffect } from "react"
import { setCookie } from "@/utils/cookies"
import { Roles } from "@/definitions"
import styles from "@/components/common/Login/Login.module.css"
import toast from "react-hot-toast"

const { Text } = Typography

export const Login = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [signinUser, { data }] = useSigninUserMutation()

  useEffect(() => {
    if (data?.state === "err") {
      toast.error(t("LOGIN_ERROR"))
      toast.error(t(data?.data))
    }

    if (data?.state === "ok" || data?.success) {
      const user_type = data.data.user_type
      const role = user_type === "1" ? Roles.Publisher : Roles.Advertiser

      toast.success(t("LOGIN_SUCCESS"))

      setCookie("user_type", user_type)
      setCookie("token", data.data.token)
      setCookie("role", role)

      navigate(
        role === "publisher"
          ? "/publisher/statistics"
          : "/advertiser/statistics",
      )
    }
  }, [data])

  const onFinish = (values: any) => {
    signinUser({ login: values.login, password: values.password })
  }

  return (
    <Form
      name="login"
      layout={"vertical"}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      autoComplete="off"
      className={styles.container}
    >
      <Form.Item
        label={t("EMAIL")}
        name="login"
        rules={[{ required: true, message: t("EMAIL_REQUIRED") }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t("PASSWORD")}
        name="password"
        rules={[{ required: true, message: t("PASSWORD_REQUIRED") }]}
      >
        <Input.Password />
      </Form.Item>
      {/* <div className={styles.information}>
        <div className={styles.register}>
          <Text>{t("DONT_HAVE_ACCOUNT")}</Text>
          <Link to="/register">
            {t("REGISTER_NOW")}
          </Link>
        </div>
        <div className={styles.forgotPassword}>
          <Link to="/forgot-password">
            {t("FORGOT_PASSWORD")}
          </Link>
        </div>
      </div> */}
      <Form.Item className={styles.registerButton}>
        <Button type="primary" htmlType="submit">
          {t("SIGN_IN")}
        </Button>
      </Form.Item>
    </Form>
  )
}
