import { Dispatch, SetStateAction } from "react"
import { AdvertiserCampaigns } from "@/definitions"
import { DatePicker, Flex, Select } from "antd"
import { TFunction } from "i18next"
import { StdLabel } from "@/components/common/Std/Label"
import { DefaultOptionType } from "antd/es/select"
import dayjs from "dayjs"

interface AdvToolsFiltersProps {
  t: TFunction<"translation", undefined>
  campaignsData: AdvertiserCampaigns[] | undefined
  defaultCampaign: AdvertiserCampaigns | undefined
  widgetsOptions: DefaultOptionType[] | undefined
  campaignsIsLoading: boolean
  widgetsDataIsLoading: boolean
  setWidgetId: Dispatch<SetStateAction<string>>
  onChangeCampaign: (value: string) => void
  onChangeStartDate: (_: any, dateString: string) => void
  onChangeEndDate: (_: any, dateString: string) => void
}

export const AdvToolsFilters = ({
  t,
  campaignsData,
  defaultCampaign,
  campaignsIsLoading,
  widgetsDataIsLoading,
  widgetsOptions,
  setWidgetId,
  onChangeCampaign,
  onChangeStartDate,
  onChangeEndDate,
}: AdvToolsFiltersProps): JSX.Element => {
  const ALL_WIDGETS_OPTION: DefaultOptionType = {
    label: t("SELECTS.ALL"),
    value: "",
  }

  return (
    <>
      <StdLabel label={t("SELECTS.CAMPAIGN_PLACEHOLDER")}>
        <Select
          placeholder={t("SELECTS.CAMPAIGN_PLACEHOLDER")}
          style={{ maxWidth: "300px" }}
          showSearch
          loading={campaignsIsLoading}
          onChange={onChangeCampaign}
          defaultValue={
            defaultCampaign
              ? `${defaultCampaign.campaign_id} - ${defaultCampaign.name}`
              : null
          }
          options={campaignsData?.map((campaign: AdvertiserCampaigns) => ({
            label: `${campaign.campaign_id} ${campaign.name}`,
            value: `${campaign.campaign_id} - ${campaign.name}`,
          }))}
        />
      </StdLabel>
      <Flex gap="large">
        <DatePicker
          onChange={onChangeStartDate}
          placeholder={t("SELECTS.START_DATE_PLACEHOLDER")}
          defaultValue={dayjs().subtract(7, "day")}
        />
        <DatePicker
          onChange={onChangeEndDate}
          placeholder={t("SELECTS.END_DATE_PLACEHOLDER")}
          defaultValue={dayjs()}
        />
      </Flex>
      <StdLabel label={t("SELECTS.WIDGET_ID_PLACEHOLDER")}>
        <Select
          placeholder={t("SELECTS.WIDGET_ID_PLACEHOLDER")}
          onChange={(value) => setWidgetId(value)}
          defaultValue={""}
          showSearch
          loading={widgetsDataIsLoading}
          options={
            widgetsOptions
              ? [ALL_WIDGETS_OPTION, ...widgetsOptions]
              : [ALL_WIDGETS_OPTION]
          }
        />
      </StdLabel>
    </>
  )
}
