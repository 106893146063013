import { AdvCampaignOfferTextCheckLength } from "@/components/advertiser/Campaigns/Offers/Texts/TextCheckLength"
import { Button, Form, FormProps, Input } from "antd"
import { useTranslation } from "react-i18next"
import { ChangeEvent, useEffect, useState } from "react"
import { StdStateSwitcher } from "@/components/common/Std/Form/StateSwitcher"
import {
  AdvertiserCampaignOfferText,
  AdvertiserCampaignOfferTextUpdate,
} from "@/definitions"
import { useUpdateAdvCampaignOfferTextMutation } from "@/redux/slices/advertiserCampaignsApi"
import { StdModerationStatus } from "@/components/common/Std/Form/ModerationStatus"

const { Item } = Form
const { TextArea } = Input

interface AdvCampaignOfferTextEditProps {
  textData: AdvertiserCampaignOfferText | undefined
  offerId: string | undefined
  textId: string | undefined
}

export const AdvCampaignOfferTextEdit = ({
  textData,
  offerId,
  textId,
}: AdvCampaignOfferTextEditProps): JSX.Element => {
  const { t } = useTranslation()

  const [isActive, setIsActive] = useState<boolean>(false)
  const [text, setText] = useState<string>("")
  const [isErrorText, setIsErrorText] = useState<boolean>(false)

  const [updateText, { isLoading }] = useUpdateAdvCampaignOfferTextMutation()

  const handleChangeText = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    const textValue = event.target.value

    setText(textValue)

    const checkElement = document.querySelector(
      ".check-text",
    ) as HTMLElement | null

    if (checkElement) {
      if (
        checkElement.scrollHeight > checkElement?.offsetHeight + 2 ||
        checkElement.scrollWidth > checkElement?.offsetWidth + 2
      ) {
        setIsErrorText(true)
      } else {
        setIsErrorText(false)
      }
    }
  }

  const onFinish: FormProps<AdvertiserCampaignOfferTextUpdate>["onFinish"] = (
    values,
  ) => {
    const newValues = {
      ...values,
      offer_id: offerId || "",
      text_id: textId || "",
      state: isActive ? "on" : "off",
      text: isErrorText ? "" : text,
    }

    updateText(newValues)
  }

  useEffect(() => {
    if (textData?.state === "on") setIsActive(true)
    if (textData?.text) setText(textData?.text)
  }, [textData])

  return (
    <Form
      name="edit-offer-text"
      layout="vertical"
      labelCol={{ span: 16 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 450 }}
      onFinish={onFinish}
      autoComplete="off"
      initialValues={{
        state: textData?.state,
        text: textData?.text,
        user_comments: "",
      }}
    >
      <Item
        name="text"
        label={t("ADV.CAMPAIGNS.TEXT")}
        rules={[{ required: true, message: t("ADV.CAMPAIGNS.TEXT_REQ") }]}
      >
        <TextArea
          onChange={handleChangeText}
          rows={4}
          placeholder={t("ADV.CAMPAIGNS.TEXT")}
        />
      </Item>
      <AdvCampaignOfferTextCheckLength text={text} isErrorText={isErrorText} />
      <Item name="user_comments" label={t("ADV.CAMPAIGNS.USER_COMMENTS")}>
        <TextArea rows={4} placeholder={t("ADV.CAMPAIGNS.USER_COMMENTS")} />
      </Item>
      <StdModerationStatus status={textData?.moderation_status} t={t} />
      <Item name="state">
        <StdStateSwitcher t={t} isActive={isActive} setIsActive={setIsActive} />
      </Item>
      <Item>
        <Button
          loading={isLoading}
          type="primary"
          htmlType="submit"
          style={{ width: "100%" }}
        >
          {t("ADV.CAMPAIGNS.SAVE_BUTTON")}
        </Button>
      </Item>
    </Form>
  )
}
