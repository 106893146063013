import { ReactNode, useEffect, useState, Dispatch, SetStateAction } from "react"
import {
  FileOutlined,
  UserOutlined,
  BugOutlined,
  ToolOutlined,
  AreaChartOutlined,
  EnvironmentOutlined,
  AndroidOutlined,
  BarChartOutlined,
  ProfileOutlined,
  FileImageOutlined,
  FileTextOutlined,
  InfoCircleOutlined,
  ContactsOutlined,
  ControlOutlined,
  FolderOpenOutlined,
  DollarOutlined,
  FileSearchOutlined,
} from "@ant-design/icons"
import type { MenuProps } from "antd"
import { Layout, Menu, Space, Modal, Badge } from "antd"
import { NavLink, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import {
  useGetModerationsCountQuery,
  useGetUserInfoQuery,
} from "@/redux/slices/userApi"
import { TFunction } from "i18next"
import { ItemType, MenuItemType } from "antd/es/menu/hooks/useItems"
import { AuthLayoutFooter } from "@/components/common/Layouts/AuthLayout/Footer"
import { AuthLayoutHeader } from "@/components/common/Layouts/AuthLayout/Header"
import { StdThemeSwitcher } from "@/components/common/Std/ThemeSwitcher"
import { getCookie, removeCookie } from "@/utils/cookies"
import { ModerationCount } from "@/definitions"
import styles from "@/components/common/Layouts/AuthLayout/AuthLayout.module.css"
import { getQueriesModerationCount } from "@/utils/getQueriesModerationCount"

const { Content, Sider } = Layout

interface AuthLayoutProps {
  children: ReactNode
  theme: string
  setTheme: Dispatch<SetStateAction<string>>
}

export const AuthLayout = ({
  children,
  theme,
  setTheme,
}: AuthLayoutProps): JSX.Element => {
  const { t } = useTranslation()

  const location = useLocation()
  const path = location.pathname

  const lang = getCookie("lang")
  const role = getCookie("role")

  const [width, setWidth] = useState<number>(window.innerWidth)
  const [currentPath, setCurrentPath] = useState<string>("")
  const [collapsed, setCollapsed] = useState<boolean>(true)
  const [logoutModalVisible, setLogoutModalVisible] = useState<boolean>(false)

  const isMobile = width <= 768

  const [collapsedWidth, setCollapsedWidth] = useState<string>("0")

  const { data } = useGetUserInfoQuery(role || "")

  const { data: moderationsCountData } = useGetModerationsCountQuery(
    getQueriesModerationCount(role),
  )

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    if (data?.confirmed === 0 || data?.confirmed === "0") {
      window.location.href = `https://adn.scroogefrog.com/?page=confirm_error&login=${
        data?.name
      }&email=${data?.login}&lang=${data?.lang || "en"}`
    }
  }, [data])

  useEffect(() => {
    if (isMobile) {
      setCollapsedWidth("0")
    } else {
      setCollapsedWidth("50")
    }
  }, [isMobile])

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [])

  useEffect(() => {
    setCurrentPath(getCurrentPath(path))
  }, [path])

  const handleMenuClick: MenuProps["onClick"] = (e): void => {
    if (isMobile) {
      setCollapsed(true)
    }
    if (e.key === "help") {
      setCurrentPath((prevState) => prevState)
    } else {
      setCurrentPath(e.key)
    }
  }

  const showLogoutModal = (): void => {
    setLogoutModalVisible(true)
  }

  return (
    <Space direction="vertical" style={{ width: "100%", overflowX: "hidden" }}>
      <Modal
        title={t("MODALS.LOGOUT")}
        open={logoutModalVisible}
        onOk={() => {
          removeCookie("role")
          removeCookie("token")
          removeCookie("user_type")
          removeCookie("lang")
          window.location.href = `https://adn.scroogefrog.com/?page=sing_in&lang=${
            lang || "en"
          }&logout=true`
        }}
        okText={t("BUTTONS.LOGOUT")}
        centered
        width={300}
        onCancel={() => setLogoutModalVisible(false)}
        okButtonProps={{ danger: true }}
      />
      <Layout style={{ minHeight: "100vh" }}>
        <AuthLayoutHeader
          t={t}
          role={role}
          lang={lang}
          balance={data?.balance || "0.00"}
          firstLetterOfName={data?.name ? data?.name.charAt(0) : ""}
          showLogoutModal={showLogoutModal}
          setCurrentPath={setCurrentPath}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          theme={theme}
          isMobile={isMobile}
        />
        <Layout hasSider style={{ position: "relative" }}>
          <Sider
            theme="light"
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            breakpoint="lg"
            collapsedWidth={collapsedWidth}
            width={214}
            zeroWidthTriggerStyle={{
              display: "none",
            }}
            style={
              isMobile
                ? {
                    position: "absolute",
                    zIndex: 10,
                    height: "100%",
                  }
                : {}
            }
            className={styles.sider}
            onBreakpoint={(broken) => {
              setCollapsedWidth(broken && isMobile ? "0" : "50")
            }}
          >
            <Space
              align="center"
              style={{ display: "flex", justifyContent: "center" }}
            ></Space>
            <Menu
              defaultSelectedKeys={["1"]}
              selectedKeys={[currentPath]}
              mode="inline"
              items={getSidebarMenu(role, t, lang, moderationsCountData)}
              onClick={handleMenuClick}
            />
            <div
              style={{
                display: "flex",
                margin: "20px auto",
                justifyContent: "center",
              }}
            >
              <StdThemeSwitcher theme={theme} setTheme={setTheme} />
            </div>
          </Sider>
          <Layout>
            <Content style={{ margin: "20px 16px" }} className={styles.content}>
              {children}
            </Content>

            <AuthLayoutFooter t={t} lang={lang} />
          </Layout>
        </Layout>
      </Layout>
    </Space>
  )
}

const getSidebarMenu = (
  role: string | undefined,
  t: TFunction<"translation", undefined>,
  lang: string | undefined,
  moderationCount: ModerationCount | undefined,
): ItemType<MenuItemType>[] => {
  if (role === "advertiser") {
    return [
      {
        key: "account",
        label: <NavLink to="/advertiser/account">{t("ACCOUNT")}</NavLink>,
        icon: <UserOutlined />,
      },
      {
        key: "statistics",
        label: (
          <NavLink to="/advertiser/statistics" style={{ color: "inherit" }}>
            {t("STATISTICS")}
          </NavLink>
        ),
        icon: <BarChartOutlined />,
      },
      {
        key: "campaigns",
        label: <NavLink to="/advertiser/campaigns">{t("CAMPAIGNS")}</NavLink>,
        icon: <ProfileOutlined />,
      },
      {
        key: "moderation",
        label: (
          <span>
            {t("MODERATION")}{" "}
            <Badge
              count={moderationCount?.mod_total_count || 0}
              style={{
                backgroundColor: "#d89614",
                color: "#ffffff",
                boxShadow: "none",
              }}
              offset={[0, 0]}
            />
          </span>
        ),
        icon: <FolderOpenOutlined />,
        children: [
          {
            key: "offers",
            label: (
              <NavLink to="/advertiser/moderation/offers">
                {t("OFFERS")}
                <Badge
                  count={moderationCount?.offers_count || 0}
                  style={{
                    backgroundColor: "#d89614",
                    color: "#ffffff",
                    boxShadow: "none",
                  }}
                  offset={[5, 0]}
                />
              </NavLink>
            ),
            icon: <FileOutlined />,
          },
          {
            key: "creatives",
            label: (
              <NavLink to="/advertiser/moderation/creatives">
                {t("CREATIVES")}
                <Badge
                  count={moderationCount?.teasers_count || 0}
                  style={{
                    backgroundColor: "#d89614",
                    color: "#ffffff",
                    boxShadow: "none",
                  }}
                  offset={[5, 0]}
                />
              </NavLink>
            ),
            icon: <FileImageOutlined />,
          },
          {
            key: "texts",
            label: (
              <NavLink to="/advertiser/moderation/texts">
                {t("TEXTS")}
                <Badge
                  count={moderationCount?.texts_count || 0}
                  style={{
                    backgroundColor: "#d89614",
                    color: "#ffffff",
                    boxShadow: "none",
                  }}
                  offset={[5, 0]}
                />
              </NavLink>
            ),
            icon: <FileTextOutlined />,
          },
        ],
      },
      {
        key: "graphs",
        label: <NavLink to="/advertiser/graphs">{t("GRAPH")}</NavLink>,
        icon: <AreaChartOutlined />,
      },
      {
        key: "payments",
        label: <NavLink to="/advertiser/payments">{t("PAYMENTS")}</NavLink>,
        icon: <DollarOutlined />,
      },
      {
        key: "tools",
        label: t("TOOLS"),
        icon: <ToolOutlined />,
        children: [
          {
            key: "geolps",
            label: (
              <NavLink to="/advertiser/tools/geolps">
                {t("GEO_STATISTICS")}
              </NavLink>
            ),
            icon: <EnvironmentOutlined />,
          },
          {
            key: "devices",
            label: (
              <NavLink to="/advertiser/tools/devices">
                {t("DEVICES_STATISTICS")}
              </NavLink>
            ),
            icon: <AndroidOutlined />,
          },
          {
            key: "domains",
            label: (
              <NavLink to="/advertiser/tools/domains">
                {t("OFFER_DOMAINS")}
              </NavLink>
            ),
            icon: <BugOutlined />,
          },
        ],
      },
      {
        key: "contacts",
        label: (
          <NavLink to={"/advertiser/contacts"}>
            {t("CONTACTS.CONTACTS")}
          </NavLink>
        ),
        icon: <ContactsOutlined />,
      },
      {
        key: "help",
        label: (
          <NavLink
            to={`https://adn.scroogefrog.com/?page=help&lang=${
              lang || "en"
            }&cat_id=6`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("HELP")}
          </NavLink>
        ),
        icon: <InfoCircleOutlined />,
      },
    ]
  } else {
    return [
      {
        key: "account",
        label: <NavLink to={"/publisher/account"}>{t("ACCOUNT")}</NavLink>,
        icon: <UserOutlined />,
      },
      {
        key: "statistics",
        label: (
          <NavLink to={"/publisher/statistics"}>{t("STATISTICS")}</NavLink>
        ),
        icon: <BarChartOutlined />,
      },
      {
        key: "widgets",
        label: <NavLink to={"/publisher/widgets"}>{t("WIDGETS")}</NavLink>,
        icon: <ControlOutlined />,
      },
      {
        key: "moderation",
        label: (
          <NavLink to={"/publisher/moderation"}>
            {t("MODERATION")}{" "}
            <Badge
              count={moderationCount?.mod_total_count || 0}
              style={{
                backgroundColor: "#d89614",
                color: "#ffffff",
                boxShadow: "none",
              }}
              offset={[0, 0]}
            />
          </NavLink>
        ),
        icon: <FileSearchOutlined />,
      },
      {
        key: "graphs",
        label: <NavLink to={"/publisher/graphs"}>{t("GRAPH")}</NavLink>,
        icon: <AreaChartOutlined />,
      },
      {
        key: "offers",
        label: <NavLink to={"/publisher/offers"}>{t("OFFER_DOMAINS")}</NavLink>,
        icon: <BugOutlined />,
      },
      {
        key: "payments",
        label: <NavLink to={"/publisher/payments"}>{t("PAYMENTS")}</NavLink>,
        icon: <DollarOutlined />,
      },
      {
        key: "contacts",
        label: (
          <NavLink to={"/publisher/contacts"}>{t("CONTACTS.CONTACTS")}</NavLink>
        ),
        icon: <ContactsOutlined />,
      },
      {
        key: "help",
        label: (
          <NavLink
            to={`https://adn.scroogefrog.com/?page=help&lang=${
              lang || "en"
            }&cat_id=1`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("HELP")}
          </NavLink>
        ),
        icon: <InfoCircleOutlined />,
      },
    ]
  }
}

const getCurrentPath = (path: string): string => {
  if (path.includes("account")) {
    return "account"
  } else if (path.includes("statistics")) {
    return "statistics"
  } else if (path.includes("widgets")) {
    return "widgets"
  } else if (path.includes("campaigns")) {
    return "campaigns"
  } else if (path.includes("graphs")) {
    return "graphs"
  } else if (path.includes("payments")) {
    return "payments"
  } else if (path.includes("geolps")) {
    return "geolps"
  } else if (path.includes("devices")) {
    return "devices"
  } else if (path.includes("offers")) {
    return "offers"
  } else if (path.includes("domains")) {
    return "domains"
  } else if (path.includes("creatives")) {
    return "creatives"
  } else if (path.includes("texts")) {
    return "texts"
  } else if (path.includes("contacts")) {
    return "contacts"
  } else if (path.includes("moderation")) {
    return "moderation"
  } else {
    return "account"
  }
}
