import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useLocation } from "react-router-dom"
import { Placeholders } from "@/components/common/UserVerify/Placeholders"
import { sleep } from "@/utils/sleep"
import { removeCookie, setCookie } from "@/utils/cookies"
import { Roles } from "@/definitions"
import styles from "@/components/common/UserVerify/UserVerify.module.css"

enum VerificationState {
  IsLoading = "loading",
  IsSuccess = "success",
  IsError = "error",
}

export const UserVerify = (): JSX.Element => {
  const { t, i18n } = useTranslation()

  const location = useLocation()
  const navigate = useNavigate()

  const [verification, setVerification] = useState<VerificationState>(
    VerificationState.IsLoading,
  )

  const queryParameters = location.search

  const user_type = extractParamValue(queryParameters, "user_type")
  const token = extractParamValue(queryParameters, "token")
  const lang = extractParamValue(queryParameters, "lang")

  const role = user_type === "1" ? Roles.Publisher : Roles.Advertiser

  useEffect(() => {
    if (lang === "es") {
      setCookie("lang", "es")
      i18n.changeLanguage("es")
    }

    if (lang === "en") {
      setCookie("lang", "en")
      i18n.changeLanguage("en")
    }

    if (user_type && token && lang) {
      sleep(2000).then(() => {
        setVerification(VerificationState.IsSuccess)

        setCookie("role", role)
        setCookie("user_type", user_type)
        setCookie("token", token)

        sleep(3500).then(() => {
          navigate(
            role === Roles.Publisher
              ? "/publisher/statistics"
              : "/advertiser/statistics",
          )
        })
      })
    } else {
      sleep(2000).then(() => {
        setVerification(VerificationState.IsError)

        removeCookie("role")
        removeCookie("user_type")
        removeCookie("token")
        removeCookie("lang")

        sleep(3500).then(() => {
          window.location.href = `https://adn.scroogefrog.com/?page=sing_in&lang=${
            lang || "en"
          }&logout=true`
        })
      })
    }
  }, [role, token, lang, user_type])

  return (
    <div className={styles.container}>
      {verification === VerificationState.IsLoading && (
        <Placeholders.Loading t={t} />
      )}
      {verification === VerificationState.IsSuccess && (
        <Placeholders.Success t={t} />
      )}
      {verification === VerificationState.IsError && (
        <Placeholders.Failed t={t} />
      )}
    </div>
  )
}

const extractParamValue = (input: string, paramName: string): string | null => {
  const pattern: RegExp = new RegExp(`${paramName}=([^&]+)`)
  const match: RegExpExecArray | null = pattern.exec(input)
  return match ? match[1] : null
}
