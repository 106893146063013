import {
  AdvertiserCampaignOffer,
  AdvertiserCampaignOfferUpdate,
} from "@/definitions"
import { Form, Input, Button } from "antd"
import type { FormProps } from "antd"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useUpdateAdvCampaignOfferMutation } from "@/redux/slices/advertiserCampaignsApi"
import { StdStateSwitcher } from "@/components/common/Std/Form/StateSwitcher"
import { StdModerationStatus } from "@/components/common/Std/Form/ModerationStatus"

const { Item } = Form
const { TextArea } = Input

interface AdvCampaignOfferEditProps {
  offer: AdvertiserCampaignOffer | undefined
}

export const AdvCampaignOfferEdit = ({
  offer,
}: AdvCampaignOfferEditProps): JSX.Element => {
  const { t } = useTranslation()
  const { campaignId, offerId } = useParams()

  const [isActive, setIsActive] = useState<boolean>(false)

  const [updateOffer] = useUpdateAdvCampaignOfferMutation()

  const onFinish: FormProps<AdvertiserCampaignOfferUpdate>["onFinish"] = (
    values,
  ) => {
    const newValues = {
      ...values,
      campaign_id: campaignId,
      offer_id: offerId,
      status: isActive ? "on" : "off",
    }

    updateOffer(newValues)
  }

  useEffect(() => {
    if (offer?.state === "on") setIsActive(true)
  }, [offer])

  return (
    <Form
      name="edit-offer"
      layout="vertical"
      labelCol={{ span: 16 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 450 }}
      onFinish={onFinish}
      autoComplete="off"
      initialValues={{
        name: offer?.name,
        url: offer?.url,
        user_comments: offer?.user_comments,
        state: offer?.state,
      }}
    >
      <Item
        name="name"
        label={t("ADV.CAMPAIGNS.OFFER_NAME")}
        rules={[{ required: true, message: t("ADV.CAMPAIGNS.OFFER_NAME_REQ") }]}
      >
        <Input placeholder={t("ADV.CAMPAIGNS.OFFER_NAME")} />
      </Item>
      <Item
        name="url"
        label={t("ADV.CAMPAIGNS.URL")}
        rules={[{ required: true, message: t("ADV.CAMPAIGNS.URL_REQ") }]}
      >
        <Input placeholder={t("ADV.CAMPAIGNS.URL")} />
      </Item>
      <Item name="user_comments" label={t("ADV.CAMPAIGNS.USER_COMMENTS")}>
        <TextArea rows={4} placeholder={t("ADV.CAMPAIGNS.USER_COMMENTS")} />
      </Item>
      <StdModerationStatus status={offer?.moderation_status} t={t} />
      <Item name="state">
        <StdStateSwitcher t={t} isActive={isActive} setIsActive={setIsActive} />
      </Item>
      <Item>
        <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
          {t("ADV.CAMPAIGNS.SAVE_BUTTON")}
        </Button>
      </Item>
    </Form>
  )
}
