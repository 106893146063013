import { useState } from "react"
import { Typography, Table, Flex } from "antd"
import { useTranslation } from "react-i18next"
import { useGetAdvModerationsQuery } from "@/redux/slices/advertiserApi"
import { AdvModerationFilters } from "@/components/advertiser/Moderation/ModerationFilters"
import { StdErrorMessage } from "@/components/common/Std/ErrorMessage"
import { Link } from "react-router-dom"
import { DEFAULT_PAGINATION_RANGE } from "@/constants"
import { StdPaginationRange } from "@/components/common/Std/Selects/PaginationRange"
import { StdColumnsDropdown } from "@/components/common/Std/Dropdowns/ColumnsDropdown"
import { ColumnsType } from "antd/es/table"
import { StdModerationStatusText } from "@/components/common/Std/ModerationStatusText"
import { AdvertiserModeration, ModerationStatuses } from "@/definitions"
import dayjs from "dayjs"

const { Title } = Typography

const MODERATION_TYPE_OFFERS: string = "offers"
const TABLE_COLUMNS_TITLES: string[] = [
  "id",
  "last_update_time",
  "ms_name",
  "cmp_name",
  "oname",
  "user_comments",
  "reject_reason",
]

export const AdvModerationOffers = (): JSX.Element => {
  const { t } = useTranslation()

  const [campaignId, setCampaignId] = useState<string>("")
  const [statusId, setStatusId] = useState<string>("-1")
  const [startDate, setStartDate] = useState<string>("")
  const [endDate, setEndDate] = useState<string>("")
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [paginationRange, setPaginationRange] = useState<number>(
    DEFAULT_PAGINATION_RANGE,
  )
  const [selectedColumns, setSelectedColumns] =
    useState<string[]>(TABLE_COLUMNS_TITLES)

  const { data, isLoading, isFetching, isError } = useGetAdvModerationsQuery(
    `type=${MODERATION_TYPE_OFFERS}&ms_id=${statusId}&from=${startDate}&to=${endDate}&per_page=${300}&id=${campaignId}`,
  )

  const { data: allModerationData } = useGetAdvModerationsQuery(
    `type=${MODERATION_TYPE_OFFERS}&ms_id=${statusId}&from=${startDate}&to=${endDate}&per_page=${300}`,
  )

  const handlePageChange = (page: number): void => {
    setCurrentPage(page)
  }

  if (isError) return <StdErrorMessage>Error...</StdErrorMessage>

  const TABLE_COLUMNS: ColumnsType<AdvertiserModeration> = [
    {
      title: t("ADV.MODERATION.ID"),
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => Number(a.id) - Number(b.id),
    },
    {
      title: t("ADV.MODERATION.LAST_UPDATE_TIME"),
      dataIndex: "last_update_time",
      key: "last_update_time",
      sorter: (a, b) => Number(a.last_update_time) - Number(b.last_update_time),
      defaultSortOrder: "descend",
      render: (timestamp) => (
        <div>{dayjs.unix(timestamp).format("YYYY-MM-DD HH:mm:ss")}</div>
      ),
    },
    {
      title: t("ADV.MODERATION.MS_NAME"),
      dataIndex: "ms_name",
      key: "ms_name",
      sorter: (a, b) => a.ms_name.localeCompare(b.ms_name),
      render: (status: ModerationStatuses) => (
        <StdModerationStatusText status={status} />
      ),
    },
    {
      title: t("ADV.MODERATION.CMP_NAME"),
      dataIndex: "cmp_name",
      key: "cmp_name",
      sorter: (a, b) => a.cmp_name.localeCompare(b.cmp_name),
    },
    {
      title: t("ADV.MODERATION.ONAME"),
      dataIndex: "oname",
      key: "oname",
      sorter: (a, b) => a.oname.localeCompare(b.oname),
      render: (text: string, record: AdvertiserModeration) => (
        <Link target="_blank" to={record.url} rel="noopener noreferrer">
          {text}
        </Link>
      ),
    },
    {
      title: t("ADV.MODERATION.USER_COMMENTS"),
      dataIndex: "user_comments",
      key: "user_comments",
      width: 200,
      sorter: (a, b) => a.user_comments.localeCompare(b.user_comments),
      render: (value) => <span>{value.length === 0 ? "-" : value}</span>,
    },
    {
      title: t("ADV.MODERATION.REJECT_REASON"),
      dataIndex: "reject_reason",
      key: "reject_reason",
      width: 200,
      sorter: (a, b) => a.reject_reason.localeCompare(b.reject_reason),
      render: (value) => <span>{value.length === 0 ? "-" : value}</span>,
    },
  ]

  return (
    <Flex vertical gap="large">
      <Title level={3}>{t("ADV.MODERATION.OFFERS")}</Title>

      <Flex justify="space-between" gap="large" wrap="wrap" align="flex-end">
        <AdvModerationFilters
          t={t}
          data={allModerationData}
          setCampaignId={setCampaignId}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          setStatusId={setStatusId}
        />
        <Flex gap="large">
          <StdPaginationRange
            t={t}
            paginationRange={paginationRange}
            setPaginationRange={setPaginationRange}
          />
          <StdColumnsDropdown
            t={t}
            namespace="ADV.MODERATION"
            options={TABLE_COLUMNS_TITLES}
            selectedOptions={selectedColumns}
            setSelectedOptions={setSelectedColumns}
          />
        </Flex>
      </Flex>

      <div style={{ position: "relative" }}>
        <Table
          scroll={{ x: "max-content" }}
          dataSource={data}
          loading={isLoading || isFetching}
          rowKey="id"
          showSorterTooltip={false}
          sortDirections={["ascend", "descend", "ascend"]}
          columns={TABLE_COLUMNS.filter((col: any) =>
            selectedColumns.includes(col?.dataIndex),
          )}
          pagination={{
            position: ["bottomCenter"],
            current: currentPage,
            pageSize: paginationRange,
            showSizeChanger: false,
            onChange: handlePageChange,
            total: data?.length,
            showTotal: (total, range) => {
              if (range[1] > 0) {
                return (
                  <span className="paginationShowTotal">
                    {t("PAGINATION.SHOW_TOTAL", {
                      firstNumber: range[0],
                      secondNumber: range[1],
                      total: total,
                    })}
                  </span>
                )
              }
            },
          }}
        />
      </div>
    </Flex>
  )
}
