import { useState, useEffect, Key } from "react"
import { useDraggableScroll } from "@/hooks/useDraggableScroll"
import {
  useGetPubStatisticsQuery,
  useLazyGetPubStatisticsQuery,
} from "@/redux/slices/publisherApi"
import { Typography, Table, Flex } from "antd"
import { useTranslation } from "react-i18next"
import { StdErrorMessage } from "@/components/common/Std/ErrorMessage"
import { StdDownloadDropdown } from "@/components/common/Std/Dropdowns/DownloadDropdown"
import { StdPaginationRange } from "@/components/common/Std/Selects/PaginationRange"
import { StdColumnsDropdown } from "@/components/common/Std/Dropdowns/ColumnsDropdown"
import { PubStatisticsTableSummary } from "@/components/publisher/Statistics/StatisticsTableSummary"
import { PubStatisticsFilters } from "@/components/publisher/Statistics/StatisticsFilters"
import { PubStatisticsTableRowText } from "@/components/publisher/Statistics/StatisticsTableRowText"
import { CSVLink } from "react-csv"
import { DEFAULT_PAGINATION_RANGE } from "@/constants"
import { PublisherStatisticsItem } from "@/definitions"
import { composeQueryParamsString } from "@/utils/composeQueryParamsString"
import {
  convertToCsv,
  getFilteredData,
} from "@/components/publisher/Statistics/utils"
import { ColumnsType } from "antd/es/table"
import { getValueByBoolean } from "@/utils/getValueByBoolean"
import { valueToFixed } from "@/utils/valueToFixed"
import { reducedValue } from "@/utils/reducedValue"
import dayjs from "dayjs"

const { Title } = Typography

export const VISIBILITY_MIN_GOOD_PERSENTAGE = 30
const MIN_GOOD_PERSENTAGE = 95
const MOBILE_WIDTH = 768
const DEFAULT_COLUMN_WIDTH = 150

const TABLE_COLUMNS_TITLES: string[] = [
  "date",
  "source_id",
  "source",
  "pad",
  "pics_per_block",
  "blind_pics_views",
  "blind_block_views",
  "pics_views",
  "block_views",
  "visibility",
  "clicks",
  "pics_ctr",
  "block_ctr",
  "pics_vctr",
  "block_vctr",
  "target_geo",
  "mobile_devices",
  "desktop_devices",
  "block_cpm",
  "block_vcpm",
  "pics_cpc",
  "debit_cpc",
]
const DEFAULT_TABLE_COLUMNS_TITLES: string[] = [
  "source_id",
  "source",
  "pad",
  "blind_block_views",
  "block_views",
  "visibility",
  "clicks",
  "block_ctr",
  "block_vctr",
  "target_geo",
  "mobile_devices",
  "desktop_devices",
  "block_cpm",
  "block_vcpm",
  "pics_cpc",
  "debit_cpc",
]

export const PubStatistics = (): JSX.Element => {
  const { t } = useTranslation()

  const isMobile = window.innerWidth <= MOBILE_WIDTH

  const [startDate, setStartDate] = useState<string>(
    dayjs().format("YYYY-MM-DD"),
  )
  const [endDate, setEndDate] = useState<string>(dayjs().format("YYYY-MM-DD"))
  const [widgetId, setWidgetId] = useState<string>("")
  const [websitetId, setWebsiteId] = useState<string>("")
  const [dateColumn, setDateColumns] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [paginationRange, setPaginationRange] = useState<number>(
    DEFAULT_PAGINATION_RANGE,
  )
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    DEFAULT_TABLE_COLUMNS_TITLES,
  )
  const [selectedRows, setSelectedRows] = useState<any[]>([])
  const [selectedRowsKeys, setSelectedRowsKeys] = useState<Key[]>([])

  const [fetchData, { data, isLoading, isError }] =
    useLazyGetPubStatisticsQuery()

  const { data: selectsData } = useGetPubStatisticsQuery(
    `from=${startDate}&to=${endDate}`,
  )

  useDraggableScroll(".ant-table-content")

  useEffect(() => {
    fetchData(
      composeQueryParamsString({
        from: startDate,
        to: endDate,
        pad_id: websitetId,
        source_id: widgetId,
        by_dates: getValueByBoolean(dateColumn),
      }),
    )
  }, [startDate, endDate, websitetId, widgetId, dateColumn])

  useEffect(() => {
    if (selectedColumns.includes("date")) {
      setDateColumns(true)
    } else {
      setDateColumns(false)
    }
  }, [selectedColumns])

  const handlePageChange = (page: number): void => {
    setCurrentPage(page)
  }

  const onSelectedRowsChange = (
    newSelectedRowKeys: Key[],
    selectedRows: any,
  ): void => {
    setSelectedRowsKeys(newSelectedRowKeys)
    setSelectedRows(selectedRows)
  }

  const onChangeWidgetId = (value: string): void => {
    if (value === "all") {
      setWidgetId("")
      return
    }

    const widgetId = value.split(" - ")[0]

    setWidgetId(widgetId)
  }

  const onChangeWebsiteId = (value: string): void => {
    if (value === "all") {
      setWebsiteId("")
      return
    }

    const websitetId = value.split(" - ")[0]

    setWebsiteId(websitetId)
  }

  if (isError) {
    return <StdErrorMessage>Error...</StdErrorMessage>
  }

  const summaryData = {
    views: data?.sum?.pics_views_sum || "0",
    clicks: data?.sum?.clicks_sum || "0",
    blockViews: data?.sum?.block_views_sum || "0",
    blindViews: data?.sum?.blind_pics_views_sum || "0",
    debitCpc: valueToFixed(data?.sum?.debit_cpc_sum, 4),
    blindBlockViews: data?.sum?.blind_widget_views_sum || "0",
    visibility: data?.sum?.visibility_sum || "0",
    blockCTR: data?.sum?.widget_ctr_sum || "0",
    blockVCTR: data?.sum?.widget_vctr_sum || "0",
  }

  const filteredData = getFilteredData(data?.data)

  const csvData = convertToCsv(
    filteredData,
    selectedColumns,
    TABLE_COLUMNS_TITLES,
    t,
    summaryData,
  )

  const selectedRowsSummaryData = {
    views: reducedValue(selectedRows, "views"),
    clicks: reducedValue(selectedRows, "clicks"),
    blockViews: reducedValue(selectedRows, "block_views"),
    blindViews: reducedValue(selectedRows, "blind_views"),
    debitCpc: valueToFixed(reducedValue(selectedRows, "debit_cpc"), 4),
    blindBlockViews: reducedValue(selectedRows, "blind_block_views"),
    visibility: reducedValue(selectedRows, "visibility"),
    blockCTR: reducedValue(selectedRows, "block_ctr"),
    blockVCTR: reducedValue(selectedRows, "block_vctr"),
  }

  const selectedRowsCsvData = convertToCsv(
    selectedRows,
    selectedColumns,
    TABLE_COLUMNS_TITLES,
    t,
    selectedRowsSummaryData,
  )

  const TABLE_COLUMNS: ColumnsType<PublisherStatisticsItem> = [
    {
      title: t("PUB.STATISTICS.DATE"),
      key: "date",
      dataIndex: "date",
      width: 107,
      fixed: !isMobile ? "left" : false,
      render: (value) => (
        <span style={{ whiteSpace: "nowrap" }}>{!value ? "-" : value}</span>
      ),
      sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    },
    {
      title: t("PUB.STATISTICS.SOURCE_ID"),
      key: "source_id",
      dataIndex: "source_id",
      width: 70,
      defaultSortOrder: "descend",
      sorter: (a, b) => Number(a.source_id) - Number(b.source_id),
      fixed: !isMobile ? "left" : false,
    },
    {
      title: t("PUB.STATISTICS.SOURCE"),
      key: "source",
      dataIndex: "source",
      width: DEFAULT_COLUMN_WIDTH,
      sorter: (a, b) => a.source.localeCompare(b.source),
    },
    {
      title: t("PUB.STATISTICS.PAD"),
      key: "pad",
      dataIndex: "pad",
      width: DEFAULT_COLUMN_WIDTH,
      render: (value) => (!value ? "-" : value),
      sorter: (a, b) => a.pad.localeCompare(b.pad),
    },
    {
      title: t("PUB.STATISTICS.PICS_PER_BLOCK"),
      key: "pics_per_block",
      dataIndex: "pics_per_block",
      width: DEFAULT_COLUMN_WIDTH,
      sorter: (a, b) => Number(a.pics_per_block) - Number(b.pics_per_block),
    },
    {
      title: t("PUB.STATISTICS.BLIND_PICS_VIEWS"),
      key: "blind_pics_views",
      dataIndex: "blind_pics_views",
      width: DEFAULT_COLUMN_WIDTH,
      sorter: (a, b) => Number(a.blind_pics_views) - Number(b.blind_pics_views),
    },
    {
      title: t("PUB.STATISTICS.BLIND_BLOCK_VIEWS"),
      key: "blind_block_views",
      dataIndex: "blind_block_views",
      width: DEFAULT_COLUMN_WIDTH,
      sorter: (a, b) =>
        Number(a.blind_block_views) - Number(b.blind_block_views),
    },
    {
      title: t("PUB.STATISTICS.PICS_VIEWS"),
      key: "pics_views",
      dataIndex: "pics_views",
      width: DEFAULT_COLUMN_WIDTH,
      sorter: (a, b) => Number(a.pics_views) - Number(b.pics_views),
    },
    {
      title: t("PUB.STATISTICS.BLOCK_VIEWS"),
      key: "block_views",
      dataIndex: "block_views",
      width: DEFAULT_COLUMN_WIDTH,
      sorter: (a, b) => Number(a.block_views) - Number(b.block_views),
    },
    {
      title: t("PUB.STATISTICS.VISIBILITY"),
      key: "visibility",
      dataIndex: "visibility",
      width: DEFAULT_COLUMN_WIDTH,
      sorter: (a, b) => Number(a.visibility) - Number(b.visibility),
      render: (value) => (
        <PubStatisticsTableRowText
          value={value}
          persentage={VISIBILITY_MIN_GOOD_PERSENTAGE}
        />
      ),
    },
    {
      title: t("PUB.STATISTICS.CLICKS"),
      key: "clicks",
      dataIndex: "clicks",
      width: 104,
      sorter: (a, b) => Number(a.clicks) - Number(b.clicks),
    },
    {
      title: t("PUB.STATISTICS.PICS_CTR"),
      key: "pics_ctr",
      dataIndex: "pics_ctr",
      width: DEFAULT_COLUMN_WIDTH,
      sorter: (a, b) => Number(a.pics_ctr) - Number(b.pics_ctr),
    },
    {
      title: t("PUB.STATISTICS.BLOCK_CTR"),
      key: "block_ctr",
      dataIndex: "block_ctr",
      width: 200,
      sorter: (a, b) => Number(a.block_ctr) - Number(b.block_ctr),
    },
    {
      title: t("PUB.STATISTICS.PICS_VCTR"),
      key: "pics_vctr",
      dataIndex: "pics_vctr",
      width: DEFAULT_COLUMN_WIDTH,
      sorter: (a, b) => Number(a.pics_vctr) - Number(b.pics_vctr),
    },
    {
      title: t("PUB.STATISTICS.BLOCK_VCTR"),
      key: "block_vctr",
      dataIndex: "block_vctr",
      width: 200,
      sorter: (a, b) => Number(a.block_vctr) - Number(b.block_vctr),
    },
    {
      title: t("PUB.STATISTICS.TARGET_GEO"),
      key: "target_geo",
      dataIndex: "target_geo",
      width: 170,
      sorter: (a, b) => Number(a.target_geo) - Number(b.target_geo),
      render: (value) => (
        <PubStatisticsTableRowText
          persentage={MIN_GOOD_PERSENTAGE}
          value={value}
        />
      ),
    },
    {
      title: t("PUB.STATISTICS.MOBILE_DEVICES"),
      key: "mobile_devices",
      dataIndex: "mobile_devices",
      width: DEFAULT_COLUMN_WIDTH,
      sorter: (a, b) => Number(a.mobile_devices) - Number(b.mobile_devices),
      render: (value, record) =>
        record.device_type === "Mobile" ? (
          <PubStatisticsTableRowText
            value={value}
            persentage={MIN_GOOD_PERSENTAGE}
          />
        ) : (
          value
        ),
    },
    {
      title: t("PUB.STATISTICS.DESKTOP_DEVICES"),
      key: "desktop_devices",
      dataIndex: "desktop_devices",
      width: DEFAULT_COLUMN_WIDTH,
      sorter: (a, b) => Number(a.desktop_devices) - Number(b.desktop_devices),
      render: (value, record) =>
        record.device_type === "Desktop" ? (
          <PubStatisticsTableRowText
            persentage={MIN_GOOD_PERSENTAGE}
            value={value}
          />
        ) : (
          value
        ),
    },
    {
      title: t("PUB.STATISTICS.BLOCK_CPM"),
      key: "block_cpm",
      dataIndex: "block_cpm",
      width: 200,
      sorter: (a, b) => Number(a.block_cpm) - Number(b.block_cpm),
    },
    {
      title: t("PUB.STATISTICS.BLOCK_VCPM"),
      key: "block_vcpm",
      dataIndex: "block_vcpm",
      width: 200,
      sorter: (a, b) => Number(a.block_vcpm) - Number(b.block_vcpm),
    },
    {
      title: t("PUB.STATISTICS.PICS_CPC"),
      key: "pics_cpc",
      dataIndex: "pics_cpc",
      width: DEFAULT_COLUMN_WIDTH,
      sorter: (a, b) => Number(a.pics_cpc) - Number(b.pics_cpc),
    },
    {
      title: t("PUB.STATISTICS.DEBIT_CPC"),
      key: "debit_cpc",
      dataIndex: "debit_cpc",
      width: 200,
      sorter: (a, b) => Number(a.debit_cpc) - Number(b.debit_cpc),
    },
  ]

  const websitesData = selectsData?.pads?.map((item) => ({
    label: item.pad_name,
    value: `${item.pad_id} - ${item.pad_name}`,
  }))

  const widgetsData = selectsData?.sources?.map((item) => ({
    label: `${item.source_id} ${item.source_name}`,
    value: `${item.source_id} - ${item.source_name}`,
  }))

  return (
    <Flex vertical gap="large">
      <Flex vertical gap="large" style={{ width: "100%" }}>
        <Title level={3}>{t("PUB.STATISTICS.STATISTICS")}</Title>

        <PubStatisticsFilters
          t={t}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          onChangeWebsiteId={onChangeWebsiteId}
          onChangeWidgetId={onChangeWidgetId}
          websitesData={websitesData}
          widgetsData={widgetsData}
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
          setDateColumns={setDateColumns}
        />

        <Flex gap="large" wrap="wrap" justify="space-between" align="center">
          <Title level={5}>{t("PUB.STATISTICS.GENERAL_STATISTICS")}</Title>
          <Flex gap="large" wrap="wrap">
            <StdPaginationRange
              t={t}
              paginationRange={paginationRange}
              setPaginationRange={setPaginationRange}
            />
            <StdColumnsDropdown
              t={t}
              namespace="PUB.STATISTICS.DOWNLOAD"
              options={TABLE_COLUMNS_TITLES}
              selectedOptions={selectedColumns}
              setSelectedOptions={setSelectedColumns}
            />
            <StdDownloadDropdown
              items={[
                {
                  key: "csv",
                  label: (
                    <CSVLink
                      filename="statistics-csv.csv"
                      data={
                        selectedRows?.length > 0 ? selectedRowsCsvData : csvData
                      }
                    >
                      {t("DOWNLOAD.CSV")}
                    </CSVLink>
                  ),
                },
              ]}
            />
          </Flex>
        </Flex>
      </Flex>

      <div style={{ position: "relative" }}>
        <Table
          style={{ whiteSpace: "pre-line" }}
          loading={isLoading}
          scroll={{ x: DEFAULT_COLUMN_WIDTH * selectedColumns?.length }}
          dataSource={filteredData}
          rowKey="row_id"
          showSorterTooltip={false}
          sortDirections={["ascend", "descend", "ascend"]}
          columns={TABLE_COLUMNS.filter((col: any) =>
            selectedColumns.includes(col?.dataIndex),
          )}
          rowSelection={{
            selectedRowKeys: selectedRowsKeys,
            onChange: onSelectedRowsChange,
            columnWidth: 70,
          }}
          summary={() => {
            if (!!data?.data?.length) {
              return (
                <PubStatisticsTableSummary
                  selectedColumns={selectedColumns}
                  defaultColumns={TABLE_COLUMNS_TITLES}
                  views={summaryData.views}
                  clicks={summaryData.clicks}
                  blockViews={summaryData.blockViews}
                  blindViews={summaryData.blindViews}
                  debitCpc={summaryData.debitCpc}
                  blindBlockViews={summaryData.blindBlockViews}
                  visibility={summaryData.visibility}
                  blockCTR={summaryData.blockCTR}
                  blockVCTR={summaryData.blockVCTR}
                />
              )
            }
          }}
          pagination={{
            position: ["bottomCenter"],
            current: currentPage,
            pageSize: paginationRange,
            showSizeChanger: false,
            onChange: handlePageChange,
            total: data?.data?.length,
            showTotal: (total, range) => {
              if (range[1] > 0) {
                return (
                  <span className="paginationShowTotal">
                    {t("PAGINATION.SHOW_TOTAL", {
                      firstNumber: range[0],
                      secondNumber: range[1],
                      total: total,
                    })}
                  </span>
                )
              }
            },
          }}
        />
      </div>
    </Flex>
  )
}
