export enum Roles {
  Publisher = "publisher",
  Advertiser = "advertiser",
}

export enum ModerationStatuses {
  Approved = "Approved",
  OnModeration = "On Moderation",
  Rejected = "Rejected",
  All = "All",
}

export interface ApiResponseOk {
  state: string
}

export interface ApiResponseError {
  state: string
  data: string
}

export interface Country {
  continent_code: string
  continent_name: string
  country_name: string
  country_iso_code: string
}

export interface PaymentType {
  id: string
  name: string
}
export interface PaymentModel {
  id: string
  name: string
}

export interface ModerationFilter {
  ms_id: string
  ms_name: ModerationStatuses
}
export interface ModerationCount {
  mod_total_count?: number
  sources_count?: number
  offers_count?: number
  teasers_count?: number
  texts_count?: number
}

export interface ModerationCountQueries {
  paramsString: string
  role: string
}

export interface DeviceType {
  id: string
  device_type: string
}
