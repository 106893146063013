import { AdvCampaignOfferCreativeUploadImage } from "@/components/advertiser/Campaigns/Offers/Creatives/CreativeUploadImage"
import { AdvertiserCampaignOfferCreativeCreate } from "@/definitions"
import {
  useCreateAdvCampaignOfferCreativeMutation,
  useGetAdvCampaignOfferCreativeTypesQuery,
} from "@/redux/slices/advertiserCampaignsApi"
import { Button, Form, Image, Select, Typography } from "antd"
import type { FormProps } from "antd"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { StdStateSwitcher } from "@/components/common/Std/Form/StateSwitcher"

const { Item, useForm } = Form
const { Text } = Typography

const MACROSES = [
  {
    id: "ad_id",
    macrose: "{ad_id}",
    aboutMacrose: "teaser_id-text_id",
  },
  {
    id: "pub",
    macrose: "{pub}",
    aboutMacrose: "publisher pad (domain)",
  },
]

interface AdvCampaignOfferCreativeCreateProps {
  offerId: string | undefined
}

export const AdvCampaignOfferCreativeCreate = ({
  offerId,
}: AdvCampaignOfferCreativeCreateProps): JSX.Element => {
  const [form] = useForm()
  const { t } = useTranslation()

  const [isActive, setIsActive] = useState<boolean>(false)
  const [imageUrl, setImageUrl] = useState<string>("")
  const [imageType, setImageType] = useState<string>("")

  const [createCreative, { isSuccess, isLoading }] =
    useCreateAdvCampaignOfferCreativeMutation()

  const { data: creativeTypes, isLoading: creativeTypesIsLoading } =
    useGetAdvCampaignOfferCreativeTypesQuery(offerId || "")

  useEffect(() => {
    if (isSuccess) {
      form.resetFields()
      setImageUrl("")
      setImageType("")
    }
  }, [isSuccess])

  const onFinish: FormProps<AdvertiserCampaignOfferCreativeCreate>["onFinish"] =
    (values) => {
      const newValues = {
        ...values,
        offer_id: offerId || "",
        state: isActive ? "on" : "off",
        image: imageUrl,
        image_type: imageType,
      }

      createCreative(newValues)
    }

  return (
    <Form
      form={form}
      name="create-offer-creative"
      layout="vertical"
      labelCol={{ span: 16 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 450 }}
      onFinish={onFinish}
      autoComplete="off"
      initialValues={{
        state: "off",
        image: "none",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingBottom: "15px",
        }}
      >
        <Text>{t("ADV.CAMPAIGNS.MACROSES")}:</Text>
        {MACROSES.map((item) => (
          <Macrose
            key={item.id}
            macrose={item.macrose}
            aboutMacrose={item.aboutMacrose}
          />
        ))}
      </div>
      <Item
        name="teaser_type"
        label={t("SELECTS.TYPE")}
        rules={[{ required: true, message: t("SELECTS.TYPE_REQ") }]}
      >
        <Select
          placeholder={t("SELECTS.TYPE_PLACEHOLDER")}
          loading={creativeTypesIsLoading}
          options={creativeTypes?.map((item) => ({
            value: item?.id,
            label: item?.name,
          }))}
        />
      </Item>
      <Item
        name="image"
        label={t("ADV.CAMPAIGNS.IMAGE")}
        rules={[{ required: true, message: t("ADV.CAMPAIGNS.IMAGE_REQ") }]}
      >
        <AdvCampaignOfferCreativeUploadImage
          setImageType={setImageType}
          setImageUrl={setImageUrl}
          t={t}
        />
      </Item>
      {imageUrl && (
        <Item>
          <Image src={imageUrl} />
        </Item>
      )}
      <Item name="state">
        <StdStateSwitcher t={t} isActive={isActive} setIsActive={setIsActive} />
      </Item>
      <Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={isLoading}
          style={{ width: "100%" }}
        >
          {t("ADV.CAMPAIGNS.CREATE_BUTTON")}
        </Button>
      </Item>
    </Form>
  )
}

interface MacroseProps {
  macrose: string
  aboutMacrose: string
}

const Macrose = ({ macrose, aboutMacrose }: MacroseProps): JSX.Element => {
  return (
    <div style={{ display: "flex", gap: "5px" }}>
      <Text>{macrose} - </Text>
      <Text>{aboutMacrose}</Text>
    </div>
  )
}
