import { createApiLink, validateError } from "@/utils"
import { advertiserSplitApi } from "@/redux/emptySplitApi"
import {
  AdvertiserDevicesStatistics,
  AdvertiserGeoStatistics,
  AdvertiserGraph,
  AdvertiserInfo,
  AdvertiserInfoUpdate,
  AdvertiserModeration,
  AdvertiserNotification,
  AdvertiserNotificationUpdate,
  AdvertiserOffersDomainsStatistics,
  AdvertiserPayment,
  AdvertiserStatistics,
  ApiResponseError,
  ApiResponseOk,
} from "@/definitions"

export const apiWithTag = advertiserSplitApi.enhanceEndpoints({
  addTagTypes: ["advertiser"],
})

export const advertiserApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getAdvUserInfo: builder.query<AdvertiserInfo, void>({
      query: () => ({
        url: `${createApiLink(`/advertiser/`)}`,
        method: "GET",
      }),
      providesTags: () => [{ type: "advertiser" }],
    }),
    updateAdvUserInfo: builder.mutation<
      ApiResponseOk | ApiResponseError,
      AdvertiserInfoUpdate
    >({
      query: ({ name, timezone }) => ({
        url: `${createApiLink(`/advertiser/`)}`,
        method: "PUT",
        body: {
          name,
          timezone,
        },
        validateStatus: (response, result) => validateError(response, result),
      }),
      invalidatesTags: () => [{ type: "advertiser" }],
    }),
    getAdvNotifications: builder.query<AdvertiserNotification[], string>({
      query: (paramsString) => ({
        url: `${createApiLink(`/advertiser/notifications/`, paramsString)}`,
        method: "GET",
      }),
      providesTags: () => [{ type: "advertiser" }],
    }),
    updateAdvNotifications: builder.mutation<
      ApiResponseOk | ApiResponseError,
      AdvertiserNotificationUpdate[]
    >({
      query: (data) => ({
        url: `${createApiLink(`/advertiser/notifications/`)}`,
        method: "PUT",
        body: data,
        validateStatus: (response, result) => validateError(response, result),
      }),
      invalidatesTags: () => [{ type: "advertiser" }],
    }),
    getAdvPayments: builder.query<AdvertiserPayment[], void>({
      query: () => ({
        url: `${createApiLink(`/advertiser/payments/`)}`,
        method: "GET",
      }),
      providesTags: () => [{ type: "advertiser" }],
    }),
    getAdvStatistics: builder.query<AdvertiserStatistics, string>({
      query: (paramsString) => ({
        url: `${createApiLink(`/advertiser/statistics/`, paramsString)}`,
        method: "GET",
      }),
      providesTags: () => [{ type: "advertiser" }],
    }),
    getAdvGraphStatistics: builder.query<AdvertiserGraph[], string>({
      query: (paramsString) => ({
        url: `${createApiLink(`/advertiser/graphStatistics/`, paramsString)}`,
        method: "GET",
      }),
      providesTags: () => [{ type: "advertiser" }],
    }),
    getAdvOffersDomainsStatistics: builder.query<
      AdvertiserOffersDomainsStatistics,
      string
    >({
      query: (paramsString) => ({
        url: `${createApiLink(`/advertiser/offersDomains/`, paramsString)}`,
        method: "GET",
      }),
      providesTags: () => [{ type: "advertiser" }],
    }),
    getAdvDevicesStatistics: builder.query<AdvertiserDevicesStatistics, string>(
      {
        query: (paramsString) => ({
          url: `${createApiLink(
            `/advertiser/DevicesStatistics/`,
            paramsString,
          )}`,
          method: "GET",
        }),
        providesTags: () => [{ type: "advertiser" }],
      },
    ),
    getAdvGeoStatistics: builder.query<AdvertiserGeoStatistics, string>({
      query: (paramsString) => ({
        url: `${createApiLink(`/advertiser/GeoStatistics/`, paramsString)}`,
        method: "GET",
      }),
      providesTags: () => [{ type: "advertiser" }],
    }),
    getAdvModerations: builder.query<AdvertiserModeration[], string>({
      query: (paramsString) => ({
        url: `${createApiLink("/advertiser/moderation", paramsString)}`,
        method: "GET",
      }),
      providesTags: () => [{ type: "advertiser" }],
    }),
  }),
  overrideExisting: false,
})

export const {
  // Info
  useGetAdvUserInfoQuery,
  useUpdateAdvUserInfoMutation,
  // Notifications
  useGetAdvNotificationsQuery,
  useUpdateAdvNotificationsMutation,
  // Payments
  useGetAdvPaymentsQuery,
  // Statistics
  useGetAdvStatisticsQuery,
  useLazyGetAdvStatisticsQuery,
  // Graph
  useLazyGetAdvGraphStatisticsQuery,
  // Tools
  useGetAdvOffersDomainsStatisticsQuery,
  useGetAdvDevicesStatisticsQuery,
  useGetAdvGeoStatisticsQuery,
  // Moderations
  useGetAdvModerationsQuery,
} = advertiserApi
