import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  useEditPubSourceMutation,
  useGetPubSourceDefaultPriceQuery,
} from "@/redux/slices/publisherApi"
import { WidgetsTypesSelect } from "@/components/publisher/Widgets/WidgetTypesSelect"
import { StdPaymentModelsCheckboxes } from "@/components/common/Std/PaymentTypesCheckboxes"
import { StdCountries } from "@/components/common/Std/Selects/Countries"
import { StdTimezones } from "@/components/common/Std/Selects/Timezones"
import { StdModerationStatus } from "@/components/common/Std/Form/ModerationStatus"
import { StdDevicesTypes } from "@/components/common/Std/Selects/DevicesTypes"
import { PublisherSource, PublisherSourceEdit } from "@/definitions"
import { Button, Flex, Form, Input, InputNumber, Typography } from "antd"
import { TFunction } from "i18next"
import type { FormProps } from "antd"
import { DOMAIN_URL_PATTER } from "@/constants"

const { Item, useForm } = Form
const { TextArea } = Input
const { Title, Text } = Typography

interface PubWidgetEditProps {
  widgetId: string | undefined
  widgetData: PublisherSource | undefined
  t: TFunction<"translation", undefined>
  setDeleteModalVisible: Dispatch<SetStateAction<boolean>>
  deleteWidgetIsLoading: boolean
}

export const PubWidgetEdit = ({
  t,
  widgetId,
  widgetData,
  setDeleteModalVisible,
  deleteWidgetIsLoading,
}: PubWidgetEditProps): JSX.Element => {
  const [form] = useForm()
  const navigate = useNavigate()

  const [domain, setDomain] = useState<string>("")
  const [countryCode, setCountryCode] = useState<string>("")
  const [paymentModelId, setPaymentModelId] = useState<string>("")
  const [widgetTypeId, setWidgetTypeId] = useState<string>("")
  const [defaultPrice, setDefaultPrice] = useState<number>(0)
  const [price, setPrice] = useState<number | null>(null)

  const [udpateWidget, { isLoading, data }] = useEditPubSourceMutation()

  const { data: defaultPriceData } = useGetPubSourceDefaultPriceQuery(
    `payment_type_id=${paymentModelId}&country_code=${countryCode}&source_type_id=${widgetTypeId}`,
    { skip: !paymentModelId || !countryCode || !widgetTypeId },
  )

  useEffect(() => {
    const initPaymentId = widgetData?.payment_model_id
    const initCountryCode = widgetData?.country
    const initWidgetTypeId = widgetData?.teaser_type_id
    const initDomain = widgetData?.pad
    const initPrice = widgetData?.price

    if (initPaymentId) {
      setPaymentModelId(initPaymentId)
    }

    if (initCountryCode) {
      setCountryCode(initCountryCode)
    }

    if (initWidgetTypeId) {
      setWidgetTypeId(initWidgetTypeId)
    }

    if (initDomain) {
      setDomain(initDomain)
    }

    if (initPrice) {
      setPrice(Number(initPrice))
    }
  }, [widgetData])

  useEffect(() => {
    const defaultPriceFromData = defaultPriceData?.default_price

    if (defaultPriceFromData) {
      setDefaultPrice(defaultPriceFromData)
    }
  }, [defaultPriceData])

  useEffect(() => {
    if (data?.state === "ok") {
      navigate("/publisher/widgets")
    }
  }, [data])

  const handleChangeCountry = (value: string): void => {
    form.setFieldValue("country", value)
    setCountryCode(value)
  }

  const handleChangePrice = (value: number | null): void => {
    form.setFieldValue("price", value)
    setPrice(value)
  }

  const handleChangePaymentModel = (value: string): void => {
    form.setFieldValue("payment_model", value)
    setPaymentModelId(value)
  }

  const handleChangeDomain = (value: string): void => {
    form.setFieldValue("domain", value)
    setDomain(value)
  }

  const handleChangeWidgetType = (value: string): void => {
    form.setFieldValue("teaser_type", value)
    setWidgetTypeId(value)
  }

  const onFinish: FormProps<PublisherSourceEdit>["onFinish"] = (
    values,
  ): void => {
    const newValues = {
      ...values,
      source_id: widgetId || "",
    }

    udpateWidget(newValues)
  }

  return (
    <Form
      form={form}
      name="edit-widget"
      layout="vertical"
      labelCol={{ span: 16 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 450 }}
      onFinish={onFinish}
      initialValues={{
        domain: widgetData?.pad,
        source: widgetData?.source,
        payment_model: widgetData?.payment_model_id,
        teaser_type: widgetData?.teaser_type_id,
        price: widgetData?.price,
        country: widgetData?.country,
        user_comments: "",
      }}
      autoComplete="off"
    >
      <Title level={4} style={{ paddingBottom: "5px" }}>
        {t("PUB.WIDGETS.WIDGET_EDIT")}
      </Title>
      <Item
        name="source"
        label={t("INPUTS.WIDGET_NAME")}
        rules={[{ required: true, message: t("INPUTS.WIDGET_NAME_REQ") }]}
      >
        <Input placeholder={t("INPUTS.WIDGET_NAME_PLACEHOLDER")} />
      </Item>
      <Item
        name="country"
        label={t("SELECTS.COUNTRY")}
        rules={[{ required: false, message: t("SELECTS.COUNTRY_REQ") }]}
        help={<Text type="warning">{t("PUB.WIDGETS.FORM_ITEM_DISABLED")}</Text>}
      >
        <StdCountries
          t={t}
          onChange={handleChangeCountry}
          defaultValue={widgetData?.country}
          disabled={true}
        />
      </Item>
      <Item
        name="timezone"
        label={t("SELECTS.TIMEZONE")}
        rules={[{ required: false, message: t("SELECTS.TIMEZONE_REQ") }]}
        help={<Text type="warning">{t("PUB.WIDGETS.FORM_ITEM_DISABLED")}</Text>}
      >
        <StdTimezones
          t={t}
          onChange={() => {}}
          disabled={true}
          defaultValue={widgetData?.timezone}
        />
      </Item>
      <Item
        name="domain"
        label={t("INPUTS.WEBSITE")}
        rules={[
          { required: true, message: t("INPUTS.WEBSITE_REQ") },
          { pattern: DOMAIN_URL_PATTER, message: t("INPUTS.WEBSITE_INVALID") },
        ]}
        help={
          domain !== widgetData?.pad ? (
            <Text type="warning">{t("PUB.WIDGETS.FORM_ITEM_WARNING")}</Text>
          ) : null
        }
      >
        <Input
          placeholder={t("INPUTS.WEBSITE_PLACEHOLDER")}
          onChange={(e) => handleChangeDomain(e.target.value)}
        />
      </Item>
      <Item
        name="teaser_type"
        label={t("SELECTS.WIDGET_TYPE")}
        rules={[{ required: false, message: t("SELECTS.WIDGET_TYPE_REQ") }]}
        help={<Text type="warning">{t("PUB.WIDGETS.FORM_ITEM_DISABLED")}</Text>}
      >
        <WidgetsTypesSelect
          t={t}
          onChange={handleChangeWidgetType}
          defaultValue={widgetData?.teaser_type_id}
          disabled={true}
        />
      </Item>
      <Item
        name="device_type"
        label={t("SELECTS.WIDGET_DEVICE_TYPE")}
        rules={[
          { required: false, message: t("SELECTS.WIDGET_DEVICE_TYPE_REQ") },
        ]}
        help={<Text type="warning">{t("PUB.WIDGETS.FORM_ITEM_DISABLED")}</Text>}
      >
        <StdDevicesTypes
          t={t}
          onChange={() => {}}
          disabled={true}
          defaultValue={widgetData?.device_type_id}
        />
      </Item>
      <Item
        name="payment_model"
        label={t("SELECTS.PAYMENT_MODEL")}
        rules={[{ required: true, message: t("SELECTS.PAYMENT_MODEL_REQ") }]}
        help={
          paymentModelId !== widgetData?.payment_model_id ? (
            <Text type="warning">{t("PUB.WIDGETS.FORM_ITEM_WARNING")}</Text>
          ) : null
        }
      >
        <StdPaymentModelsCheckboxes
          onChange={handleChangePaymentModel}
          defaultValue={widgetData?.payment_model_id}
        />
      </Item>
      <Item
        name="price"
        label={t("INPUTS.PRICE_USD")}
        rules={[{ required: true, message: t("INPUTS.PRICE_USD_REQ") }]}
        help={getWarningMessagePrice(
          defaultPrice,
          price,
          Number(widgetData?.price),
          t,
        )}
      >
        <InputNumber
          min={0}
          max={1000}
          decimalSeparator="."
          addonAfter="$"
          style={{ width: "100%" }}
          onChange={handleChangePrice}
          placeholder={t("INPUTS.PRICE_USD_PLACEHOLDER")}
        />
      </Item>
      <Item name="user_comments" label={t("INPUTS.USER_COMMENTS")}>
        <TextArea
          rows={4}
          placeholder={t("INPUTS.USER_COMMENTS_PLACEHOLDER")}
        />
      </Item>
      {widgetData?.reject_reason && (
        <Item label={t("INPUTS.REJECT_REASON")}>
          <TextArea
            rows={4}
            value={widgetData?.reject_reason || ""}
            readOnly
            placeholder={""}
            style={{ cursor: "default", resize: "none" }}
          />
        </Item>
      )}
      <StdModerationStatus status={widgetData?.moderation_status} t={t} />
      <Item>
        <Button
          style={{ width: "100%" }}
          type="primary"
          htmlType="submit"
          loading={isLoading}
        >
          {t("BUTTONS.UPDATE")}
        </Button>
      </Item>
      <Item>
        <Button
          loading={deleteWidgetIsLoading}
          style={{ width: "100%" }}
          type="primary"
          danger
          htmlType="button"
          onClick={() => setDeleteModalVisible(true)}
        >
          {t("BUTTONS.DELETE")}
        </Button>
      </Item>
    </Form>
  )
}

const getWarningMessagePrice = (
  defaultPrice: number,
  price: number | null,
  initPrice: number,
  t: TFunction<"translation", undefined>,
): JSX.Element | null => {
  if (typeof price === "number") {
    if (price > defaultPrice && price !== initPrice) {
      return (
        <Flex vertical gap={6}>
          <Text type="warning">
            {t("PUB.WIDGETS.DEFAULT_PRICE_WARNING", {
              price: defaultPrice,
            })}
          </Text>
          <Text type="warning">{t("PUB.WIDGETS.FORM_ITEM_WARNING")}</Text>
        </Flex>
      )
    }

    if (price > defaultPrice && price === initPrice) {
      return (
        <Text type="warning">
          {t("PUB.WIDGETS.DEFAULT_PRICE_WARNING", {
            price: defaultPrice,
          })}
        </Text>
      )
    }

    if (price >= 0 && price !== initPrice) {
      return <Text type="warning">{t("PUB.WIDGETS.FORM_ITEM_WARNING")}</Text>
    }
  }

  return null
}
