import { Middleware } from "@reduxjs/toolkit"
import { isRejectedWithValue } from "@reduxjs/toolkit"

export const authMiddleware: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (isRejectedWithValue(action) && action.payload?.status === 401) {
      window.location.href = "https://adn.scroogefrog.com/?page=sing_in&lang=en"
    }
    return next(action)
  }
