import { StdModerationStatusText } from "@/components/common/Std/ModerationStatusText"
import { ModerationStatuses } from "@/definitions"
import { Flex, Typography } from "antd"
import { TFunction } from "i18next"

const { Text } = Typography

interface StdModerationStatusProps {
  status: ModerationStatuses | undefined
  t: TFunction<"translation", undefined>
}

export const StdModerationStatus = ({
  status,
  t,
}: StdModerationStatusProps): JSX.Element => {
  return (
    <Flex align="center" gap={15} style={{ paddingBottom: "24px" }}>
      <Text>{t("INPUTS.MODERATION_STATUS")}:</Text>
      <StdModerationStatusText status={status} />
    </Flex>
  )
}
