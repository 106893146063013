import { Dispatch, SetStateAction } from "react"
import { StdLabel } from "@/components/common/Std/Label"
import { useGetModerationFiltersQuery } from "@/redux/slices/utilsApi"
import { textToUpperCase } from "@/utils/textToUpperCase"
import { DatePicker, Flex, Input, Select } from "antd"
import { TFunction } from "i18next"

interface PubModerationFiltersProps {
  t: TFunction<"translation", undefined>
  setStartDate: Dispatch<SetStateAction<string>>
  setEndDate: Dispatch<SetStateAction<string>>
  setWidgetId: Dispatch<SetStateAction<string>>
  setStatusId: Dispatch<SetStateAction<string>>
}

export const PubModerationFilters = ({
  t,
  setStartDate,
  setEndDate,
  setWidgetId,
  setStatusId,
}: PubModerationFiltersProps): JSX.Element => {
  const { data: moderationFiltersData, isLoading: moderationFiltersIsLoading } =
    useGetModerationFiltersQuery()

  return (
    <Flex vertical gap="large" style={{ maxWidth: "300px", width: "100%" }}>
      <Input
        placeholder={t("INPUTS.ID_PLACEHOLDER")}
        onChange={(e) => setWidgetId(e.target.value)}
      />
      <Flex gap="large" wrap="wrap" style={{ width: "100%" }}>
        <DatePicker
          style={{ flex: "1" }}
          placeholder={t("SELECTS.START_DATE_PLACEHOLDER")}
          onChange={(_: any, dateString: string) => setStartDate(dateString)}
        />
        <DatePicker
          style={{ flex: "1" }}
          placeholder={t("SELECTS.END_DATE_PLACEHOLDER")}
          onChange={(_: any, dateString: string) => setEndDate(dateString)}
        />
      </Flex>
      <StdLabel label={t("SELECTS.STATUS")}>
        <Select
          placeholder={t("SELECTS.STATUS_PLACEHOLDER")}
          loading={moderationFiltersIsLoading}
          defaultValue="-1"
          onChange={(value: string) => setStatusId(value)}
          options={moderationFiltersData?.map((item) => ({
            label: t(`STATUSES.${textToUpperCase(item.ms_name)}`),
            value: item.ms_id,
          }))}
        />
      </StdLabel>
    </Flex>
  )
}
