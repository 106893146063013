import { Select } from "antd"
import { useTranslation } from "react-i18next"
import { CSSProperties } from "react"
import { useUpdateLangMutation } from "@/redux/slices/utilsApi"
import { getCookie, setCookie } from "@/utils/cookies"

interface StdLangsSwitcherProps {
  style: CSSProperties
}

export const StdLangsSwitcher = ({
  style,
}: StdLangsSwitcherProps): JSX.Element => {
  const { i18n } = useTranslation()

  const [updateLang] = useUpdateLangMutation()

  const onChangeLang = (value: string): void => {
    i18n.changeLanguage(value)
    setCookie("lang", value)
    updateLang(value)
  }

  return (
    <Select
      defaultValue={getCookie("lang") || "en"}
      style={style}
      onChange={onChangeLang}
    >
      <Select.Option value="en">En</Select.Option>
      <Select.Option value="es">Es</Select.Option>
    </Select>
  )
}
