import { PublisherSourceDeviceType } from "@/definitions"
import { useGetPubSourceDevicesTypesQuery } from "@/redux/slices/publisherApi"
import { Select } from "antd"
import { TFunction } from "i18next"

interface StdDevicesTypesProps {
  t: TFunction<"translation", undefined>
  onChange: (value: string) => void
  defaultValue?: string
  disabled?: boolean
}

export const StdDevicesTypes = ({
  t,
  onChange,
  defaultValue,
  disabled,
}: StdDevicesTypesProps): JSX.Element => {
  const { data, isLoading } = useGetPubSourceDevicesTypesQuery()

  return (
    <Select
      optionFilterProp="children"
      defaultValue={defaultValue}
      disabled={disabled}
      onChange={onChange}
      loading={isLoading}
      placeholder={t("SELECTS.WIDGET_DEVICE_TYPE_PLACEHOLDER")}
      options={data?.map((deviceType: PublisherSourceDeviceType) => ({
        label: deviceType.type,
        value: deviceType.id,
      }))}
    />
  )
}
