import { useEffect, useState } from "react"
import { Typography, Select, Table, Flex } from "antd"
import { useTranslation } from "react-i18next"
import { useGetAdvDevicesStatisticsQuery } from "@/redux/slices/advertiserApi"
import { StdErrorMessage } from "@/components/common/Std/ErrorMessage"
import { StdSpinner } from "@/components/common/Std/Spinner"
import { StdLabel } from "@/components/common/Std/Label"
import { useGetAdvAllCampaignsQuery } from "@/redux/slices/advertiserCampaignsApi"
import { AdvToolsFilters } from "@/components/advertiser/Tools/ToolsFilters"
import { composeQueryParamsString } from "@/utils/composeQueryParamsString"
import dayjs from "dayjs"

const { Title } = Typography

export const AdvToolsDevicesStatistics = (): JSX.Element => {
  const { t } = useTranslation()

  const [campaignId, setCampaignId] = useState<string>("")
  const [widgetId, setWidgetId] = useState<string>("")
  const [deviceId, setDeviceId] = useState<string>("")
  const [startDate, setStartDate] = useState<string>(
    dayjs().subtract(7, "day").format("YYYY-MM-DD"),
  )
  const [endDate, setEndDate] = useState<string>(dayjs().format("YYYY-MM-DD"))

  const { data: campaignsData, isLoading: campaignsIsLoading } =
    useGetAdvAllCampaignsQuery()

  const {
    data: statsData,
    isLoading: statsIsLoading,
    isError: statsIsError,
  } = useGetAdvDevicesStatisticsQuery(
    composeQueryParamsString({
      from: startDate,
      to: endDate,
      campaign_id: campaignId,
      widget_id: widgetId,
      device_type_id: deviceId,
    }),
    {
      skip: !campaignId,
    },
  )

  const { data: selectsData, isLoading: selectsDataIsLoading } =
    useGetAdvDevicesStatisticsQuery(
      `from=${startDate}&to=${endDate}&campaign_id=${campaignId}`,
      {
        skip: !campaignId,
      },
    )

  const defaultCampaign = campaignsData?.reduce((maxObj, currentObj) => {
    return parseFloat(currentObj.campaign_id) > parseFloat(maxObj.campaign_id)
      ? currentObj
      : maxObj
  }, campaignsData[0])

  useEffect(() => {
    setCampaignId(defaultCampaign?.campaign_id || "")
  }, [campaignsData])

  const onChangeStartDate = (_: any, dateString: string): void => {
    setStartDate(dateString)
  }

  const onChangeEndDate = (_: any, dateString: string): void => {
    setEndDate(dateString)
  }

  const onChangeCampaign = (value: string): void => {
    const campaignId = value.split(" - ")[0]

    setCampaignId(campaignId)
  }

  const onChangeDevice = (value: string): void => {
    if (value === "") {
      setDeviceId("")
      return
    }

    const deviceId = value.split(" - ")[0]

    setDeviceId(deviceId)
  }

  const ALL_DEVISE_OPTION = {
    label: t("SELECTS.ALL"),
    value: "",
  }

  const devicesOptions = selectsData?.devices?.map((device) => ({
    label: device.name,
    value: `${device.id} - ${device.name}`,
  }))

  const widgetsOptions = selectsData?.widgets_ids?.map((widgetId: string) => ({
    label: widgetId,
    value: widgetId,
  }))

  if (campaignsIsLoading) return <StdSpinner />

  if (statsIsError) return <StdErrorMessage>Error...</StdErrorMessage>

  return (
    <Flex vertical gap="large">
      <Title level={3}>{t("ADV.TOOLS.DEVICES_STATISTICS")}</Title>

      <Flex vertical gap="large" style={{ maxWidth: "300px", width: "100%" }}>
        <AdvToolsFilters
          t={t}
          widgetsOptions={widgetsOptions}
          campaignsData={campaignsData}
          defaultCampaign={defaultCampaign}
          campaignsIsLoading={campaignsIsLoading}
          widgetsDataIsLoading={selectsDataIsLoading}
          setWidgetId={setWidgetId}
          onChangeCampaign={onChangeCampaign}
          onChangeStartDate={onChangeStartDate}
          onChangeEndDate={onChangeEndDate}
        />
        <StdLabel label={t("SELECTS.DEVICE_PLACEHOLDER")}>
          <Select
            placeholder={t("SELECTS.DEVICE_PLACEHOLDER")}
            defaultValue={""}
            loading={selectsDataIsLoading}
            showSearch
            options={
              devicesOptions
                ? [ALL_DEVISE_OPTION, ...devicesOptions]
                : [ALL_DEVISE_OPTION]
            }
            onChange={onChangeDevice}
          />
        </StdLabel>
      </Flex>

      <Table
        scroll={{ x: "max-content" }}
        dataSource={
          !!statsData?.data && statsData?.data?.length > 0
            ? statsData?.data.map((item, index) => ({
                ...item,
                row_id: item.widget_id + `${index}`,
              }))
            : []
        }
        loading={statsIsLoading}
        rowKey={"row_id"}
        columns={[
          {
            title: t("ADV.TOOLS.WIDGET_ID"),
            dataIndex: "widget_id",
            key: "widget_id",
          },
          {
            title: t("ADV.TOOLS.DEVICE_TYPE"),
            dataIndex: "device_type",
            key: "device_type",
          },
          {
            title: t("ADV.TOOLS.BLOCK_VIEWS_DEVICES"),
            dataIndex: "block_views",
            key: "block_views",
          },
        ]}
        pagination={{ position: ["bottomCenter"], showSizeChanger: false }}
      />
    </Flex>
  )
}
