import { Typography, Table, Flex } from "antd"
import { useEffect, useState } from "react"
import {
  useGetPubCampaignsQuery,
  useLazyGetPubOffersStatisticsQuery,
} from "@/redux/slices/publisherApi"
import { useTranslation } from "react-i18next"
import { useGetOffersDomainsGeoQuery } from "@/redux/slices/utilsApi"
import { PubOfferDomainsFilters } from "@/components/publisher/OfferDomains/OfferDomainsFilters"
import { StdSpinner } from "@/components/common/Std/Spinner"
import { StdErrorMessage } from "@/components/common/Std/ErrorMessage"
import { StdPaginationRange } from "@/components/common/Std/Selects/PaginationRange"
import { getValueByBoolean } from "@/utils/getValueByBoolean"
import { DEFAULT_PAGINATION_RANGE } from "@/constants"
import dayjs from "dayjs"

const { Title } = Typography

export const PubOfferDomains = (): JSX.Element => {
  const { t } = useTranslation()

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [paginationRange, setPaginationRange] = useState<number>(
    DEFAULT_PAGINATION_RANGE,
  )
  const [campaign, setCampaign] = useState<string>("")
  const [startDate, setStartDate] = useState<string>("1980-01-01")
  const [endDate, setEndDate] = useState<string>(dayjs().format("YYYY-MM-DD"))
  const [geo, setGeo] = useState<string>("ALL")
  const [showUrlsStat, setShowUrlsStat] = useState<boolean>(false)

  const {
    data: pubCampaignsData,
    isLoading: pubCampaignsIsLoading,
    isError: pubCampaignsIsError,
  } = useGetPubCampaignsQuery()

  const {
    data: geoData,
    isLoading: isLoadingGeoData,
    isError: isErrorGeoData,
  } = useGetOffersDomainsGeoQuery()

  const [fetchData, { data, isLoading, isError }] =
    useLazyGetPubOffersStatisticsQuery()

  useEffect(() => {
    if (campaign) {
      fetchData(
        `from=${startDate}&to=${endDate}&show_url_stat=${getValueByBoolean(
          showUrlsStat,
        )}&geo=${geo}&campaign_id=${campaign}`,
      )
    }
  }, [startDate, endDate, showUrlsStat, geo, campaign])

  const defaultCampaign = pubCampaignsData?.reduce((maxObj, currentObj) => {
    return parseFloat(currentObj.campaign_id) > parseFloat(maxObj.campaign_id)
      ? currentObj
      : maxObj
  }, pubCampaignsData[0])

  useEffect(() => {
    setCampaign(defaultCampaign?.campaign_id || "")
  }, [defaultCampaign])

  const handlePageChange = (page: number): void => {
    setCurrentPage(page)
  }

  const onChangeStartDate = (_: any, dateString: string): void => {
    setStartDate(dateString)
  }

  const onChangeEndDate = (_: any, dateString: string): void => {
    setEndDate(dateString)
  }

  if (pubCampaignsIsLoading || isLoadingGeoData) {
    return <StdSpinner />
  }

  if (pubCampaignsIsError || isErrorGeoData || isError) {
    return <StdErrorMessage>Error...</StdErrorMessage>
  }

  return (
    <Flex vertical gap="large">
      <Title level={3}>{t("PUB.OFFER_DOMAINS.OFFER_DOMAINS")}</Title>

      <Flex
        style={{ width: "100%" }}
        align="flex-end"
        justify="space-between"
        gap="large"
        wrap="wrap"
      >
        <PubOfferDomainsFilters
          t={t}
          defaultCampaign={defaultCampaign}
          setCampaign={setCampaign}
          setGeo={setGeo}
          setShowUrlsStat={setShowUrlsStat}
          pubCampaignsData={pubCampaignsData}
          onChangeStartDate={onChangeStartDate}
          onChangeEndDate={onChangeEndDate}
          geoData={geoData}
        />
        <StdPaginationRange
          t={t}
          paginationRange={paginationRange}
          setPaginationRange={setPaginationRange}
        />
      </Flex>

      <div style={{ position: "relative" }}>
        <Table
          scroll={{ x: "max-content" }}
          loading={isLoading}
          rowKey="id"
          dataSource={data?.domains}
          showSorterTooltip={false}
          sortDirections={["ascend", "descend", "ascend"]}
          columns={[
            {
              title: t("PUB.OFFER_DOMAINS.DATE"),
              dataIndex: "date",
              key: "date",
              sorter: (a, b) =>
                new Date(a.date).getTime() - new Date(b.date).getTime(),
              defaultSortOrder: "descend",
            },
            {
              title: t("PUB.OFFER_DOMAINS.OFFER"),
              dataIndex: "offer",
              key: "offer",
              sorter: (a, b) => a.offer.localeCompare(b.offer),
            },
            {
              title: t("PUB.OFFER_DOMAINS.COUNTRY"),
              dataIndex: "country",
              key: "country",
              sorter: (a, b) => a.country.localeCompare(b.country),
            },
            {
              title: t("PUB.OFFER_DOMAINS.DOMAIN"),
              dataIndex: "domain",
              key: "domain",
              sorter: (a, b) => a.domain.localeCompare(b.domain),
            },
            {
              title: t("PUB.OFFER_DOMAINS.BAD"),
              dataIndex: "bad",
              key: "bad",
              sorter: (a, b) => a.bad.localeCompare(b.bad),
            },
          ]}
          pagination={{
            position: ["bottomCenter"],
            current: currentPage,
            pageSize: paginationRange,
            showSizeChanger: false,
            onChange: handlePageChange,
            total: data?.domains?.length,
            showTotal: (total, range) => {
              if (range[1] > 0) {
                return (
                  <span className="paginationShowTotal">
                    {t("PAGINATION.SHOW_TOTAL", {
                      firstNumber: range[0],
                      secondNumber: range[1],
                      total: total,
                    })}
                  </span>
                )
              }
            },
          }}
        />
      </div>
    </Flex>
  )
}
