import { Dispatch, SetStateAction } from "react"
import { StdLabel } from "@/components/common/Std/Label"
import { AdvertiserModeration } from "@/definitions"
import { useGetModerationFiltersQuery } from "@/redux/slices/utilsApi"
import { textToUpperCase } from "@/utils/textToUpperCase"
import { DatePicker, Flex, Select } from "antd"
import { TFunction } from "i18next"

interface AdvModerationFiltersProps {
  t: TFunction<"translation", undefined>
  data: AdvertiserModeration[] | undefined
  setCampaignId: Dispatch<SetStateAction<string>>
  setStartDate: Dispatch<SetStateAction<string>>
  setEndDate: Dispatch<SetStateAction<string>>
  setStatusId: Dispatch<SetStateAction<string>>
}

export const AdvModerationFilters = ({
  t,
  data,
  setCampaignId,
  setStartDate,
  setEndDate,
  setStatusId,
}: AdvModerationFiltersProps): JSX.Element => {
  const { data: moderationFiltersData, isLoading: moderationFiltersIsLoading } =
    useGetModerationFiltersQuery()

  const ALL_ID = {
    label: t("SELECTS.ALL"),
    value: "",
  }

  const optionsIds = data?.map((item) => ({
    label: item.id,
    value: item.id,
  }))

  return (
    <Flex vertical gap="large" style={{ maxWidth: "300px", width: "100%" }}>
      <Select
        placeholder={t("INPUTS.ID_PLACEHOLDER")}
        onChange={(value) => setCampaignId(value)}
        showSearch
        options={optionsIds ? [ALL_ID, ...optionsIds] : [ALL_ID]}
      />
      <Flex gap="large" wrap="wrap" style={{ width: "100%" }}>
        <DatePicker
          style={{ flex: "1" }}
          placeholder={t("SELECTS.START_DATE_PLACEHOLDER")}
          onChange={(_: any, dateString: string) => setStartDate(dateString)}
        />
        <DatePicker
          style={{ flex: "1" }}
          placeholder={t("SELECTS.END_DATE_PLACEHOLDER")}
          onChange={(_: any, dateString: string) => setEndDate(dateString)}
        />
      </Flex>
      <StdLabel label={t("SELECTS.STATUS")}>
        <Select
          placeholder={t("SELECTS.STATUS_PLACEHOLDER")}
          loading={moderationFiltersIsLoading}
          defaultValue="-1"
          onChange={(value: string) => setStatusId(value)}
          options={moderationFiltersData?.map((item) => ({
            label: t(`STATUSES.${textToUpperCase(item.ms_name)}`),
            value: item.ms_id,
          }))}
        />
      </StdLabel>
    </Flex>
  )
}
