import { Dispatch, SetStateAction } from "react"
import {
  UserOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons"
import {
  Layout,
  Avatar,
  Typography,
  Button,
  Space,
  Dropdown,
  MenuProps,
  theme,
} from "antd"
import { NavLink } from "react-router-dom"
import { StdLangsSwitcher } from "@/components/common/Std/Selects/LangsSwitcher"
import { TFunction } from "i18next"
import { ReactComponent as LogoIcon } from "@/assets/logo.svg"
import styles from "@/components/common/Layouts/AuthLayout/AuthLayout.module.css"

const { Header } = Layout
const { Title } = Typography
const { useToken } = theme

interface AuthLayoutHeaderProps {
  t: TFunction<"translation", undefined>
  lang: string | undefined
  role: string | undefined
  balance: string | number
  firstLetterOfName: string
  theme: string
  showLogoutModal: () => void
  setCurrentPath: Dispatch<SetStateAction<string>>
  collapsed: boolean
  setCollapsed: Dispatch<SetStateAction<boolean>>
  isMobile: boolean
}

export const AuthLayoutHeader = ({
  t,
  lang,
  role,
  balance,
  firstLetterOfName,
  theme,
  showLogoutModal,
  setCurrentPath,
  collapsed,
  setCollapsed,
  isMobile,
}: AuthLayoutHeaderProps): JSX.Element => {
  const { token } = useToken()

  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  }

  const MENU_ITEMS: MenuProps["items"] = [
    {
      key: "account",
      label: <NavLink to={`/${role}/account`}>{t("ACCOUNT")}</NavLink>,
      icon: <UserOutlined />,
      onClick: () => {
        setCurrentPath("account")
      },
    },
    {
      key: "logout",
      label: <div>{t("LOGOUT")}</div>,
      onClick: showLogoutModal,
      icon: <LogoutOutlined />,
    },
  ]

  return (
    <Header className={styles.header}>
      <Space>
        {!isMobile && (
          <Space>
            <Title level={5} style={{ color: "#fff" }}>
              {balance} USD
            </Title>
          </Space>
        )}
        <StdLangsSwitcher style={{ width: 70 }} />
        <Dropdown
          menu={{
            items: MENU_ITEMS,
          }}
          dropdownRender={(menu) => (
            <div style={contentStyle}>
              {isMobile && (
                <Title
                  level={5}
                  style={{ textAlign: "center", padding: "5px 5px 0 5px" }}
                >
                  {balance} USD
                </Title>
              )}
              {menu}
            </div>
          )}
        >
          <Avatar
            size="large"
            style={{
              cursor: "pointer",
              background: theme === "dark" ? "#00b96b" : "#1f7a1f",
              fontSize: "22px",
            }}
          >
            {firstLetterOfName ? firstLetterOfName : <UserOutlined />}
          </Avatar>
        </Dropdown>
      </Space>
      <Space align={"center"} className={styles.headerLogoContainer}>
        <NavLink
          to={`https://adn.scroogefrog.com/?lang=${lang || "en"}`}
          className={styles.headerLogo}
        >
          <LogoIcon className={styles.logoImage} />
          <div className={styles.logoWrapper}>
            <Title
              className={styles.logoText}
              level={5}
              style={{
                color: "#fff",
              }}
            >
              ScroogeFrog
            </Title>
            <Title
              className={(styles.logoTextRight, styles.logoText)}
              level={5}
              style={{
                color: "#fff",
              }}
            >
              AdNetwork
            </Title>
          </div>
        </NavLink>

        <Button
          className={styles.trigger}
          onClick={() => setCollapsed(!collapsed)}
        >
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Button>
      </Space>
    </Header>
  )
}
