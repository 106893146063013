import { Roles } from "@/definitions"
import { getCookie } from "@/utils/cookies"
import { ReactNode } from "react"
import { Navigate, useLocation } from "react-router-dom"

interface ProtectedRouteProps {
  children: ReactNode
  requiredRole: Roles
}

export const ProtectedRoute = ({
  children,
  requiredRole,
}: ProtectedRouteProps) => {
  const location = useLocation()

  const token = getCookie("token")
  const role = getCookie("role")

  if (!token || !role) {
    return <Navigate to="/" replace state={{ from: location }} />
  }

  if (requiredRole !== role) {
    return (
      <Navigate to={`/${role}/statistics`} replace state={{ from: location }} />
    )
  }

  return children
}
