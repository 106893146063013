import { useEffect, useState } from "react"
import { useCreateAdvCampaignOfferMutation } from "@/redux/slices/advertiserCampaignsApi"
import { Button, Form, Input, Typography } from "antd"
import type { FormProps } from "antd"
import { AdvertiserCampaignOfferCreate } from "@/definitions"
import { useTranslation } from "react-i18next"
import { StdStateSwitcher } from "@/components/common/Std/Form/StateSwitcher"
import { TFunction } from "i18next"

const { Item, useForm } = Form
const { Text } = Typography

const URL_TAMPLATES = [
  {
    id: "AD_ID",
    template: "{ad_id}",
  },
  {
    id: "IMG_ID",
    template: "{img_id}",
  },
  {
    id: "TEXT_ID",
    template: "{text_id}",
  },
  {
    id: "WIDGET_ID",
    template: "{widget_id}",
  },
  {
    id: "CAMPAIGN_ID",
    template: "{campaign_id}",
  },
  {
    id: "WEBSITE_ID",
    template: "{website_id}",
  },
  {
    id: "CPC",
    template: "{cpc}",
  },
]

interface AdvCampaignOfferCreateProps {
  campaignId: string | undefined
}

export const AdvCampaignOfferCreate = ({
  campaignId,
}: AdvCampaignOfferCreateProps): JSX.Element => {
  const [form] = useForm()
  const { t } = useTranslation()

  const [isActive, setIsActive] = useState<boolean>(false)

  const [createOffer, { isLoading, isSuccess }] =
    useCreateAdvCampaignOfferMutation()

  useEffect(() => {
    if (isSuccess) {
      form.resetFields()
    }
  }, [isSuccess])

  const onFinish: FormProps<AdvertiserCampaignOfferCreate>["onFinish"] = (
    values,
  ) => {
    const newValues = {
      ...values,
      campaign_id: campaignId || "",
      state: isActive ? "on" : "off",
    }

    createOffer(newValues)
  }

  return (
    <Form
      form={form}
      name="create-offer"
      layout="vertical"
      labelCol={{ span: 16 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 450 }}
      onFinish={onFinish}
      autoComplete="off"
      initialValues={{
        state: "off",
      }}
    >
      <Item
        name="name"
        label={t("ADV.CAMPAIGNS.OFFER_NAME")}
        rules={[{ required: true, message: t("ADV.CAMPAIGNS.OFFER_NAME_REQ") }]}
      >
        <Input placeholder={t("ADV.CAMPAIGNS.OFFER_NAME")} />
      </Item>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingBottom: "5px",
        }}
      >
        <Text>{t("ADV.CAMPAIGNS.OFFERS_MACROSES.TITLE")}:</Text>
        {URL_TAMPLATES.map((item) => (
          <UrlTemplate
            key={item.id}
            template={item.template}
            id={item.id}
            t={t}
          />
        ))}
      </div>
      <Item
        name="url"
        label={t("ADV.CAMPAIGNS.URL")}
        rules={[{ required: true, message: t("ADV.CAMPAIGNS.URL_REQ") }]}
      >
        <Input placeholder={t("ADV.CAMPAIGNS.URL")} />
      </Item>
      <Item name="state">
        <StdStateSwitcher t={t} isActive={isActive} setIsActive={setIsActive} />
      </Item>
      <Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={isLoading}
          style={{ width: "100%" }}
        >
          {t("ADV.CAMPAIGNS.CREATE_BUTTON")}
        </Button>
      </Item>
    </Form>
  )
}

interface UrlTemplateProps {
  template: string
  id: string
  t: TFunction<"translation", undefined>
}

const UrlTemplate = ({ template, id, t }: UrlTemplateProps): JSX.Element => {
  return (
    <div style={{ display: "flex", gap: "5px" }}>
      <Text>{template} - </Text>
      <Text>{t(`ADV.CAMPAIGNS.OFFERS_MACROSES.${id}`)}</Text>
    </div>
  )
}
