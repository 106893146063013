import { Modal } from "antd"
import { TFunction } from "i18next"
import { Dispatch, SetStateAction } from "react"

interface PubWidgetDeleteModalProps {
  t: TFunction<"translation", undefined>
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  handleDeleteWidget: () => Promise<void>
}

export const PubWidgetDeleteModal = ({
  t,
  isOpen,
  setIsOpen,
  handleDeleteWidget,
}: PubWidgetDeleteModalProps): JSX.Element => {
  return (
    <Modal
      title={t("MODALS.DELETE_WIDGET")}
      open={isOpen}
      onOk={handleDeleteWidget}
      okText={t("BUTTONS.YES")}
      cancelText={t("BUTTONS.NO")}
      centered
      width={350}
      onCancel={() => setIsOpen(false)}
      okButtonProps={{ danger: true }}
    />
  )
}
