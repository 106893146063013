import { Flex, Input, Typography } from "antd"

const { TextArea } = Input
const { Text } = Typography

interface AdvCampaignOfferTextCheckLengthProps {
  text: string
  isErrorText: boolean
}

export const AdvCampaignOfferTextCheckLength = ({
  text,
  isErrorText,
}: AdvCampaignOfferTextCheckLengthProps): JSX.Element => {
  return (
    <Flex gap={15} style={{ paddingBottom: "15px" }}>
      <TextArea
        className="check-text"
        readOnly={true}
        value={text}
        rows={5}
        style={{
          width: "130px",
          fontSize: "11px",
          fontFamily: "sabes",
          resize: "none",
          overflow: "hidden",
          wordBreak: "normal",
          hyphens: "none",
          overflowWrap: "normal",
          textAlign: "center",
        }}
      />
      {text?.length > 0 && (
        <Text strong={true} type={isErrorText ? "danger" : "success"}>
          {isErrorText ? "Error!" : "Ok"}
        </Text>
      )}
    </Flex>
  )
}
