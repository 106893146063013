import { Select } from "antd"
import { TFunction } from "i18next"
import { Dispatch, SetStateAction } from "react"

const PAGINATION_RANGE: number[] = [10, 20, 30, 40, 50]

interface StdPaginationRangeProps {
  t: TFunction<"translation", undefined>
  paginationRange: number
  setPaginationRange: Dispatch<SetStateAction<number>>
}

export const StdPaginationRange = ({
  t,
  paginationRange,
  setPaginationRange,
}: StdPaginationRangeProps): JSX.Element => {
  return (
    <Select
      placeholder={t("PAGINATION.RANGE_PLACEHOLDER")}
      onChange={(value) => setPaginationRange(value)}
      value={paginationRange}
      options={PAGINATION_RANGE.map((option) => ({
        label: t("PAGINATION.RANGE", { option: option }),
        value: option,
      }))}
      style={{ width: "auto" }}
    />
  )
}
