import { StdStateSwitcher } from "@/components/common/Std/Form/StateSwitcher"
import { AdvertiserCampaign, AdvertiserCampaignUpdate } from "@/definitions"
import { useUpdateAdvCampaignMutation } from "@/redux/slices/advertiserCampaignsApi"
import { Typography, Button, Input, InputNumber, Flex, Form } from "antd"
import type { FormProps } from "antd"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

const { Item } = Form
const { Text } = Typography

interface AdvCampaignEditProps {
  campaignId: string | undefined
  campaign: AdvertiserCampaign | undefined
}

export const AdvCampaignEdit = ({
  campaignId,
  campaign,
}: AdvCampaignEditProps): JSX.Element => {
  const { t } = useTranslation()

  const [isActive, setIsActive] = useState<boolean>(false)

  const [updateCampaign] = useUpdateAdvCampaignMutation()

  const onFinish: FormProps<AdvertiserCampaignUpdate>["onFinish"] = (
    values,
  ): void => {
    const newValues = {
      ...values,
      campaign_id: campaignId || "",
      state: isActive ? "on" : "off",
    }

    updateCampaign(newValues)
  }

  useEffect(() => {
    if (campaign?.state === "on") setIsActive(true)
  }, [campaign])

  return (
    <Form
      name="edit-campaign"
      layout="vertical"
      labelCol={{ span: 16 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 450 }}
      onFinish={onFinish}
      autoComplete="off"
      initialValues={{
        cpc_limit: 0,
        name: campaign?.name,
        state: campaign?.state,
      }}
    >
      <Item
        name="name"
        label={t("ADV.CAMPAIGNS.CAMPAING_NAME")}
        rules={[
          { required: true, message: t("ADV.CAMPAIGNS.CAMPAING_NAME_REQ") },
        ]}
      >
        <Input placeholder={t("ADV.CAMPAIGNS.CAMPAING_NAME")} />
      </Item>
      <Item
        name="cpc_limit"
        label={t("ADV.CAMPAIGNS.CPC_LIMIT")}
        rules={[{ required: true, message: t("ADV.CAMPAIGNS.CPC_LIMIT_REQ") }]}
      >
        <InputNumber
          min={0}
          max={1000}
          placeholder={t("ADV.CAMPAIGNS.CPC_LIMIT")}
          style={{ width: "100%" }}
        />
      </Item>
      <Flex
        vertical
        gap={15}
        style={{
          maxWidth: "300px",
        }}
      >
        <Text>
          {t("ADV.CAMPAIGNS.TIMEZONE")}: {campaign?.timezone || ""}
        </Text>
        <Item name="state">
          <StdStateSwitcher
            t={t}
            isActive={isActive}
            setIsActive={setIsActive}
          />
        </Item>
      </Flex>
      <Item>
        <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
          {t("ADV.CAMPAIGNS.SAVE_BUTTON")}
        </Button>
      </Item>
    </Form>
  )
}
