import { AdvCampaignOfferCreativeUploadImage } from "@/components/advertiser/Campaigns/Offers/Creatives/CreativeUploadImage"
import {
  AdvertiserCampaignOfferCreative,
  AdvertiserCampaignOfferCreativeUpdate,
} from "@/definitions"
import { Button, Form, Image, Input, Select } from "antd"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { StdStateSwitcher } from "@/components/common/Std/Form/StateSwitcher"
import {
  useGetAdvCampaignOfferCreativeTypesQuery,
  useUpdateAdvCampaignOfferCreativeMutation,
} from "@/redux/slices/advertiserCampaignsApi"
import { StdModerationStatus } from "@/components/common/Std/Form/ModerationStatus"
import type { FormProps } from "antd"

const { Item } = Form
const { TextArea } = Input

interface AdvCampaignOfferCreativeEditProps {
  creativeData: AdvertiserCampaignOfferCreative | undefined
  offerId: string | undefined
  creativeId: string | undefined
}

export const AdvCampaignOfferCreativeEdit = ({
  creativeData,
  offerId,
  creativeId,
}: AdvCampaignOfferCreativeEditProps): JSX.Element => {
  const { t } = useTranslation()

  const [isActive, setIsActive] = useState<boolean>(false)
  const [imageUrl, setImageUrl] = useState<string>("")
  const [imageType, setImageType] = useState<string>("")

  const [updateCreative, { isLoading }] =
    useUpdateAdvCampaignOfferCreativeMutation()

  const { data: creativeTypes, isLoading: creativeTypesIsLoading } =
    useGetAdvCampaignOfferCreativeTypesQuery(offerId || "")

  const onFinish: FormProps<AdvertiserCampaignOfferCreativeUpdate>["onFinish"] =
    (values) => {
      const newValues =
        imageUrl === creativeData?.img
          ? {
              user_comment: values?.user_comment || "",
              teaser_type: values?.teaser_type,
              offer_id: offerId || "",
              teaser_id: creativeId || "",
              state: isActive ? "on" : "off",
            }
          : {
              ...values,
              offer_id: offerId || "",
              teaser_id: creativeId || "",
              state: isActive ? "on" : "off",
              image: imageUrl,
              image_type: imageType,
            }

      updateCreative(newValues)
    }

  useEffect(() => {
    if (creativeData?.state === "on") setIsActive(true)
    if (creativeData?.img) setImageUrl(creativeData?.img)
  }, [creativeData])

  return (
    <Form
      name="edit-offer-creatives"
      layout="vertical"
      labelCol={{ span: 16 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 450 }}
      onFinish={onFinish}
      autoComplete="off"
      initialValues={{
        state: creativeData?.state,
        teaser_type: creativeData?.teaser_type,
        user_comment: "",
      }}
    >
      <Item
        name="teaser_type"
        label={t("SELECTS.TYPE")}
        rules={[{ required: true, message: t("SELECTS.TYPE_REQ") }]}
      >
        <Select
          placeholder={t("SELECTS.TYPE_PLACEHOLDER")}
          loading={creativeTypesIsLoading}
          options={creativeTypes?.map((item) => ({
            value: item?.id,
            label: item?.name,
          }))}
        />
      </Item>
      <Item name="image" label={t("ADV.CAMPAIGNS.IMAGE")}>
        <AdvCampaignOfferCreativeUploadImage
          setImageType={setImageType}
          setImageUrl={setImageUrl}
          t={t}
        />
      </Item>
      {imageUrl && (
        <Item>
          <Image src={imageUrl} />
        </Item>
      )}
      <Item name="user_comment" label={t("ADV.CAMPAIGNS.USER_COMMENTS")}>
        <TextArea rows={4} placeholder={t("ADV.CAMPAIGNS.USER_COMMENTS")} />
      </Item>
      <StdModerationStatus status={creativeData?.moderation_status} t={t} />
      <Item name="state">
        <StdStateSwitcher t={t} isActive={isActive} setIsActive={setIsActive} />
      </Item>
      <Item>
        <Button
          loading={isLoading}
          type="primary"
          htmlType="submit"
          style={{ width: "100%" }}
        >
          {t("ADV.CAMPAIGNS.CREATE_BUTTON")}
        </Button>
      </Item>
    </Form>
  )
}
