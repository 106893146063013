export const valueToFixed = (
  value: string | number | undefined,
  toFixedValue: number,
): string => {
  if (!value) {
    const zero = 0
    return zero.toFixed(toFixedValue)
  }

  const newValue = Number(value).toFixed(toFixedValue)

  return newValue
}
