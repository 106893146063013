import { ReactNode } from "react"
import { Avatar, Button, Space, Typography } from "antd"
import { NavLink } from "react-router-dom"
import { CopyOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next"
import { ReactComponent as TelegramIcon } from "@/assets/telegram.svg"
import { ReactComponent as SkypeIcon } from "@/assets/skype.svg"
import { ReactComponent as WhatsAppIcon } from "@/assets/whatsapp.svg"
import { copyToClipboard } from "@/utils/copyToClipboard"
import { getCookie } from "@/utils/cookies"
import BAYER_AVATAR_URL from "@/assets/bayer_avatar.png"
import styles from "@/components/common/Contacts/Contacts.module.css"

const { Title, Text } = Typography

interface ContactsItem {
  id: string
  name: string
  link: string
  icon: ReactNode
}

const CONTACTS: ContactsItem[] = [
  {
    id: "1",
    name: "Telegram",
    link: "https://t.me/SF_AdNetwork",
    icon: <TelegramIcon className={styles.iconClass} />,
  },
  {
    id: "2",
    name: "WhatsApp",
    link: "https://wa.me/qr/EKBFB3ACVTBTC1",
    icon: <WhatsAppIcon className={styles.iconClass} />,
  },
  {
    id: "3",
    name: "Skype",
    link: "https://join.skype.com/invite/FxN4OGc4L3yK",
    icon: <SkypeIcon className={styles.iconClass} />,
  },
]

export const Contacts = (): JSX.Element => {
  const { t } = useTranslation()

  const handleCopyText = (textToCopy: string): void => {
    copyToClipboard(textToCopy, t("TOASTS.COPIED"))
  }

  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <Title level={3}>{t("CONTACTS.CONTACTS")}</Title>
      <div className={styles.buyerContainer}>
        <Avatar src={BAYER_AVATAR_URL} size={100} />
        <div className={styles.nameContainer}>
          <Title level={4}>Lucia Salazar</Title>
          <Text style={{ fontSize: "16px" }}>{t("CONTACTS.ABOUT_BAYER")}</Text>
        </div>
      </div>
      {CONTACTS.map((item) => (
        <LinkItem
          icon={item.icon}
          name={item.name}
          link={item.link}
          key={item.id}
          handleCopyText={handleCopyText}
        />
      ))}
    </Space>
  )
}

interface LinkItemProps {
  icon: ReactNode
  name: string
  link: string
  handleCopyText: (textToCopy: string) => void
}

const LinkItem = ({
  icon,
  name,
  link,
  handleCopyText,
}: LinkItemProps): JSX.Element => {
  const theme = getCookie("theme")
  const isLightTheme = theme === "light"

  return (
    <div
      className={
        isLightTheme ? styles.linkItemContainerLight : styles.linkItemContainer
      }
    >
      <NavLink
        className={styles.linkItem}
        to={link}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: isLightTheme ? "rgba(0, 0, 0, 0.88)" : "#fff" }}
      >
        {icon}
        <Text className={styles.textItem}>{name}</Text>
      </NavLink>
      <div className={styles.copyContainer}>
        <Button
          type="primary"
          onClick={() => handleCopyText(link)}
          style={{ padding: "4px 10px" }}
        >
          <CopyOutlined />
        </Button>
      </div>
    </div>
  )
}
