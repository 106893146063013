import { getCookie } from "@/utils/cookies"
import { API_URL } from "@/constants"
import toast from "react-hot-toast"
import esES from "antd/es/locale/es_ES"
import enUS from "antd/es/locale/en_US"
import "dayjs/locale/es"

export const validateError = (response: any, result: any) => {
  const isValid = response.status === 200 && result.state !== "err"
  if (!isValid) {
    toast.error(result.data)
  } else if (isValid) {
    toast.success("Success")
  }
  return isValid
}

export const validateErrorStatus = (response: any, result: any) =>
  response.status === 200 && (!result.state || result.state === "ok")

export const createApiLink = (
  path: string,
  param?: string,
  // isDev?: boolean,
) => {
  if (param) {
    // console.log(111, param)
    return `${API_URL}${path}?${param}`
  } else return `${API_URL}${path}`
}

export const getLocaleOrSetDefault = () => {
  const lang = getCookie("lang")
  if (lang === "es") {
    return esES
  } else if (lang === "en") {
    return enUS
  } else {
    return enUS
  }
}

export const fakeAuth = (): Promise<string> =>
  new Promise((resolve) => {
    setTimeout(() => resolve("2342f2f1d131rf12"), 250)
  })
