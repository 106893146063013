import { Typography, Flex } from "antd"
import {
  useGetAdvNotificationsQuery,
  useGetAdvUserInfoQuery,
} from "@/redux/slices/advertiserApi"
import { useGetTimezonesQuery } from "@/redux/slices/utilsApi"
import { useTranslation } from "react-i18next"
import { StdSpinner } from "@/components/common/Std/Spinner"
import { StdErrorMessage } from "@/components/common/Std/ErrorMessage"
import { AdvAccountNotifications } from "@/components/advertiser/Account/AccountNotifications"
import { AdvAccountInformation } from "@/components/advertiser/Account/AccountInformation"
import { getCookie } from "@/utils/cookies"

const { Title } = Typography

export const AdvAccount = (): JSX.Element => {
  const { t } = useTranslation()

  const lang = getCookie("lang")

  const {
    data: userData,
    isLoading: userDataIsloading,
    isError: userDataIsError,
  } = useGetAdvUserInfoQuery()

  const { data: timezonesData, isLoading: timezonesIsLoading } =
    useGetTimezonesQuery()

  const {
    data: notificationsData,
    isLoading: notificationsIsLoading,
    isError: notificationsIsError,
  } = useGetAdvNotificationsQuery(`lang=${lang || "en"}`)

  if (userDataIsloading || timezonesIsLoading || notificationsIsLoading)
    return <StdSpinner />

  if (userDataIsError || notificationsIsError)
    return <StdErrorMessage>Error...</StdErrorMessage>

  return (
    <Flex vertical gap="large">
      <Title level={3}>{t("ADV.ACCOUNT.ACCOUNT")}</Title>

      <AdvAccountInformation
        t={t}
        timezonesData={timezonesData}
        userData={userData}
      />

      <AdvAccountNotifications notificationsData={notificationsData} t={t} />
    </Flex>
  )
}
